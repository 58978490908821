import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import SaveIcon from '@material-ui/icons/Save';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Swal from 'sweetalert2';
import {
  Divider,
  Grid,
  TextField,
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Button,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import constant from '../../../../constant/constant';
import { deleteData, patchData } from '../../../../provider/data-loader';
import EditIcon from '@material-ui/icons/Edit';
import CustomSelect from 'components/select/custom-select';
import SyncLoader from 'react-spinners/SyncLoader';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 1050,
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: 'flex-end',
  },
  button: {
    margin: theme.spacing(1),
    textTransform: 'capitalize',
    fontFamily: 'Calibri'
  },
  text: {
    fontFamily: 'Calibri',
    fontSize: constant.fontSize.text,
  },
  tableContent: {
    fontSize: constant.fontSize.placholder,
    fontFamily: 'Calibri'
  },
  loading: {
    position: 'absolute',
    flexGrow: 1,
    display: 'flex',
    width: '80%',
    height: '80%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  delete: {
    backgroundColor: constant.color.red,
    '&:hover': {
      backgroundColor: constant.color.darkRed,
    },
  }
}));

const partOfSpeechs = [
  { value: 'kh', label: 'kh', },
  { value: 'en', label: 'en' },
];

const PartOfSpeechTable = props => {
  const { className, listPartofSpeech, handleRefresh, onItemClick, onChangePage, total, page, onChangeRowsPerPage, count, ...rest } = props;
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deletePartOfSpeech, setDeletePartOfSpeech] = useState(null);
  const [nameErrTxt, setNameErrTxt] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [partOfSpeech, setPartOfSpeech] = useState({
    name: '',
    type: '',
  });

  const [selectedPartOfSpeech, setSelectPartOfSpeech] = useState(
    {
      value: 'kh',
      label: "kh"
    });

  const onCloseAddUserModal = () => {
    console.log('called onCloseAddUserModal');
    setOpenModal(false);
  };

  const handleChange = event => {
    setPartOfSpeech({
      ...partOfSpeech,
      [event.target.name]: event.target.value,
    });
    setNameErrTxt('');
  };

  const handleClickOpenDeleteModal = (event, deleteItem) => {
    setOpenDeleteModal(true);
    setDeletePartOfSpeech(deleteItem);
  };

  const onConfirmDelete = () => {
    setOpenDeleteModal(false);
    if (deletePartOfSpeech) {
      deleteData(
        constant.endPoint.partofspeech +
        '/' +
        deletePartOfSpeech._id +
        '?token=' +
        window.sessionStorage.getItem(constant.sessionKey)
      )
        .then(response => {
          if (response.code === 1) {
            Swal.fire({
              icon: 'success',
              title: 'លុបទិន្នន័យបានជោគជ័យ',
            });
            handleRefresh();
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Opps, something went wrong.',
              html:
                '<div style="font-family: Battambang-Regular; font-size: 14 "> <p>' +
                response.message +
                '</p></div>',
            });
          }
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            title: 'Opps, something went wrong.',
            html:
              '<div style="font-family: Battambang-Regular; font-size: 14 "> <p>' +
              err +
              '</p></div>',
          });
        });
    }
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const onOpenPartOfSpeechModal = (event, item) => {
    setSelectPartOfSpeech({
      value: item.type,
      label: item.type,
    });
    setPartOfSpeech(item);
    setOpenModal(true);
  };

  function handleValidateData() {
    let formIsValid = true;
    if (partOfSpeech.name === '') {
      formIsValid = false;
      setNameErrTxt('Please input your part of spech name');
    }
    return formIsValid;
  }

  const handleOnUpdate = () => {
    const token = window.sessionStorage.getItem(constant.sessionKey);
    if (handleValidateData()) {
      let obj = {
        name: partOfSpeech.name,
        type: selectedPartOfSpeech.value
      }
      setIsLoading(true);
      patchData(
        constant.endPoint.partofspeech + '/' + partOfSpeech._id + '?token=' + token, obj)
        .then(response => {
          setIsLoading(false);
          if (response.code === 1) {
            setOpenModal(false);
            Swal.fire({
              icon: 'success',
              title: 'Update Success',
            });
            handleRefresh();
          } else {
            setOpenModal(false);
            Swal.fire({
              icon: 'error',
              title: 'Opps, something went wrong.',
              html:
                '<div style="font-family: Battambang-Regular; font-size: 14 "> <p>' +
                response.message +
                '</p></div>',
            });
          }
        })
        .catch(err => {
          setOpenModal(false);
          setIsLoading(false)
          Swal.fire({
            icon: 'error',
            title: 'Opps, something went wrong.',
            html:
              '<div style="font-family: Battambang-Regular; font-size: 14 "> <p>' +
              err +
              '</p></div>',
          });
        });

    }
  };

  const classes = useStyles();

  const handleChangeValue = (option) => {
    setSelectPartOfSpeech({
      value: option.value,
      label: option.label,
    });
  }

  return (
    <div>
      {isLoading && (
        <div className={classes.loading}>
          <SyncLoader
            size={15}
            color={constant.color.main}
            loading={isLoading}
          />
        </div>
      )}
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.text}>No</TableCell>
                    <TableCell className={classes.text}>Name</TableCell>
                    <TableCell className={classes.text}>Type</TableCell>
                    <TableCell className={classes.text}>Created Date</TableCell>
                    <TableCell className={classes.text}>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {listPartofSpeech.map((partOfSpeech, index) => (
                    <TableRow
                      className={classes.tableRow}
                      hover
                      key={partOfSpeech._id}
                    >
                      <TableCell className={classes.tableContent}>{(page * count) + (index + 1)}</TableCell>
                      <TableCell className={classes.tableContent}>{partOfSpeech.name}</TableCell>
                      <TableCell className={classes.tableContent}>{partOfSpeech.type}</TableCell>
                      <TableCell className={classes.tableContent}>
                        {moment(partOfSpeech.createdAt).format('DD/MM/YYYY')}
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          color="secondary"
                          size="small"
                          className={classes.button}
                          onClick={event => onOpenPartOfSpeechModal(event, partOfSpeech)}
                          startIcon={<EditIcon />}
                        >
                          Edit
                        </Button>
                        <Button
                          variant="contained"
                          color="secondary"
                          size="small"
                          onClick={event =>
                            handleClickOpenDeleteModal(event, partOfSpeech)
                          }
                          className={`${classes.button} ${classes.delete}`}
                          startIcon={<DeleteIcon />}
                        >
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={total}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            labelRowsPerPage="ចំនួនទិន្នន័យក្នុងមួយទំព័រ"
            page={page}
            rowsPerPage={count}
            rowsPerPageOptions={[10, 25, 50]}
          />
        </CardActions>
      </Card>
      <div>
        <Dialog
          open={openModal}
          fullWidth
          onClose={onCloseAddUserModal}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Edit Part of Speech</DialogTitle>
          <Divider />
          <DialogContent style={{ paddingBottom: 80 }}>
            <Grid container spacing={1}>
              <Grid item md={6} xs={12}>
                <TextField
                  error={nameErrTxt !== '' ? true : false}
                  helperText={partOfSpeech.name === '' ? nameErrTxt : ''}
                  fullWidth
                  label="Name"
                  margin="dense"
                  name="name"
                  onChange={handleChange}
                  required
                  value={partOfSpeech.name}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12} style={{ marginTop: 8, width: '100%' }}>
                <CustomSelect
                  options={partOfSpeechs}
                  height='30px'
                  selectedOption={selectedPartOfSpeech}
                  handleChangeValue={handleChangeValue} />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions style={{ marginTop: 8, width: '100%' }}>
            <Button
              onClick={onCloseAddUserModal}
              color="default"
              variant="outlined"
            >
              បោះបង់
            </Button>
            <Button
              onClick={handleOnUpdate}
              color="primary"
              variant="contained"
              startIcon={<SaveIcon />}
            >
              កែប្រែ
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <div>
        <Dialog
          open={openDeleteModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleCloseDeleteModal}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">បញ្ជាក់</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              តើអ្នកពិតជាចង់លុបថ្នាក់ពាក្យ
              {deletePartOfSpeech ? ' ' + deletePartOfSpeech.name : ''}
              ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseDeleteModal}
              color="primary"
              variant="outlined"
            >
              បោះបង់
            </Button>
            <Button
              onClick={onConfirmDelete}
              variant="contained"
              color="secondary"
            >
              លុប
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

PartOfSpeechTable.propTypes = {
  className: PropTypes.string,
  listPartofSpeech: PropTypes.array.isRequired,
  handleRefresh: PropTypes.func,
  onChangePage: PropTypes.func,
  onChangeRowsPerPage: PropTypes.func,
  total: PropTypes.number,
  page: PropTypes.number,
  count: PropTypes.number,
};

export default PartOfSpeechTable;
