import React, { useState, useEffect } from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types'
import { Button, DialogActions, Typography, DialogTitle, Divider } from "@material-ui/core";
import c from "constant/constant";
import { useDropzone } from 'react-dropzone';
import "../../WordTab/khkhword/styles.css";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Swal from 'sweetalert2';
import { postData } from 'provider/data-loader';
import SyncLoader from "react-spinners/SyncLoader";

const useStyles = makeStyles(theme => ({
	content: {
		width: '100%',
	},
	btn: {
		color: c.color.main,
		textTransform: 'capitalize',
		fontSize: c.fontSize.text,
		width: '100%',
		fontFamily: 'Calibri'
	},
	text: {
		color: c.color.text,
		fontSize: c.fontSize.text,
		fontWeight: 500,
		fontFamily: 'Calibri'
	},
	mainColor: {
		color: c.color.main
	},
	dialogStyle: {
		borderRadius: 10,
	},
	thumbsContainer: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		marginTop: 16
	},
	thumb: {
		display: 'inline-flex',
		borderRadius: 2,
		border: '1px solid #eaeaea',
		marginBottom: 8,
		marginRight: 8,
		width: 100,
		height: 100,
		padding: 4,
		boxSizing: 'border-box'
	},
	thumbInner: {
		display: 'flex',
		minWidth: 0,
		overflow: 'hidden'
	},
	img: {
		display: 'block',
		width: 'auto',
		height: '100%'
	},
	closeIcon: {
		position: 'absolute',
		width: 100,
		display: 'flex',
		justifyContent: 'flex-end',
		marginTop: -10
	},
	icon: {
		backgroundColor: 'red',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: '50%',
		'&:hover': {
			cursor: 'pointer',
		},
	},
	title: {
		fontSize: 20
	}
}))

const ImageRequestDialog = props => {
	const { openImageRequest, handleClose, word, type } = props;
	const classes = useStyles();
	const [tempFile, setTempFile] = useState([]);
	const [multiImage, setMultiImage] = useState({ preview: [], raw: [] });
	const [tempFilePreview, setTempFilePreview] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const { getRootProps, getInputProps } = useDropzone({
		accept: 'image/*',
		onDrop: acceptedFiles => {
			const fileArray = [];

			for (let i = 0; i < acceptedFiles.length; i++) {
				setTempFile(oldFile => [...oldFile, acceptedFiles[i]]);
				setTempFilePreview(oldFile => [...oldFile, URL.createObjectURL(acceptedFiles[i])]);
				fileArray.push(URL.createObjectURL(acceptedFiles[i]));
			}
		}
	});

	const removeImage = (file, index) => {
		console.log("Index", index)
		let tempArr = [...tempFile];
		let tempArrPreview = [...tempFilePreview];
		tempArr.splice(index, 1);
		tempArrPreview.splice(index, 1);

		setTempFile(tempArr);
		setTempFilePreview(tempArrPreview)
	}

	const thumbs = multiImage?.preview.map((file, index) => (
		<div className={classes.thumb} key={index}>
			<div className={classes.thumbInner}>
				<img
					src={file}
					className={classes.img}
					alt={"Image" + index}
				/>
				<div className={classes.closeIcon}>
					<div className={classes.icon} onClick={() => removeImage(file, index)}>
						<HighlightOffIcon style={{ color: 'white' }} />
					</div>
				</div>
			</div>
		</div>
	));

	useEffect(() => {
		setMultiImage({ preview: tempFilePreview, raw: tempFile });
	}, [tempFilePreview, tempFile]);

	const handleValidation = () => {
		let formIsValid = true;
		if (multiImage.raw.length === 0) {
			formIsValid = false;
		}

		return formIsValid;
	}

	const clear = () => {
		setMultiImage({
			row: [],
			preview: []
		})
	}
	const submitImageRequest = () => {
		var form_data = new FormData();
		if (handleValidation()) {
			setIsLoading(true);
			if (multiImage.raw.length > 0) {
				for (let i = 0; i < multiImage.raw.length; i++) {
					form_data.append('images', multiImage.raw[i]);
				}
			}

			if (type === c.types.khkh) {
				form_data.append('kh_kh_word', word?._id);
			} else if (type === c.types.khen) {
				form_data.append('kh_en_word', word?._id);
			} else {
				form_data.append('en_kh_word', word?._id);
			}

			for (var value of form_data.values()) {
				console.log(value);
			}

			postData(c.endPoint.imageRequest, form_data)
				.then(value => {
					console.log(value);
					setIsLoading(false);
					handleClose();
					if (value.code === 1) {
						Swal.fire({
							icon: 'success',
							title: 'ជោគជ័យ',
						}).then(value => {
							clear();
						});
					} else {
						Swal.fire({
							icon: 'success',
							title: 'Something went wrong',
						});
					}
				})
				.catch(err => {
					Swal.fire({
						icon: 'error',
						title: 'Opps, something went wrong.',
						html:
							'<div style="font-family: Battambang-Regular; font-size: 14 "> <p>' +
							err.message +
							'</p></div>',
					});
				});
		}
	};

	return (
		<Dialog
			open={openImageRequest}
			onClose={handleClose}
			fullWidth={true}
			aria-labelledby="form-dialog-title"
			classes={{
				paper: classes.dialogStyle
			}}
		>
			<DialogTitle id="alert-dialog-slide-title">
				<Typography className={classes.title}>ស្នើបញ្ចូលរូបភាព</Typography>
			</DialogTitle>
			<Divider />
			<DialogContent className={classes.content}>
				<div style={{ textAlign: 'center' }}>
					<p>{word?.word}</p>
				</div>
				<section className="container">
					<div {...getRootProps({ className: 'dropzone' })}>
						<input {...getInputProps()} />
						<AddCircleOutlineIcon style={{ fontSize: 30 }} />
						<p>ជ្រើសរើសរូបភាព</p>
					</div>
					<aside className={classes.thumbsContainer}>
						{thumbs}
					</aside>
				</section>
			</DialogContent>
			<Divider />
			<DialogActions>
				<Button 
					onClick={handleClose}
					color="default"
                	variant="outlined">
					បោះបង់
                </Button>

				<Button  
					onClick={submitImageRequest}
					color="primary"
					variant="contained">
					បញ្ជូន
                </Button>
			</DialogActions>
			{isLoading ? (
				<div
					style={{
					position: 'absolute',
					width: '100%',
					height: '100%',
					justifyContent: 'center',
					display: 'flex',
					alignItems: 'center',
					}}
				>
					<SyncLoader
					size={15}
					color={c.color.main}
					loading={isLoading}
					/>
				</div>
				) : null}
		</Dialog>
	)
}

ImageRequestDialog.propTypes = {
	className: PropTypes.string,
	openImageRequest: PropTypes.bool,
	handleClose: PropTypes.func,
	word: PropTypes.object,
	type: PropTypes.string
}

export default ImageRequestDialog
