import '../index.css';
const fontSize = {
  title: 24,
  text: 20,
  subTitle: 14,
  content: 14,
  meaningText: 24,
  placholder: 18,
  texfield: 16,
};
const color = {
  main: '#27577B',
  darkMain: '#002e4f',
  yellow: '#ffa500',
  darkYellow: '#c67600',
  text: 'grey',
  footerColor: '#525A5C',
  blueBorder: '#1F9ED3',
  red: '#C02126',
  darkRed: '#880000',
  bgContent: '#F8FAF9',
  green: '#00A652',
  darkgreen: '#007627',
  blue: '#224FA4',
  dashboardTextColor: '#5D86A2',
  errText: '#e53935',
  darkWhite: '#cccccc',
};

const fontFamily = {
  Khmer_Battambong_Regular: 'Battambang-Regular',
};

const sessionKey = 'TOKEN';

const localStorageKey = {
  WORDS: 'WORDS',
};

const types = {
  khkh: 'khkh',
  enkh: 'enkh',
  khen: 'khen',
}

// const host = 'http://dictionary-api.stembola.com/v1';
const host = process.env.REACT_APP_BASE_URL;
const endPoint = {
  auth: host + '/auth',
  user: host + '/users',
  allWord: host + '/all_words',
  khKhWord: host + '/kh_kh_words',
  khEnWord: host + '/kh_en_words',
  enKhWord: host + '/en_kh_words',
  images: host + '/images',
  sounds: host + '/sounds',
  wordRequest: host + '/word_requests',
  enquiry: host + '/enquiries',
  version: host + '/versions',
  imageRequest: host + '/image_requests',
  changeRequest: host + '/change_requests',
  file: host + '/files',
  wordComment: host + '/word_comments',
  dashboard: host + '/dashboard',
  changerequest: host + '/change_requests',
  advertisements: host + '/advertisements',
  webAdvertisements: host + '/advertisements/web',
  partofspeech: host + '/part_of_speeches',
  khkhSuggestions: host + '/kh_kh_words/suggestions',
  khenSuggestions: host + '/kh_en_words/suggestions',
  enkhSuggestions: host + '/en_kh_words/suggestions'
};

const userRole = {
  admin: 'admin',
  editor: 'editor',
  contributor: 'contributor',
};

const token =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiYWRtaW4iLCJfaWQiOiI1ZTg2MDA5OWI2OTNkNTc3MjZlNWU1M2MiLCJpYXQiOjE1ODc1NTkzOTYsImV4cCI6MTU4NzgxODU5Nn0.GjWClO3sgIccKXmsOw6oVac4e5rSmI2_mvvZmMFUE3Y';

const constants = {
  fontSize,
  fontFamily,
  color,
  endPoint,
  token,
  sessionKey,
  userRole,
  localStorageKey,
  types
};

export default constants;

export const getUser = () => {
  return window.sessionStorage.getItem('USER');
};
