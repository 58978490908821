
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, ListItemIcon, Typography } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import c from 'constant/constant';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    text: {
        fontFamily: 'Calibri',
        fontSize: c.fontSize.texfield
    }
}));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&:focus': {
            backgroundColor: c.color.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
        marginTop: 16
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const ProfileMenu = props => {
    const { anchorEl, handleClose, handleSignOut, username } = props;
    const classes = useStyles();

    return (
        <StyledMenu
            id="customized-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            autoFocus={false}
        >
            <StyledMenuItem>
                <ListItemText primary={<Typography  className={classes.text}>{username}</Typography>} />
            </StyledMenuItem>
            <StyledMenuItem onClick={handleSignOut}>
                <ListItemIcon style={{ minWidth: '40px' }}>
                    <ExitToAppIcon />
                </ListItemIcon>
                <ListItemText primary={<Typography  className={classes.text}>Log Out</Typography>}/>
            </StyledMenuItem>
        </StyledMenu>
    );
};

ProfileMenu.propTypes = {
    className: PropTypes.string,
    anchorEl: PropTypes.object,
    handleSignOut: PropTypes.func,
    username: PropTypes.string
};

export default ProfileMenu;
