import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { VersionToolbar, VersionTable } from './components';
import { Divider, Grid, Button, TextField } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import SaveIcon from '@material-ui/icons/Save';
import DialogTitle from '@material-ui/core/DialogTitle';
import Swal from 'sweetalert2';
import { getData, postData } from '../../provider/data-loader';
import constant from '../../constant/constant';
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));

const UserList = props => {
  const classes = useStyles();
  const [versions, setVersions] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  const onOpenAddUserModal = () => {
    setOpenModal(true);
  };

  const onCloseAddUserModal = () => {
    console.log('called onCloseAddUserModal');
    setOpenModal(false);
  };

  const [version, setVersion] = React.useState({
    version: '',
    note: '',
  });

  const handleChange = event => {
    setVersion({
      ...version,
      [event.target.name]: event.target.value,
    });
  };

  const handleClear = () => {
    setVersion({
      version: '',
      note: ''
    })
  }

  const handleOnSave = () => {
    const token = window.sessionStorage.getItem(constant.sessionKey);
    postData(constant.endPoint.version + '?token=' + token, version)
      .then(response => {
        if (response.code === 1) {
          setOpenModal(false);
          Swal.fire({
            icon: 'success',
            title: 'ADD SUCCESS',
          });
          handleRefresh();
          handleClear();
        } else {
          setOpenModal(false);
          handleClear();
          Swal.fire({
            icon: 'error',
            title: 'Opps, something went wrong.',
            html:
              '<div style="font-family: Battambang-Regular; font-size: 14 "> <p>' +
              response.message +
              '</p></div>',
          });
        }
      })
      .catch(err => {
        setOpenModal(false);
        handleClear();
        Swal.fire({
          icon: 'error',
          title: 'Opps, something went wrong.',
          html:
            '<div style="font-family: Battambang-Regular; font-size: 14 "> <p>' +
            err +
            '</p></div>',
        });
      });
  };

  function getVersions() {
    const token = window.sessionStorage.getItem(constant.sessionKey);
    getData(constant.endPoint.version + '?token=' + token).then(data => {
      if (data.code === 1) {
        setVersions(data.data);
      } else if (data.code === 0) {
        console.log('error clients');
      } else {
        console.log('error server');
      }
    });
  }
  useEffect(() => {
    getVersions();
  }, []);

  const handleRefresh = e => {
    getVersions();
  };

  return (
    <div className={classes.root}>
      <VersionToolbar
        handleRefresh={handleRefresh}
        handleOpenAddUserModal={onOpenAddUserModal}
      />
      <div className={classes.content}>
        <VersionTable versions={versions} handleRefresh={handleRefresh} />
        <div>
          <Dialog
            open={openModal}
            onClose={onCloseAddUserModal}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">Version ថ្មី</DialogTitle>
            <Divider />
            <DialogContent>
              <Grid container spacing={1}>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Version"
                    margin="dense"
                    name="version"
                    onChange={handleChange}
                    required
                    value={version.version}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="ចំណាំ"
                    margin="dense"
                    name="note"
                    onChange={handleChange}
                    value={version.note}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogAction}>
              <Button
                onClick={onCloseAddUserModal}
                color="default"
                variant="outlined"
              >
                បោះបង់
              </Button>
              <Button
                onClick={handleOnSave}
                color="primary"
                variant="contained"
                startIcon={<SaveIcon />}
              >
                រក្សាទុក
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </div>
  );
};

export default UserList;
