import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import AllPagesPDFViewer from '../components/pdf/all-pages';
import samplePDF from '../../../assets/file/abbrevation.pdf';

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		flexFlow: 'column',
		height: '100%',
	},
	content: {
		flexGrow: 1,
		display: 'flex',
		width: '100% !important',
		overflow: "auto",
		[theme.breakpoints.up('sm')]: {
			justifyContent: 'center',
		},
		
	}
}));

const Abbrevation = props => {
	const classes = useStyles();

	return (
		<div
			className={clsx({
				[classes.root]: true,
			})}
		>
			<div className={classes.content}>
				<div className="all-page-container">
					<AllPagesPDFViewer pdf={samplePDF} />
				</div>
			</div>
		</div>
	);
};

Abbrevation.propTypes = {
	className: PropTypes.string,
};

export default Abbrevation;
