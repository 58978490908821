import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import c from 'constant/constant';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexFlow: 'column',
    height: '100%',
  },
  content: {
    flexGrow: 1,
    [theme.breakpoints.down('xs')]: {
      padding: 10
    },
  },
  title: {
    height: '30px',
    width: '100%',
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '30px',
    flexFlow: 'row',
    color: c.color.main,
    fontWeight: 'bold',
    paddingTop: 16
  },
  about: {
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  text: {
    marginTop: '5px',
    textIndent: '50px',
    lineHeight: 2,
    textAlign: 'justify',
  },
}));

const Acknowledgement = props => {
  const classes = useStyles();

  return (
    <div
      className={clsx({
        [classes.root]: true,
      })}
    >
      <div className={classes.content}>
        <div className={classes.title}>សេក្តីថ្លែងអំណរគុណ</div>
        <div className={classes.about}>
          <p className={classes.text}>
            សូមថ្លែងអំណរគុណចំពោះក្រុមការងាររួមមានលោកស្រី ព្រុំ មុនីរិទ្ធិ លោក
            សេង ប៊ុនដេត កញ្ញា ប៊ន វ៉ាន់ឌី និងលោកស្រី វង្ស ណូឡែន
            ដែលបានចងក្រងកំណត់ត្រារបស់ខ្ញុំជាវាក្យបរិវត្តន៍
            សម្រាប់ជាការចាប់ផ្តើម និងការកសាង ខ្មែរភីឌៀ
            ដែលជាប្រជុំវចនានុក្រមនេះឡើង ក្នុងគោលដៅចងក្រងប្រមូលផ្តុំ អភិរក្ស
            និងអភិវឌ្ឍអក្សរសាស្ត្រខ្មែរសម្រាប់សាធារណជនប្រើប្រាស់ប្រកបដោយសុក្រឹតភាព។
            </p>
        </div>
        <div className={classes.title}>Acknowledgement</div>
        <div className={classes.about}>
          <p className={classes.text}>
            I would like to thank my working group - Mrs. Prum Monirith, Mr.
            Seng Bundeth, Miss. Born Vandy, Mrs. Vong Nolen - for their
            contributions in organizing, transcribing and compiling my notes
            to form first a Lexicon and subsequently KHMERPEDIA. It is a
            collection of authentic dictionaries intended to integrate,
            conserve and further advance Khmer vocabulary for the public to
            use properly and accurately.
            </p>
        </div>
      </div>
    </div>
  );
};

Acknowledgement.propTypes = {
  className: PropTypes.string,
};

export default Acknowledgement;
