/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef } from 'react'
import { NavLink as RouterLink } from 'react-router-dom'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { List, ListItem, Button, colors } from '@material-ui/core'
import constants from 'constant/constant'    
import { 
  setAuthorGlobal, 
  setStatusGlobal, 
  setPageGlobal, 
  setRowInPerPageGlobal ,
  setStartDateGlobal,
  setEndDateGlobal
} from '../../../../../../views/User/actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {},
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium,
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
  menuText: {
    fontFamily: 'Calibri',
    fontSize: constants.fontSize.placholder
  },
  active: {
    color: constants.color.yellow,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: constants.color.yellow,
    },
  },
  count: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center'
  }
}))

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
))

const SidebarNav = props => {
  const { 
      pages, 
      className, 
      setPageGlobal, 
      setStatusGlobal, 
      setAuthorGlobal, 
      setRowInPerPageGlobal, 
      setStartDateGlobal,
      setEndDateGlobal } = props
  const classes = useStyles();

  const handleClickSubMenu = () => {
    setPageGlobal(0);
    setRowInPerPageGlobal(10);
    setStatusGlobal('');
    setAuthorGlobal('');
    setEndDateGlobal('');
    setStartDateGlobal('');
  }

  return (
    <List className={clsx(classes.root, className)}>
      {pages.map(page => (
        page.isShow &&
        <span key={page.title}>               
          <ListItem className={classes.item} disableGutters>
            {
              page.subMenu.length > 0 ?
                <Button
                  className={classes.button}
                  size="large"
                >
                  <div className={classes.icon}>{page.icon}</div>
                  <div className={classes.menuText}>{page.title}</div>
                </Button>
                :
                <Button
                  activeClassName={classes.active}
                  className={classes.button}
                  component={CustomRouterLink}
                  to={page.href}
                  size="large"
                  onClick={handleClickSubMenu}
                >
                  <div className={classes.icon}>{page.icon}</div>
                  <div className={classes.menuText}>{page.title}</div>
                  {
                    (page.count > 0) &&
                    <div className={classes.count}>
                      <p 
                        style={{backgroundColor: constants.color.red,
                         borderRadius: '8px',
                         padding: '0px 6px 0px 6px',
                         color: 'white'}}>
                           {page.count}
                          </p>
                    </div>
                  }
                </Button>
            }
          </ListItem>
          {
            page.subMenu &&
              <List disablePadding style={{ paddingLeft: 20 }}>
                {
                  page.subMenu.map(menu => (
                    <ListItem className={classes.item} disableGutters key={menu.title}>
                      <Button
                        activeClassName={classes.active}
                        className={classes.button}
                        component={CustomRouterLink}
                        to={menu.href}
                        size="large"
                        onClick={handleClickSubMenu}
                      >
                        <div className={classes.icon}>{menu.icon}</div>
                        <div className={classes.menuText}>{menu.title}</div>
                      </Button>
                    </ListItem>
                  ))
                }
              </List>
          }
        </span>
      ))}
    </List>
  )
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    setAuthorGlobal,
    setStatusGlobal,
    setPageGlobal,
    setRowInPerPageGlobal,
    setStartDateGlobal,
    setEndDateGlobal,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SidebarNav)
