import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { UsersToolbar, UsersTable } from './components';
import { getData } from '../../provider/data-loader';
import constant from '../../constant/constant';
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));

const UserList = props => {
  const classes = useStyles();
  const [enquiries, setEnquiries] = useState([]);

  function getEnquiryData(search = "") {
    const token = window.sessionStorage.getItem(constant.sessionKey);
    let query = "?token=" + token;
    if (search.trim().length > 0) {
      query += "&search=" + search;
    }
    getData(constant.endPoint.enquiry + query).then(data => {
      if (data.code === 1) {
        setEnquiries(data.data);
      } else if (data.code === 0) {
        console.log('error clients');
      } else {
        console.log('error server');
      }
    });
  }
  useEffect(() => {
    getEnquiryData();
  }, []);

  const handleRefresh = e => {
    getEnquiryData();
  };

  const onSearchChange = e => {
    let search = e.target.value;
    getEnquiryData(search);
  }

  return (
    <div className={classes.root}>
      <UsersToolbar handleRefresh={handleRefresh} onSearchChange={onSearchChange} />
      <div className={classes.content}>
        <UsersTable enquiries={enquiries} handleRefresh={handleRefresh}/>
      </div>
    </div>
  );
};

export default UserList;
