import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';
import AddBoxIcon from '@material-ui/icons/AddBox';
import RefreshIcon from '@material-ui/icons/Refresh';
import c from 'constant/constant';
const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    marginRight: theme.spacing(1),
  },
  button: {
    marginRight: '8px',
    textTransform: 'capitalize',
    fontFamily: 'Calibri',
    fontSize: c.fontSize.placholder,
  },
  dialogAction: {
    paddingRight: '25px',
  },
}));

const UsersToolbar = props => {
  const { className, handleRefresh, handleOpenAddUserModal, ...rest } = props;

  const classes = useStyles();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.row}>
        <Button
          color="primary"
          variant="contained"
          className={classes.button}
          startIcon={<AddBoxIcon />}
          onClick={handleOpenAddUserModal}
        >
          New
        </Button>
        <Button
          color="default"
          variant="outlined"
          className={classes.button}
          onClick={handleRefresh}
          startIcon={<RefreshIcon />}
        >
          Refresh
        </Button>
        <span className={classes.spacer} />
        {/* <SearchInput
          className={classes.searchInput}
          placeholder="Search user"
        /> */}
      </div>
    </div>
  );
};

UsersToolbar.propTypes = {
  className: PropTypes.string,
  handleOpenAddUserModal: PropTypes.func,
  handleRefresh: PropTypes.func,
};

export default UsersToolbar;
