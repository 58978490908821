const initialState = {
    newSearchWord: null,
    newSearchWordID: '',
    newTab: 0,
    userRole: '',
    khkhWords: [],
    khenWords: [],
    enkhWords: [],
    authorGlobal: '',
    statusGlobal: '',
    pageGlobal: 0,
    rowInPerPageGlobal: 10,
    startDateGlobal: '',
    endDateGlobal: ''
}

const wordreducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_WORD_TO_SEARCH': return { ...state, newSearchWord: action.payload.newSearchWord }
        case 'GET_WORD_ID_TO_SEARCH': return { ...state, newSearchWordID: action.payload.newSearchWordID }
        case 'ACTIVE_TAB': return { ...state, newTab: action.payload.newTab }
        case 'ACTIVE_USER': return { ...state, userRole: action.payload.userRole }
        case 'CLEAR_WORD_TO_SEARCH': return { ...state, newSearchWord: null, newSearchWordID: '' }
        case 'GET_KHKH_WORD' : return { ...state, khkhWords: action.payload.khkhWords }
        case 'GET_KHEN_WORD' : return { ...state, khenWords: action.payload.khenWords }
        case 'GET_ENKH_WORD' : return { ...state, enkhWords: action.payload.enkhWords }
        case 'SET_AUTHOR': return { ...state, authorGlobal: action.payload.authorGlobal }
        case 'SET_STATUS': return { ...state, statusGlobal: action.payload.statusGlobal }
        case 'SET_PAGE': return { ...state, pageGlobal: action.payload.pageGlobal }
        case 'SET_ROW_IN_PER_PAGE': return { ...state, rowInPerPageGlobal: action.payload.rowInPerPageGlobal }
        case 'SER_START_DATE' : return { ...state, startDateGlobal: action.payload.startDateGlobal }
        case 'SER_END_DATE' : return { ...state, endDateGlobal: action.payload.endDateGlobal }
        
        default:
            return state
    }
}

export default wordreducer