import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/styles';
import c from 'constant/constant';
import { Grid, Typography, useMediaQuery, Collapse } from '@material-ui/core';
import commingsoon from '../../../assets/images/commingsoon.jpg';
import { isMobile } from 'react-device-detect';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const useStyles = makeStyles(theme => ({
  root: {
    left: 0,
    bottom: 0,
    right: 0,
  },
  box: {
    display: 'flex',
    textAlign: 'right',
    alignItems: 'flex-end',
    alignSelf: 'flex-end',
  },

  one: {
    width: 50,
    marginLeft: 10,
  },
  link: {
    color: 'white',
    fontFamily: c.fontFamily.Khmer_Battambong_Regular,
    cursor: 'pointer',
    fontSize: '15px',
  },
  text: {
    color: 'white',
    fontFamily: c.fontFamily.Khmer_Battambong_Regular,
    fontSize: '15px',
  },
  enText: {
    color: 'white',
    fontSize: '15px',
  },
  borderRight: {
    borderRightWidth: 2,
    borderRightColor: c.color.blueBorder,
    borderRightStyle: 'solid',
  },
  footerItemCenter: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
  },
  linkToProgram: {
    color: 'white',
    fontFamily: c.fontFamily.Khmer_Battambong_Regular,
    fontSize: '15px',
    cursor: 'pointer',
    textDecoration: 'underline',
    marginTop: '8px',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  footerCenter: {
    textAlign: 'center'
  },
  adsBottom: {
    height: '30',
},
contentData: {
  paddingLeft: '3%',
  paddingRight: '3%',
  backgroundColor: c.color.bgContent,
}
}));

const Footer = props => {
  const { className, children, slides } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const [checked, setChecked] = React.useState(false);
  const path = children.props.location.pathname;

  function onFounderClick() {
    children.props.history.push('/founder');
  }

  const saveQuestionButton = () => {
    children.props.history.push('/enquiry');
  };

  function onAcknowledgementClick() {
    children.props.history.push('/acknowledgement');
  }

  const handleCheckProcode = (event) => {
    setChecked((prev) => !prev);
  };

  return (
    <div className={clsx(classes.root, className)} style={{ position: path === "/" && 'fixed' }}>
      {
        path === "/" &&
        <Grid container>
          {
            !isXs && <Grid item xs={2} sm={2} md={2} lg={2}></Grid>
          }
          <Grid item xs={12} sm={8} md={8} lg={8} className={classes.contentData}>
            <Grid container className={classes.adsBottom}>
              <Grid item xs={6} sm={6} md={6} lg={6}>
                <div style={{ paddingTop: 20 }}>
                  <Slider
                    autoplay={true}
                    autoplaySpeed={10000}
                    arrows={false}>
                    {
                      slides.map((slide, index) =>
                        <div key={index}>
                          <a href={slide.link}
                            target="_blank"
                            rel="noopener noreferrer">
                            <img src={c.endPoint.file + '/' + slide.image} width="100%" alt={slide.tlin} />
                          </a>
                        </div>
                      )
                    }
                  </Slider>
                </div>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} >
                <div style={{ paddingTop: 20, paddingLeft: 10 }}>
                  <Slider
                    autoplay={true}
                    arrows={false}
                    autoplaySpeed={10000}>
                    {
                      slides.reverse().map((slide, index) =>
                        <div key={index}>
                          <a href={slide.link}
                            target="_blank"
                            rel="noopener noreferrer">
                            <img src={c.endPoint.file + '/' + slide.image} width="100%" alt={slide.tlin} />
                          </a>
                        </div>
                      )
                    }
                  </Slider>
                </div>
              </Grid>
            </Grid>
          </Grid>
          {
            !isXs && <Grid item xs={2} sm={2} md={2} lg={2}></Grid>
          }
        </Grid>
      }
      {
        !isXs ?
          <Grid container style={{ backgroundColor: c.color.footerColor, padding: '1%' }}>
            <Grid
              item
              xs={6}
              sm={2}
              md={2}
              lg={2}
              xl={2}
              style={{ display: 'flex', flexFlow: 'column' }}
              className={classes.borderRight}
            >
              <span className={classes.link} onClick={onFounderClick}>
                អំពីស្ថាបនិក
          </span>
              <span className={classes.link} onClick={onAcknowledgementClick}>
                សេក្តីថ្លែងអំណរគុណ
          </span>
            </Grid>
            <Grid
              item
              xs={6}
              sm={4}
              md={4}
              lg={4}
              xl={4}
              className={`${classes.footerItemCenter} ${classes.borderRight}`}
            >
              <Typography className={classes.enText}>ចូលរួមជាមួយពួកយើង</Typography>
              <div style={{ display: 'flex', flexFlow: 'row' }}>
                <Grid container>
                  <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                    <a
                      href="https://www.facebook.com/SOPHALLETH/?ref=bookmarks"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className={classes.one}>
                        <img
                          height="40px"
                          width="40px"
                          alt="facebook"
                          src="/images/social/fb.png"
                        />
                      </div>
                    </a>
                  </Grid>
                  <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                    <a
                      href="https://www.instagram.com/sophalleth/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className={classes.one}>
                        <img
                          height="40px"
                          alt="instagram"
                          src="/images/social/ig.png"
                        />
                      </div>
                    </a>
                  </Grid>
                  <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                    <a
                      href="https://www.youtube.com/channel/UCFJ-hcEfJK4K8PDFYPmDHmw"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className={classes.one}>
                        <img
                          height="40px"
                          alt="twitter"
                          src="/images/social/youtube.png"
                        />
                      </div>
                    </a>
                  </Grid>
                  <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                    <a
                      href="https://twitter.com/SophallethEang/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className={classes.one}>
                        <img
                          height="40px"
                          alt="web"
                          src="/images/social/twitter.png"
                        />
                      </div>
                    </a>
                  </Grid>
                  <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                    <a
                      href="https://www.linkedin.com/in/sophalleth-eang-ab91796a/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className={classes.one}>
                        <img
                          height="40px"
                          alt="linkedin"
                          src="/images/social/linkedin.png"
                        />
                      </div>
                    </a>
                  </Grid>
                  <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                    <a
                      href="https://eangsophalleth.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className={classes.one}>
                        <img height="40px" alt="web" src="/images/social/web.png" />
                      </div>
                    </a>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid
              item
              xs={6}
              sm={4}
              md={4}
              lg={4}
              xl={4}
              className={`${classes.footerItemCenter} ${classes.borderRight}`}
            >
              <div style={{ display: 'flex', flexFlow: 'row' }}>
                <div
                  style={{
                    justifyContent: 'center',
                    display: 'flex',
                    flexFlow: 'column',
                  }}
                >
                  <Typography className={classes.enText}>កម្មវិធី</Typography>
                  <Typography className={classes.enText}>ពិសេសៗ</Typography>
                  <Typography className={classes.enText}>ជាច្រើនទៀត</Typography>
                </div>
                <div style={{ marginLeft: 10 }}>
                  <img
                    src={commingsoon}
                    width="80"
                    height="80"
                    alt="comming soon"
                  />
                </div>
              </div>
            </Grid>
            <Grid
              item
              xs={6}
              sm={2}
              md={2}
              lg={2}
              xl={2}
              style={{
                display: 'flex',
                alignItems: isMobile ? 'flex-end' : '',
                justifyContent: isMobile ? 'flex-end' : 'flex-end',
              }}
            >
              <div className={classes.column}>
                <Typography className={classes.link}>ទំនាក់ទំនង</Typography>
                <Typography className={classes.link} onClick={saveQuestionButton}>
                  សំណួរ
            </Typography>
                <Typography
                  className={classes.linkToProgram}
                  onClick={() => children.props.history.push('/dashboard')}
                >
                  ចូលទៅកាន់កម្មវិធី
            </Typography>
              </div>
            </Grid>
          </Grid>
          :
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: c.color.footerColor, padding: '1%' }}>
            <Grid container>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6} className={classes.footerCenter}>
                <span className={classes.link} onClick={onFounderClick}>
                  អំពីស្ថាបនិក
                      </span>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6} className={classes.footerCenter}>
                <span className={classes.link} onClick={onAcknowledgementClick}>
                  សេក្តីថ្លែងអំណរគុណ
          </span>
              </Grid>
            </Grid>
            {
              !checked &&
              // <p className={classes.link}>more</p>
              <ExpandMoreIcon onClick={handleCheckProcode} style={{ color: 'white' }} />
            }
            <Collapse in={checked}>
              <div>
                <div style={{ textAlign: 'center' }}>
                  <span className={classes.link}>
                    ចូលរួមជាមួយពួកយើង
                  </span>
                  <Grid container>
                    <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                      <a
                        href="https://www.facebook.com/SOPHALLETH/?ref=bookmarks"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className={classes.one}>
                          <img
                            height="40px"
                            width="40px"
                            alt="facebook"
                            src="/images/social/fb.png"
                          />
                        </div>
                      </a>
                    </Grid>
                    <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                      <a
                        href="https://www.instagram.com/sophalleth/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className={classes.one}>
                          <img
                            height="40px"
                            alt="instagram"
                            src="/images/social/ig.png"
                          />
                        </div>
                      </a>
                    </Grid>
                    <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                      <a
                        href="https://www.youtube.com/channel/UCFJ-hcEfJK4K8PDFYPmDHmw"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className={classes.one}>
                          <img
                            height="40px"
                            alt="twitter"
                            src="/images/social/youtube.png"
                          />
                        </div>
                      </a>
                    </Grid>
                    <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                      <a
                        href="https://twitter.com/SophallethEang/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className={classes.one}>
                          <img
                            height="40px"
                            alt="web"
                            src="/images/social/twitter.png"
                          />
                        </div>
                      </a>
                    </Grid>
                    <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                      <a
                        href="https://www.linkedin.com/in/sophalleth-eang-ab91796a/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className={classes.one}>
                          <img
                            height="40px"
                            alt="linkedin"
                            src="/images/social/linkedin.png"
                          />
                        </div>
                      </a>
                    </Grid>
                    <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                      <a
                        href="https://eangsophalleth.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className={classes.one}>
                          <img height="40px" alt="web" src="/images/social/web.png" />
                        </div>
                      </a>
                    </Grid>
                  </Grid>
                </div>
                <div className={classes.row}>
                  <p className={classes.text}>កម្មវិធីពិសេសៗជាច្រើនទៀត</p>
                  <div style={{ marginLeft: 10 }}>
                    <img
                      src={commingsoon}
                      width="60"
                      height="60"
                      alt="comming soon"
                    />
                  </div>
                </div>
                <div className={classes.column}>
                  <span className={classes.link} onClick={onFounderClick}>
                    សំណួរ
                  </span>
                  <span className={classes.link} onClick={onAcknowledgementClick}>
                    ចូលទៅកាន់កម្មវិធី
                  </span>
                </div>
              </div>
            </Collapse>
            {
              checked &&
              <ExpandLessIcon onClick={handleCheckProcode} style={{ color: 'white' }} />
            }
          </div>
      }
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  slides: PropTypes.array
};

export default Footer;
