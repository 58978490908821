import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, Grid, Typography, Avatar } from '@material-ui/core';
import constants from 'constant/constant';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    '&:hover': {
      backgroundColor: constants.color.darkWhite,
    },
  },
  content: {
    alignItems: 'center',
    display: 'flex',
  },
  title: {
    fontWeight: 700,
    fontFamily: 'Calibri',
    color: constants.color.dashboardTextColor
  },
  avatar: {
    backgroundColor: theme.palette.success.main,
    height: 56,
    width: 56,
  },
  icon: {
    height: 32,
    width: 32,
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  differenceIcon: {
    color: theme.palette.success.dark,
  },
  differenceValue: {
    color: theme.palette.success.dark,
    marginRight: theme.spacing(1),
  },
  cardText: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexFlow: 'column',
  },
  itemCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  cardPadding: {
    padding: 16
  }
}));

const TotalUsers = props => {
  const { className, path, handleClick, ...rest } = props;
  const { icon, text, data, fontSize, sub_text = "" } = rest;
  const classes = useStyles();

  return (
    <Card {...rest} className={clsx(classes.root, className)} onClick={() => handleClick(path)}>
      <CardContent className={`${classes.itemCenter} ${classes.cardPadding}`}>
        <Grid container className={classes.itemCenter}>
          <Grid item xs={3}>
            <Avatar className={classes.avatar}>{icon}</Avatar>
          </Grid>
          <Grid item xs={9} className={classes.cardText}>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              variant="body2"
              style={{ fontSize: fontSize }}
            >
              {text}
            </Typography>
            <Typography variant="h1" style={{ color: constants.color.blue }}>{data}</Typography>
            {sub_text !== "" ? <Typography variant="h4" style={{ color: constants.color.green }}>Published: {sub_text}</Typography> : ""}
          </Grid>
        </Grid>
        {/* <div className={classes.difference}>
          <ArrowUpwardIcon className={classes.differenceIcon} />
          <Typography
            className={classes.differenceValue}
            variant="body2"
          >
            16%
          </Typography>
          <Typography
            className={classes.caption}
            variant="caption"
          >
            Since last month
          </Typography>
        </div> */}
      </CardContent>
    </Card>
  );
};

TotalUsers.propTypes = {
  className: PropTypes.string,
  fontSize: PropTypes.number,
  path: PropTypes.string,
  handleClick: PropTypes.func
};

export default TotalUsers;
