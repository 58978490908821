
  export const getWordToSearch = (word) => {
    return {
      type: 'GET_WORD_TO_SEARCH',
      payload: {
        newSearchWord: word
      }
    }
  }

  export const getWordIdToSearch = (wordID) => {
    return {
      type: 'GET_WORD_ID_TO_SEARCH',
      payload: {
        newSearchWordID: wordID
      }
    }
  }

  export const clearWordToSearch = () => {
    return {
      type: 'CLEAR_WORD_TO_SEARCH',
      payload: {
        newSearchWord: ''
      }
    }
  }

  export const setTabSelected = (tab) => {
    return {
      type: 'ACTIVE_TAB',
      payload: {
        newTab: tab
      }
    }
  }

  export const setUserRole = (role) => {
    return {
      type: 'ACTIVE_USER',
      payload: {
        userRole: role
      }
    }
  }

  export const setKhKhWords = (list) => {
    return {
      type: 'GET_KHKH_WORD',
      payload: {
        khkhWords: list
      }
    }
  }

  export const setKhEnWords = (list) => {
    return {
      type: 'GET_KHEN_WORD',
      payload: {
        khenWords: list
      }
    }
  }

  export const setEnKhWords = (list) => {
    return {
      type: 'GET_ENKH_WORD',
      payload: {
        enkhWords: list
      }
    }
  }

  export const setAuthorGlobal = (authorGlobal) => {
    return {
      type: 'SET_AUTHOR',
      payload: {
        authorGlobal: authorGlobal
      }
    }
  }

  export const setStatusGlobal = (statusGlobal) => {
    return {
      type: 'SET_STATUS',
      payload: {
        statusGlobal: statusGlobal
      }
    }
  }

  export const setPageGlobal = (page) => {
    return {
      type: 'SET_PAGE',
      payload: {
        pageGlobal: page
      }
    }
  }

  export const setRowInPerPageGlobal = (rowInPerPageGlobal) => {
    return {
      type: 'SET_ROW_IN_PER_PAGE',
      payload: {
        rowInPerPageGlobal: rowInPerPageGlobal
      }
    }
  }

  export const setStartDateGlobal = (startDate) => {
    return {
      type: 'SER_START_DATE',
      payload: {
        startDateGlobal: startDate
      }
    }
  }

  export const setEndDateGlobal = (endDate) => {
    return {
      type: 'SER_END_DATE',
      payload: {
        endDateGlobal: endDate
      }
    }
  }