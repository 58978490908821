import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Divider, Grid, Button, TextField } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Swal from 'sweetalert2';
import { getData, postData } from '../../provider/data-loader';
import constant from '../../constant/constant';
import PartOfSpeechTable from './components/List';
import PartOfSpeechToolBar from './components/Toolbar';
import CustomSelect from 'components/select/custom-select';
import SyncLoader from 'react-spinners/SyncLoader';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  btn: {
    textTransform: 'capitalize',
    fontFamily: 'calibri'
  },
  enFont: {
    fontFamily: 'calibri',
    fontSize: '18px'
  },
  loading: {
    position: 'absolute',
    flexGrow: 1,
    display: 'flex',
    width: '80%',
    height: '80%',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const partOfSpeechs = [
  { value: 'kh', label: 'kh', },
  { value: 'en', label: 'en' },
];

const PartOfSpeech = props => {
  const classes = useStyles();
  const [listPartofSpeech, setListPartOfspeech] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPartOfSpeech, setSelectPartOfSpeech] = useState(
    {
      value: 'kh',
      label: "kh"
    });

  const [partofSpeech, setPartOfSpeech] = useState({
    name: '',
    type: '',
  });
  const [nameErrTxt, setNameErrTxt] = useState('');
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(10);
  const [total, setTotal] = useState(0);
  const [partOfSpeechFilter, setPartOfSpeechFilter] = useState('');

  const handleChangeValue = (option) => {
    console.log(option.value);
    setSelectPartOfSpeech({
      value: option.value,
      label: option.label,
    });
  }

  const onOpenPartOfSpeechModal = () => {
    setOpenModal(true);
  };

  const onClosePartOfSpeechModal = () => {
    setOpenModal(false);
  };

  function handleValidateData() {
    let formIsValid = true;
    if (partofSpeech.name === '') {
      formIsValid = false;
      setNameErrTxt('Please input your part of speech name');
    }
    return formIsValid;
  }

  const handleChange = event => {
    setPartOfSpeech({
      ...partofSpeech,
      [event.target.name]: event.target.value,
    });
    setNameErrTxt('');
  };

  const clear = () => {
    setPartOfSpeech({
      name: '',
      type: ''
    });
    setSelectPartOfSpeech({
      value: 'kh',
      label: "kh"
    })
  };

  const handleOnSave = () => {
    const token = window.sessionStorage.getItem(constant.sessionKey);
    if (handleValidateData()) {
      let obj = {
        name: partofSpeech.name,
        type: selectedPartOfSpeech.value
      }
      setIsLoading(true);
      postData(constant.endPoint.partofspeech + '?token=' + token, obj)
        .then(response => {
          if (response.code === 1) {
            setIsLoading(false);
            setOpenModal(false);
            Swal.fire({
              icon: 'success',
              title: 'ADD SUCCESS',
            });
            clear();
            handleRefresh();
          } else if (response.code === -1) {
            setOpenModal(false);
            Swal.fire({
              icon: 'warning',
              text: 'ថ្នាក់ពាក្យនេះមានរួចហើយ​​ សូមបញ្ចូលថ្នាក់ពាក្យថ្មី',
            });
          } else {
            setIsLoading(false);
            Swal.fire({
              icon: 'error',
              title: 'Opps, something went wrong.',
              html:
                '<div style="font-family: Battambang-Regular; font-size: 14 "> <p>' +
                response.message +
                '</p></div>',
            });
          }
        })
        .catch(err => {
          setIsLoading(false);
          Swal.fire({
            icon: 'error',
            title: 'Opps, something went wrong.',
            html:
              '<div style="font-family: Battambang-Regular; font-size: 14 "> <p>' +
              err +
              '</p></div>',
          });
        });
    } else {
      console.log("No Validation")
    }
  };

  function getPartOfSpeech() {
    const token = window.sessionStorage.getItem(constant.sessionKey);
    setIsLoading(true);
    let query = `?token=${token}&page=${page + 1}&count=${count}`
    if (search.trim().length > 0) {
      query += "&name=" + search;
    }
    if (partOfSpeechFilter !== 'all') {
      query += "&type=" + partOfSpeechFilter;
    }
    if (PartOfSpeech)
      getData(constant.endPoint.partofspeech + '?' + query).then(data => {
        setIsLoading(false);
        if (data.code === 1) {
          setListPartOfspeech(data.data);
          setTotal(data.pagination.total_items);
        } else if (data.code === 0) {
          console.log('error clients');
        } else {
          console.log('error server');
        }
      }).catch(error => {
        setIsLoading(false)
        console.log(error);
      })
  }

  useEffect(getPartOfSpeech, [page, count, search, partOfSpeechFilter]);

  const handleRefresh = e => {
    getPartOfSpeech();
  };

  const onSearchHandle = (e) => {
    setSearch(e.target.value);
  }
  const onChangePage = (event, page) => {
    setPage(page);
  }

  const onChangeRowsPerPage = (event) => {
    setCount(event.target.value);
  }
  const handleFilterByPartOfSpeechType = (value) => {
    setPartOfSpeechFilter(value);
  }
  return (
    <div className={classes.root}>
      {isLoading && (
        <div className={classes.loading}>
          <SyncLoader
            size={15}
            color={constant.color.main}
            loading={isLoading}
          />
        </div>
      )}
      <PartOfSpeechToolBar
        handleRefresh={handleRefresh}
        onOpenPartOfSpeechModal={onOpenPartOfSpeechModal}
        onSearchHandle={onSearchHandle}
        handleFilterByPartOfSpeechType={handleFilterByPartOfSpeechType}
      />
      <div className={classes.content}>
        <PartOfSpeechTable listPartofSpeech={listPartofSpeech} handleRefresh={handleRefresh} total={total}
          page={page}
          count={count}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage} />
        <div>
          <Dialog
            fullWidth={true}
            open={openModal}
            onClose={onClosePartOfSpeechModal}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">
              <span className={classes.enFont}>Part Of Speech</span>
            </DialogTitle>
            <Divider />
            <DialogContent style={{ paddingBottom: 80 }}>
              <Grid container spacing={1} style={{ display: 'flex', flexFlow: 'row' }}>
                <Grid item md={12} xs={12}>
                  <TextField
                    error={nameErrTxt !== '' ? true : false}
                    helperText={partofSpeech.name === '' ? nameErrTxt : ''}
                    fullWidth
                    label="Name"
                    margin="dense"
                    name="name"
                    onChange={handleChange}
                    required
                    value={partofSpeech.name}
                    variant="outlined"
                    InputProps={{
                      className: classes.enFont
                    }}
                  />
                </Grid>
                <Grid item md={12} xs={12} style={{ marginTop: 8, width: '100%' }}>
                  <CustomSelect
                    options={partOfSpeechs}
                    height='30px'
                    selectedOption={selectedPartOfSpeech}
                    handleChangeValue={handleChangeValue} />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={onClosePartOfSpeechModal}
                color="default"
                variant="outlined"
                className={classes.btn}
              >
                Cancel
              </Button>
              <Button
                onClick={handleOnSave}
                color="primary"
                variant="contained"
                className={classes.btn}
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </div>
  );
};

export default PartOfSpeech;
