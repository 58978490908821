import React from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types'
import { IconButton } from "@material-ui/core";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

const useStyles = makeStyles(theme => ({
	content: {
		width: '600px',
		padding: 0,
		margin: 0,
		display: 'flex',
		justifyContent: 'center',
	},
	img: {
		position: 'absolute', 
		width: '100%', 
		display: 'flex', 
		justifyContent: 'flex-end',
	},
	dialogStyle: {
		background: 'none',
		boxShadow: 'none'
    },
}))

const ImageDialog = props => {
	const { openImgDialog, imageLink, handleCloseImageDialog } = props;
	const classes = useStyles();

	return (
		<Dialog
			open={openImgDialog}
			onClose={handleCloseImageDialog}
			fullWidth={true}
			aria-labelledby="form-dialog-title"
			classes={{
                paper: classes.dialogStyle
            }}
		>
			<DialogContent className={classes.content}>
				<img src={imageLink} width="600px" height="600px" style={{objectFit: 'contain'}}  alt="word" />
				<div className={classes.img}>
					<IconButton 
						aria-label="add to shopping cart" 
						onClick={handleCloseImageDialog}
						style={{color: 'white'}}>
						<HighlightOffIcon style={{fontSize: '24'}}/>
					</IconButton>	
				</div>
			</DialogContent>
		</Dialog>
	)
}

ImageDialog.propTypes = {
	className: PropTypes.string,
	openImgDialog: PropTypes.bool,
	imageLink: PropTypes.string,
	handleCloseImageDialog: PropTypes.func,
}

export default ImageDialog
