import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { UsersToolbar, UsersTable } from './components';
import { getData } from '../../provider/data-loader';
import constant from '../../constant/constant';
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));
let oldData = [];
let secondData = []
const UserList = props => {
  const classes = useStyles();
  const [requests, setRequests] = useState([]);

  function getEnquiryData(category = 'all') {
    const token = window.sessionStorage.getItem(constant.sessionKey);
    let url = constant.endPoint.changeRequest + '?token=' + token;
    if (category !== 'all') {
      url =
        constant.endPoint.changeRequest +
        '?token=' +
        token +
        '&category=' +
        category;
    }
    getData(url).then(data => {
      if (data.code === 1) {
        setRequests(data.data);
        oldData = data.data;
        secondData = oldData;
        // console.log(secondData);
      } else if (data.code === 0) {
        console.log('error clients');
      } else {
        console.log('error server');
      }
    });
  }
  useEffect(() => {
    getEnquiryData();
  }, []);

  const handleRefresh = e => {
    getEnquiryData();
  };

  const handleChangeCategory = category => {
    getEnquiryData(category);
  };

  const onSearchChange = e => {
    console.log(secondData);
    let search = e.target.value;
    console.log('search:', search);
    if (search.trim().length > 0) {
      let data = oldData.filter(item => {
        let ekWord = item.en_kh_word;
        let kkWord = item.kh_kh_word;
        let keWord = item.kh_en_word;
        if (ekWord != null) {
          if (ekWord.word.includes(search)) return true;
        }
        if (kkWord != null) {
          if (kkWord.word.includes(search)) return true;
        }
        if (keWord != null) {
          if (keWord.word.includes(search)) return true;
        }
        return false;
      });
      setRequests(data);
    } else {
      setRequests(secondData);
    }
  }
  return (
    <div className={classes.root}>
      <UsersToolbar
        handleRefresh={handleRefresh}
        onSearchChange={onSearchChange}
        handleChangeCategory={handleChangeCategory}
      />
      <div className={classes.content}>
        <UsersTable requests={requests} handleRefresh={handleRefresh} />
      </div>
    </div>
  );
};

export default UserList;
