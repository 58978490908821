import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import c from 'constant/constant';
import clsx from 'clsx';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
  TextField,
  Grid,
} from '@material-ui/core';
import { postData } from 'provider/data-loader';
import '../../../index.css';
import Swal from 'sweetalert2';
import constants from 'constant/constant';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexFlow: 'column',
    height: '100%',
  },
  content: {
    flexGrow: 1,
  },
  myContent: {
    marginTop: '20px',
    paddingBottom: 20,
    paddingRight: 5
  },
}));

const Enquiry = props => {
  const { className } = props;
  const classes = useStyles();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [question, setQuestion] = useState('');
  const [nameErrorText, setNameErrorText] = useState('');
  const [emailErrorText, setEmailErrorText] = useState('');
  const [questionErrorText, setQuestionErrorText] = useState('');
  const [check, setCheck] = useState('');
  const isValid = () => {
    let formIsValid = true;
    if (name === '') {
      formIsValid = false;
      setNameErrorText('សូមបញ្ចូលឈ្មោះឲ្យបានត្រឹមត្រូវ');
    }
    if (email === '') {
      formIsValid = false;
      setEmailErrorText('សូមបញ្ចូលអ៊ីម៉ែលឲ្យបានត្រឹមត្រូវ');
    }
    if (question === '') {
      formIsValid = false;
      setQuestionErrorText('សូមបញ្ចូលនិយមន័យភាសាខ្មែរ');
    }
    return formIsValid;
  };

  const clear = () => {
    setName('');
    setEmail('');
    setQuestion('');
}

  const submitWord = () => {
    if (isValid()) {
      setCheck(true);
      let data = {
        name: name,
        email: email,
        question: question,
      };
      postData(constants.endPoint.enquiry, data)
        .then(response => {
          if (response.code === 1) {
            clear();
            setCheck(false);
            Swal.fire({
              icon: 'success',
              title: 'សូមអរគុណចំពោះការទាក់ទងរបស់អ្នក',
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Opps, something went wrong.',
              html:
                '<div style="font-family: Battambang-Regular; font-size: 14 "> <p>' +
                response.message +
                '</p></div>',
            });
          }
        })
        .catch(e => {
          Swal.fire({
            icon: 'error',
            title: 'Opps, something went wrong.',
            html:
              '<div style="font-family: Battambang-Regular; font-size: 14 "> <p>' +
              e +
              '</p></div>',
          });
        });
    } else {
      setCheck(false);
    }
  };

  return (
    <div
      className={clsx({
        [classes.root]: true,
      })}
    >
      <div className={classes.content}>
        <div className={classes.myContent}>
          <Card className={clsx(classes.root, className)}>
            <form>
              <CardHeader
                title={
                  <p
                    style={{
                      color: c.color.main,
                      fontFamily: c.fontFamily.Khmer_Battambong_Regular,
                      fontSize: c.fontSize.text,
                    }}
                  >
                    មានសំណួរ
                  </p>
                }
              />
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs>
                    <TextField
                      error={name === '' && check ? true : false}
                      id="name"
                      fullWidth
                      placeholder="បញ្ចូលឈ្មោះរបស់អ្នក"
                      label="ឈ្មោះរបស់អ្នក"
                      name="name"
                      type="text"
                      variant="outlined"
                      value={name}
                      required={true}
                      helperText={nameErrorText}
                      onChange={e => setName(e.currentTarget.value)}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs>
                    <TextField
                      error={email === '' && check ? true : false}
                      fullWidth
                      placeholder="បញ្ចូលអ៊ីម៉ែលរបស់អ្នក"
                      label="អ៊ីម៉ែលរបស់អ្នក"
                      name="email"
                      type="text"
                      variant="outlined"
                      value={email}
                      required={true}
                      helperText={emailErrorText}
                      onChange={e => setEmail(e.currentTarget.value)}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs>
                    <TextField
                      error={question === '' && check ? true : false}
                      fullWidth
                      label="សំណួររបស់អ្នក"
                      placeholder="បញ្ចូលសំណួររបស់អ្នក"
                      name="question"
                      type="text"
                      variant="outlined"
                      value={question}
                      multiline={true}
                      required={true}
                      rows={4}
                      helperText={question === '' ? questionErrorText : null}
                      onChange={e => setQuestion(e.currentTarget.value)}
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <CardActions>
                <Button
                  color="primary"
                  variant="outlined"
                  style={{
                    fontFamily: c.fontFamily.Khmer_Battambong_Regular,
                    width: '200px',
                  }}
                  onClick={() => props.history.push('/')}
                >
                  ត្រលប់ក្រោយ
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  style={{
                    fontFamily: c.fontFamily.Khmer_Battambong_Regular,
                    width: '100px',
                  }}
                  onClick={submitWord}
                >
                  ផ្ញើ
                </Button>
              </CardActions>
            </form>
          </Card>
        </div>
      </div>
    </div>
  );
};

Enquiry.propTypes = {
  className: PropTypes.string,
};

export default Enquiry;
