import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Button,
  Typography,
  CardActions,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Divider,
  Box,
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import {
  getData,
} from '../../provider/data-loader';
import c from '../../constant/constant';
import Swal from 'sweetalert2';
import SyncLoader from 'react-spinners/SyncLoader';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import PerfectScrollbar from 'react-perfect-scrollbar';
import moment from 'moment';
import WordsToolBar from './ProductsToolbar/ProductsToolbar';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    width: '100%',
    height: '100%',
    fontSize: c.fontSize.placholder,
  },
  loading: {
    display: 'flex',
    width: '80%',
    height: '80%',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
  },
  content: {
    marginTop: theme.spacing(2),
  },
  pagination: {
    marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  button: {
    marginRight: '8px',
    fontSize: c.fontSize.placholder,
    '&:hover': {
      background: c.color.darkgreen,
    },
  },
  iconButton: {
    margin: theme.spacing(0.5),
    textTransform: 'capitalize',
    fontFamily: 'Calibri',
  },
  grid: {
    padding: 16,
    margin: 0,
  },
  actionBtn: {
    backgroundColor: c.color.green,
  },
  textSize: {
    fontSize: c.fontSize.text,
  },
  engFontFamily: {
    fontFamily: 'Calibri',
    fontSize: c.fontSize.text,
  },
  actions: {
    justifyContent: 'flex-end',
  },
  word: {
    fontSize: 30,
    fontWeight: 'bold',
    paddingTop: 10,
    float: 'left',
  },
  subTitle: {
    fontSize: c.fontSize.text,
    color: c.color.text,
    marginTop: 5,
    marginBottom: 5,
  },
  partOfSpeech: {
    fontSize: c.fontSize.title,
    color: c.color.red,
    fontWeight: 'bold',
  },
  meaning: {
    paddingLeft: 20,
    paddingTop: 10,
    paddingBottom: 10,
    fontFamily: c.fontFamily.Khmer_Battambong_Regular,
  },
  number: {
    float: 'left',
    minWidth: 20,
    color: c.color.text,
  },
  example: {
    paddingLeft: 20,
    paddingTop: 10,
    color: c.color.text,
  },
  row: {
    display: 'flex',
    flexFlow: 'row',
  },
  detail: {
    fontFamily: 'Calibri',
    fontSize: c.fontSize.title,
  },
  btn: {
    fontFamily: 'Calibri',
    textTransform: 'capitalize',
  },
  imageRequest: {
    float: 'left',
    textAlign: 'center',
  },
  commentRequest: {
    display: 'flex',
    flexFlow: 'column',
  },
  view: {
    backgroundColor: c.color.green,
    '&:hover': {
      backgroundColor: c.color.darkgreen,
    },
  },
  delete: {
    backgroundColor: c.color.red,
    '&:hover': {
      backgroundColor: c.color.darkRed,
    },
  },
  edit: {
    backgroundColor: c.color.yellow,
    '&:hover': {
      backgroundColor: c.color.darkYellow,
    },
  },
  publish: {
    backgroundColor: c.color.main,
    '&:hover': {
      backgroundColor: c.color.darkMain,
    },
  },
  tableContent: {
    fontSize: c.fontSize.placholder,
  },
  enFont: {
    fontFamily: 'calibri',
  },
  comment: {
    display: 'flex',
    flexFlow: 'row',
    borderBottom: '1px solid gray',
    paddingBottom: '10px',
  },
  userIcon: {
    display: 'flex',
    flexFlow: 'row',
    paddingRight: '32px',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const AllWordList = props => {
  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [words, setWords] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [viewWord, setViewWord] = useState(null);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [openImageRequestModal, setOpenImageRequestModal] = useState(false);
  const [category, setCategory] = useState("all");
  const [search, setSearch] = useState('');
  const [totalItems, setTotalItems] = useState(0);

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  useEffect(() => {
    getAllWord();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, search, category]);

  const getAllWord = () => {
    const token = window.sessionStorage.getItem(c.sessionKey);
    let query = `?token=${token}&page=${page + 1}&count=${rowsPerPage}&search=${search}`;
    if (category !== 'all') {
      query += "&category=" + category;
    }
    getData(c.endPoint.allWord + query)
      .then(data => {
        if (data.code === 1) {
          setWords(data.data);
          setTotalItems(data.pagination.total_items);
          setIsLoading(false);
        } else if (data.code === 0) {
          alert("Please check your connection");
        } else {
          alert("Something went wrong");
        }
      })
      .catch(err => {
        setIsLoading(false);
        Swal.fire({
          icon: 'error',
          text: 'Something went wrong',
        });
      });
  };

  const handleRefresh = () => {
    getAllWord()
  };

  const handleSearch = e => {
    e.preventDefault();
    setSearch(e.target.value);
  };

  const handleClickOpenViewModal = (event, word) => {
    setOpenViewModal(true);
    setViewWord(word);
  };
  const handleCloseViewModal = () => {
    setOpenViewModal(false);
  };

  const handleCloseImageRequestModal = () => {
    setOpenImageRequestModal(false);
  };

  const handleChangeCategory = type => {
    setCategory(type);
  };

  return (
    <div className={classes.root}>
      {isLoading && (
        <div className={classes.loading}>
          <SyncLoader size={15} color={c.color.main} loading={isLoading} />
        </div>
      )}
      <div>
        <WordsToolBar
          handleRefresh={handleRefresh}
          handleSearch={handleSearch}
          handleChangeCategory={handleChangeCategory}
        />
        <div className={classes.content}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12}>
              <Paper className={classes.paper}>
                <PerfectScrollbar>
                  <div className={classes.inner}>
                    <Table>
                      <TableHead>
                        <TableRow className={classes.tableTitle}>
                          <TableCell className={classes.engFontFamily}>
                            No
                          </TableCell>
                          <TableCell className={classes.engFontFamily}>
                            Category
                          </TableCell>
                          <TableCell className={classes.engFontFamily}>
                            Word
                          </TableCell>
                          <TableCell className={classes.engFontFamily}>
                            Part of Speech
                          </TableCell>
                          <TableCell className={classes.engFontFamily}>
                            {/* <Typography className={classes.engFontFamily}>
                              Image
                            </Typography>
                            <Typography className={classes.engFontFamily}>
                              Request
                            </Typography> */}
                            Definition
                          </TableCell>
                          {/* <TableCell className={classes.engFontFamily}>
                            Comments
                          </TableCell> */}
                          <TableCell className={classes.engFontFamily}>
                            Date
                          </TableCell>
                          <TableCell className={classes.engFontFamily}>
                            Status
                          </TableCell>
                          <TableCell className={classes.engFontFamily}>
                            Action
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {words.map((word, index) => (
                          <TableRow
                            className={classes.tableRow}
                            hover
                            key={index}
                          >
                            <TableCell className={classes.tableContent}>
                              {(page * rowsPerPage) + (index + 1)}
                            </TableCell>
                            <TableCell>
                              <Typography
                                variant="body1"
                                className={classes.tableContent}
                              >
                                {word.type}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography
                                variant="body1"
                                className={classes.tableContent}
                              >
                                {word.word}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              {
                                word.definitions.map((def, index) => (
                                  <div key={index} className={classes.row}>
                                    {
                                      word.definitions.length > 1 &&
                                      <Typography style={{ marginRight: '8px' }}>{index + 1}.</Typography>
                                    }
                                    <Typography
                                      variant="body1"
                                      key={index}
                                      className={`${classes.tableContent} ${classes.enFont}`}
                                    >
                                      {def.part_of_speech}
                                    </Typography>
                                  </div>
                                ))
                              }
                            </TableCell>
                            <TableCell style={{ textAlign: 'center' }}>
                              {
                                word.definitions.map((def, index) => (
                                  <div key={index} className={classes.row}>
                                    {
                                      word.definitions.length > 1 &&
                                      <Typography style={{ marginRight: '8px' }}>{index + 1}.</Typography>
                                    }
                                    {
                                      def.meanings.map((mean, meanIndex) => (
                                        <Typography key={meanIndex} className={classes.enFont}>{mean.meaning}</Typography>
                                      ))
                                    }
                                  </div>
                                ))
                              }
                            </TableCell>
                            <TableCell className={classes.tableContent}>
                              {moment(word.createdAt).format('DD/MM/YYYY')}
                            </TableCell>
                            <TableCell
                              className={`${classes.tableContent} ${classes.enFont}`}
                            >
                              {word.status?.toUpperCase()}
                            </TableCell>
                            <TableCell>
                              <Button
                                variant="contained"
                                color="secondary"
                                size="small"
                                className={`${classes.iconButton} ${classes.view}`}
                                onClick={event =>
                                  handleClickOpenViewModal(event, word)
                                }
                              >
                                View
                               </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </div>
                </PerfectScrollbar>

                <CardActions className={classes.actions}>
                  <TablePagination
                    component="div"
                    count={totalItems}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handleRowsPerPageChange}
                    labelRowsPerPage="ចំនួនទិន្នន័យក្នុងមួយទំព័រ"
                    page={page}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[10, 50, 100, 200, 500, 1000]}
                  />
                </CardActions>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </div>

      <div>
        <Dialog
          open={openViewModal}
          TransitionComponent={Transition}
          keepMounted
          fullWidth={true}
          onClose={handleCloseViewModal}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            <Typography className={classes.detail}>View Detail</Typography>
          </DialogTitle>
          <DialogContent>
            {viewWord && (
              <div>
                <div className={classes.row} style={{ alignItems: 'flex-end' }}>
                  <p className={classes.word}>{viewWord.word}</p>
                </div>
                <p className={classes.subTitle}>[{viewWord.word}]</p>
                <Divider />
                <div className={classes.meaning} style={{ width: '100%' }}>
                  {viewWord.definitions.map((item, index) => {
                    return (
                      <div key={index}>
                        <p className={classes.partOfSpeech}>
                          {item.part_of_speech}
                        </p>
                        {item.meanings.map((mean, meanIdex) => {
                          return (
                            <div key={meanIdex}>
                              <div className={classes.meaning}>
                                <div>
                                  <p className={classes.number}>
                                    {meanIdex + 1}
                                  </p>
                                  <p className={classes.meaningText}>
                                    {mean.meaning}
                                  </p>
                                </div>
                                {mean.examples.map((example, Exindex) => {
                                  return (
                                    <div
                                      className={classes.meaning}
                                      key={Exindex}
                                    >
                                      <p className={classes.exampleText}>
                                        {example}
                                      </p>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
                <div style={{ width: '100%' }}>
                  {viewWord.images.length !== 0 && (
                    <Grid container>
                      {viewWord.images.map((item, index) => {
                        return (
                          <Box
                            border={1}
                            key={index}
                            borderColor="#cfd8dc"
                            style={{ padding: 5, marginLeft: 5, marginTop: 5 }}
                          >
                            <Grid item xs={12} sm={6} md={6}>
                              <img
                                src={c.endPoint.images + '/' + item}
                                width="120"
                                height="120"
                                alt={'Image' + index}
                              />
                            </Grid>
                          </Box>
                        );
                      })}
                    </Grid>
                  )}
                </div>
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseViewModal}
              color="primary"
              variant="outlined"
              className={classes.btn}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>

      <div>
        <Dialog
          open={openImageRequestModal}
          TransitionComponent={Transition}
          keepMounted
          fullWidth={true}
          onClose={handleCloseImageRequestModal}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            <Typography className={classes.detail}>View Detail</Typography>
          </DialogTitle>
          <DialogContent>
            {viewWord && (
              <div>
                <div style={{ width: '100%' }}>
                  {viewWord.images.length !== 0 && (
                    <Grid container>
                      {viewWord.images.map((item, index) => {
                        return (
                          <Box
                            border={1}
                            key={index}
                            borderColor="#cfd8dc"
                            style={{ padding: 5, marginLeft: 5, marginTop: 5 }}
                          >
                            <Grid item xs={12} sm={6} md={6}>
                              <img
                                src={c.endPoint.images + '/' + item}
                                width="120"
                                height="120"
                                alt={'Image' + index}
                              />
                            </Grid>
                          </Box>
                        );
                      })}
                    </Grid>
                  )}
                </div>
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseImageRequestModal}
              color="primary"
              variant="outlined"
              className={classes.btn}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default AllWordList;
