import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import SaveIcon from '@material-ui/icons/Save';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Swal from 'sweetalert2';
import {
  Divider,
  Grid,
  TextField,
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Button,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import constant from '../../../../constant/constant';
import { putData, deleteData } from '../../../../provider/data-loader';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 1050,
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: 'flex-end',
  },
  button: {
    margin: theme.spacing(1),
    textTransform: 'capitalize',
    fontFamily: 'Calibri'
  },
  text: {
    fontFamily: 'Calibri',
    fontSize: constant.fontSize.text,
  },
  tableContent: {
    fontSize: constant.fontSize.placholder,
    fontFamily: 'Calibri'
  },
  delete: {
    backgroundColor: constant.color.red,
    '&:hover': {
      backgroundColor: constant.color.darkRed,
    },
  }
}));

const UsersTable = props => {
  const { className, versions, handleRefresh, onItemClick, ...rest } = props;
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deletedVersion, setDeletedVersion] = useState(null);
  const [version, setVersion] = React.useState({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
  });

  const onCloseAddUserModal = () => {
    console.log('called onCloseAddUserModal');
    setOpenModal(false);
  };

  const handleChange = event => {
    setVersion({
      ...version,
      [event.target.name]: event.target.value,
    });
  };

  const handleClickOpenDeleteModal = (event, deleteItem) => {
    setOpenDeleteModal(true);
    setDeletedVersion(deleteItem);
  };

  const onConfirmDelete = () => {
    setOpenDeleteModal(false);
    if (deletedVersion) {
      deleteData(
        constant.endPoint.version +
          '/' +
          deletedVersion._id +
          '?token=' +
          window.sessionStorage.getItem(constant.sessionKey)
      )
        .then(response => {
          if (response.code === 1) {
            Swal.fire({
              icon: 'success',
              title: 'លុបទិន្នន័យបានជោគជ័យ',
            });
            handleRefresh();
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Opps, something went wrong.',
              html:
                '<div style="font-family: Battambang-Regular; font-size: 14 "> <p>' +
                response.message +
                '</p></div>',
            });
          }
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            title: 'Opps, something went wrong.',
            html:
              '<div style="font-family: Battambang-Regular; font-size: 14 "> <p>' +
              err +
              '</p></div>',
          });
        });
    }
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handleOnUpdate = () => {
    const token = window.sessionStorage.getItem(constant.sessionKey);
    putData(
      constant.endPoint.version + '/' + version._id + '?token=' + token,
      version
    )
      .then(response => {
        if (response.code === 1) {
          setOpenModal(false);
          Swal.fire({
            icon: 'success',
            title: 'កែប្រែជោគជ័យ',
          });
          handleRefresh();
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Opps, something went wrong.',
            html:
              '<div style="font-family: Battambang-Regular; font-size: 14 "> <p>' +
              response.message +
              '</p></div>',
          });
        }
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          title: 'Opps, something went wrong.',
          html:
            '<div style="font-family: Battambang-Regular; font-size: 14 "> <p>' +
            err +
            '</p></div>',
        });
      });
  };

  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  return (
    <div>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.text}>#</TableCell>
                    <TableCell className={classes.text}>Version</TableCell>
                    <TableCell className={classes.text}>Note</TableCell>
                    <TableCell className={classes.text}>Created Date</TableCell>
                    <TableCell className={classes.text}>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {versions.slice(0, rowsPerPage).map((version, index) => (
                    <TableRow
                      className={classes.tableRow}
                      hover
                      key={version._id}
                    >
                      <TableCell className={classes.tableContent}>{(page * rowsPerPage) + (index + 1)}</TableCell>
                      <TableCell className={classes.tableContent}>{version.version}</TableCell>
                      <TableCell className={classes.tableContent}>{version.note}</TableCell>
                      <TableCell className={classes.tableContent}>
                        {moment(version.createdAt).format('DD/MM/YYYY')}
                      </TableCell>
                      <TableCell>
                        {/* <Button
                          variant="outlined"
                          color="secondary"
                          size="small"
                          className={classes.button}
                          onClick={event => onOpenAddUserModal(event, version)}
                          startIcon={<EditIcon />}
                        >
                          កែប្រែ
                        </Button> */}
                        <Button
                          variant="contained"
                          color="secondary"
                          size="small"
                          onClick={event =>
                            handleClickOpenDeleteModal(event, version)
                          }
                          className={`${classes.button} ${classes.delete}`}
                          startIcon={<DeleteIcon />}
                        >
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={versions.length}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            labelRowsPerPage="ចំនួនទិន្នន័យក្នុងមួយទំព័រ"
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </CardActions>
      </Card>
      <div>
        <Dialog
          open={openModal}
          onClose={onCloseAddUserModal}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Edit Version</DialogTitle>
          <Divider />
          <DialogContent>
            <Grid container spacing={1}>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Version"
                  margin="dense"
                  name="version"
                  onChange={handleChange}
                  required
                  value={version.version}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Note"
                  margin="dense"
                  name="note"
                  onChange={handleChange}
                  value={version.note}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className={classes.dialogAction}>
            <Button
              onClick={onCloseAddUserModal}
              color="default"
              variant="outlined"
            >
              បោះបង់
            </Button>
            <Button
              onClick={handleOnUpdate}
              color="primary"
              variant="contained"
              startIcon={<SaveIcon />}
            >
              កែប្រែ
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <div>
        <Dialog
          open={openDeleteModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleCloseDeleteModal}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">បញ្ជាក់</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              តើអ្នកពិតជាចង់លុបជំនាន់នៃពាក្យ
              {deletedVersion ? ' ' + deletedVersion.version : ''}
              ??
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseDeleteModal}
              color="primary"
              variant="outlined"
            >
              បោះបង់
            </Button>
            <Button
              onClick={onConfirmDelete}
              variant="contained"
              color="secondary"
            >
              លុប
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

UsersTable.propTypes = {
  className: PropTypes.string,
  versions: PropTypes.array.isRequired,
  handleRefresh: PropTypes.func,
};

export default UsersTable;
