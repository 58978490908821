import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import c from 'constant/constant';
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexFlow: 'column',
    height: '100%',
  },
  content: {
    flexGrow: 1,
  },
  title: {
    width: '100%',
    marginTop: '20px',
    textAlign: 'center',
    fontSize: '30px',
    color: c.color.main,
    fontWeight: 'bold',
  },
  about: {
    marginBottom: '10px',
  },
  text: {
    marginTop: '5px',
    textIndent: '50px',
    lineHeight: 2,
    textAlign: 'justify',
  },
}));

const About = props => {
  const classes = useStyles();

  return (
    <div
      className={clsx({
        [classes.root]: true,
      })}
    >
      <div className={classes.content}>
          <div class={classes.title}>
            អំពីប្រជុំវចនានុក្រម និងវាក្យបរិវត្តន៍
          </div>
          <div class={classes.about}>
            <p class={classes.text}>
              Eang Sopahlleth The PEDIA គឺជាសព្វវចនាធិប្បាយខ្មែរ
              ប្រជុំវចនានុក្រម ដែលបង្កើតឡើងតាមរយៈកំណត់ត្រារបស់ខ្ញុំ ហើយបានចងក្រង
              និងធ្វើទំនើបកម្មជាប្រចាំក្នុងគោលដៅអភិរក្ស
              និងអភិវឌ្ឍអក្សរសាស្ត្រខ្មែរសម្រាប់សាធារណជន
              ប្រើប្រាស់ប្រកបដោយសុក្រឹតភាព។
            </p>
            <p class={classes.text}>
              Eang Sopahlleth The PEDIA
              បើកចំហការចូលរួមពីសាធារណជនតាមរយៈការទទួលយកមតិយោបល់ និងការបន្ថែមពាក្យ
              ថ្មីៗពីសាធារណជន។ ដើម្បីធានាបាននូវព័ត៌មានដែលមានសុក្រិតភាព
              រាល់មតិយោបល់ និងពាក្យថ្មីៗនឹងត្រូវបានត្រួតពិនិត្យដោយ
              បណ្ឌិតសភាចារ្យ ឬក្រុមអ្នកជំនាញអក្សរសាស្ត្រ
              រួចទើបបង្ហោះដាក់ចូលក្នុងកម្មវិធីនេះ
              ដោយមានរំលេចឈ្មោះសាធារណជនដែលបានចូលរួមចំណែកផ្តល់មតិយោបល់
              និងបន្ថែមពាក្យថ្មីៗទាំងនោះ។
            </p>
            <p class={classes.text}>
              Eang Sopahlleth The PEDIA នឹងធ្វើទំនើបកម្មជាប្រចាំ
              ដើម្បីដើរឱ្យទាន់វឌ្ឍនភាពនៃសង្គមនាសតវត្សរ៍ទី២១ នេះផង
              និងដើម្បីអភិវឌ្ឍឱ្យកាន់តែប្រសើរឡើងជាប្រចាំ
              ក្នុងគោលដៅធានាឱ្យអ្នកប្រើប្រាស់
              នូវសុក្រឹតភាពវាក្យសព្ទជាមួយកម្មវិធី Eang Sopahlleth The PEDIA ។
            </p>
          </div>
          <div class={classes.title}>Eang Sopahlleth The PEDIA</div>
          <div class={classes.about}>
            <p class={classes.text}>
              Eang Sopahlleth The PEDIA is an integration of Khmer lexicons and
              Khmer dictionaries that I developed from my notes; updated and
              modernized to conserve and develop Khmer phrases and words for the
              public to use accurately.
            </p>
            <p class={classes.text}>
              The contents of Eang Sopahlleth The PEDIA are open to the public
              for comments and suggestions. Suggestions in the form of comments
              for corrections or new words will be reviewed by academia and/or
              experts. When suggestions are approved, changes will be made or a
              new word will be added to the application along with the name of
              the person who made the suggestion.
            </p>
            <p class={classes.text}>
              Eang Sopahlleth The PEDIA will be updated regularly. It is to
              advance alongside social development in the 21st century. This
              modernization guarantees the accuracy of the phrases or words in
              Eang Sopahlleth The PEDIA.
            </p>
          </div>
      </div>
    </div>
  );
};

About.propTypes = {
  className: PropTypes.string,
};

export default About;
