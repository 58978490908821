import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Swal from 'sweetalert2';
// import ReplyIcon from '@material-ui/icons/Reply';
import EmailShare from 'react-email-share-link';
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Button,
  Grid,
  Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import constant, { getUser } from '../../../../constant/constant';
import { deleteData } from '../../../../provider/data-loader';
// import { EmailShareButton } from 'react-share';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 1050,
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: 'flex-end',
  },
  button: {
    margin: theme.spacing(0.5),
    textTransform: 'capitalize',
    fontFamily: 'calibri',
  },
  text: {
    fontFamily: 'calibri',
    fontSize: constant.fontSize.text,
  },
  tableContent: {
    fontSize: constant.fontSize.placholder,
    fontFamily: 'Calibri',
  },
  iconButton: {
    margin: theme.spacing(0.5),
    textTransform: 'capitalize',
    fontFamily: 'Calibri',
  },
  delete: {
    backgroundColor: constant.color.red,
    '&:hover': {
      backgroundColor: constant.color.darkRed,
    },
  },
  enFont: {
    fontFamily: 'Calibri',
  },
  view: {
    backgroundColor: constant.color.green,
    '&:hover': {
      backgroundColor: constant.color.darkgreen,
    },
  },
  dialogRow: {
    padding: '8px 0px 8px 0px',
  },
  action: {
    display: 'flex',
    flexFlow: 'row',
  },
}));

const List = props => {
  const { className, enquiries, onItemClick, handleRefresh, ...rest } = props;
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteEnquiry, setDeletedEnquiry] = useState(null);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [enQueryView, setEnQueryView] = useState(null);

  const user = JSON.parse(getUser());

  const handleClickOpenDeleteModal = (event, selectedItem) => {
    setOpenDeleteModal(true);
    setDeletedEnquiry(selectedItem);
  };

  const handleOpenViewModal = (event, enquiry) => {
    setOpenViewModal(true);
    setEnQueryView(enquiry);
  };

  const handleCloseViewModal = () => {
    setOpenViewModal(false);
  };

  const onConfirmDelete = () => {
    setOpenDeleteModal(false);
    if (deleteEnquiry) {
      deleteData(
        constant.endPoint.enquiry +
          '/' +
          deleteEnquiry._id +
          '?token=' +
          window.sessionStorage.getItem(constant.sessionKey)
      )
        .then(response => {
          if (response.code === 1) {
            Swal.fire({
              icon: 'success',
              title: 'លុបទិន្នន័យបានជោគជ័យ',
            });
            handleRefresh();
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Opps, something went wrong.',
              html:
                '<div style="font-family: Battambang-Regular; font-size: 14 "> <p>' +
                response.message +
                '</p></div>',
            });
          }
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            title: 'Opps, something went wrong.',
            html:
              '<div style="font-family: Battambang-Regular; font-size: 14 "> <p>' +
              err +
              '</p></div>',
          });
        });
    }
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const classes = useStyles();

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  return (
    <div>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.text}>#</TableCell>
                    <TableCell className={classes.text}>Question</TableCell>
                    <TableCell className={classes.text}>Email</TableCell>
                    <TableCell className={classes.text}>Name</TableCell>
                    <TableCell className={classes.text}>Date</TableCell>
                    <TableCell className={classes.text}>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {enquiries.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((enquiry, index) => (
                    <TableRow
                      className={classes.tableRow}
                      hover
                      key={enquiry._id}
                    >
                      <TableCell className={classes.tableContent}>
                        {(page * rowsPerPage) + (index + 1)}
                      </TableCell>
                      <TableCell
                        className={`${classes.tableContent} ${classes.enFont}`}
                      >
                        {enquiry.question}
                      </TableCell>
                      <TableCell className={classes.text}>
                        {enquiry.email}
                      </TableCell>
                      <TableCell
                        className={`${classes.tableContent} ${classes.enFont}`}
                      >
                        {enquiry.name}
                      </TableCell>
                      <TableCell className={classes.tableContent}>
                        {moment(enquiry.createdAt).format('DD/MM/YYYY')}
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          size="small"
                          color="secondary"
                          className={`${classes.iconButton} ${classes.view}`}
                          onClick={event => handleOpenViewModal(event, enquiry)}
                        >
                          View
                        </Button>
                        {(user.role === constant.userRole.admin ||
                          user.role === constant.userRole.editor) && (
                          <div>
                            <Button
                              variant="outlined"
                              color="secondary"
                              size="small"
                              className={classes.iconButton}
                            >
                              {/* <EmailShareButton
                                body={enquiry.question}
                                subject="Answer"
                                style={{ height: 24, display: 'flex', justifyContent: 'center', }}
                              >
                                <ReplyIcon style={{ fontSize: 20 }} />
                                  Reply
                              </EmailShareButton> */}
                              <EmailShare
                                email={enquiry.email}
                                subject="Answer"
                                body={enquiry.question}
                              >
                                {link => (
                                  <a href={link} data-rel="external">
                                    Reply by email
                                  </a>
                                )}
                              </EmailShare>
                            </Button>
                            <Button
                              variant="contained"
                              color="secondary"
                              size="small"
                              className={`${classes.iconButton} ${classes.delete}`}
                              onClick={event =>
                                handleClickOpenDeleteModal(event, enquiry)
                              }
                              startIcon={<DeleteIcon />}
                            >
                              Delete
                            </Button>
                          </div>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={enquiries.length}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            labelRowsPerPage="ចំនួនទិន្នន័យក្នុងមួយទំព័រ"
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </CardActions>
      </Card>
      <div>
        <Dialog
          open={openDeleteModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleCloseDeleteModal}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">បញ្ជាក់</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              តើអ្នកពិតជាចង់លុបសំណួរនេះពី
              {deleteEnquiry ? ' ' + deleteEnquiry.name + ' ' : ''}
              មែនឬទេ ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseDeleteModal}
              color="primary"
              variant="outlined"
            >
              បោះបង់
            </Button>
            <Button
              onClick={onConfirmDelete}
              variant="contained"
              color="secondary"
            >
              លុប
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <div>
        {openViewModal && (
          <Dialog
            open={openViewModal}
            TransitionComponent={Transition}
            fullWidth={true}
            maxWidth={'sm'}
            onClose={handleCloseViewModal}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle id="alert-dialog-slide-title">
              <Typography className={classes.text}>View Detail</Typography>
            </DialogTitle>
            <DialogContent>
              <Grid container>
                <Grid item xs={4} className={classes.dialogRow}>
                  <p className={classes.text}>Question</p>
                </Grid>
                <Grid item xs={8} className={classes.dialogRow}>
                  <p className={classes.text}>{enQueryView.question}</p>
                </Grid>
                <Grid item xs={4} className={classes.dialogRow}>
                  <p className={classes.text}>Name</p>
                </Grid>
                <Grid item xs={8} className={classes.dialogRow}>
                  <p className={classes.text}>{enQueryView.name}</p>
                </Grid>
                <Grid item xs={4} className={classes.dialogRow}>
                  <p className={classes.text}>Email</p>
                </Grid>
                <Grid item xs={8} className={classes.dialogRow}>
                  <p className={classes.text}>{enQueryView.email}</p>
                </Grid>
                <Grid item xs={4} className={classes.dialogRow}>
                  <p className={classes.text}>Date</p>
                </Grid>
                <Grid item xs={8} className={classes.dialogRow}>
                  <p className={classes.text}>
                    {moment(enQueryView.createdAt).format('DD/MM/YYYY')}
                  </p>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleCloseViewModal}
                color="primary"
                variant="outlined"
                className={classes.button}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </div>
    </div>
  );
};

List.propTypes = {
  className: PropTypes.string,
  enquiries: PropTypes.array.isRequired,
  handleRefresh: PropTypes.func,
};

export default List;
