import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import constants from 'constant/constant';
import SearchInput from "components/SearchInput"
const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    marginRight: theme.spacing(1),
  },
  button: {
    marginRight: '8px',
    textTransform: 'capitalize',
    fontFamily: 'Calibri',
    fontSize: constants.fontSize.placholder,
  },
  dialogAction: {
    paddingRight: '25px',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 80,
  },
}));

const Toolbar = props => {
  const { className, handleChangeCategory, onSearchChange, handleRefresh, ...rest } = props;
  const classes = useStyles();
  const [type, setType] = useState('all');
  let handleChange = event => {
    let value = event.target.value;
    setType(value);
    handleChangeCategory(value);
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.row}>
        <Button
          color="default"
          variant="outlined"
          className={classes.button}
          onClick={handleRefresh}
          startIcon={<RefreshIcon />}
        >
          Refresh
        </Button>
        <FormControl
          variant="outlined"
          margin="dense"
          className={classes.formControl}
        >
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            onChange={handleChange}
            value={type}
            name="type"
            style={{ height: 43 }}
          >
            <MenuItem value="all" selected>
              All
            </MenuItem>
            <MenuItem value="KK">ខ្មែរ-ខ្មែរ</MenuItem>
            <MenuItem value="KE">ខ្មែរ-ENG</MenuItem>
            <MenuItem value="EK">ENG-ខ្មែរ</MenuItem>
          </Select>
        </FormControl>
        <span className={classes.spacer} />
        <SearchInput
          className={classes.searchInput}
          placeholder="Search"
          onChange={onSearchChange}
        />
      </div>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
  handleRefresh: PropTypes.func,
  handleChangeCategory: PropTypes.func,
  onSearchChange: PropTypes.func,
};

export default Toolbar;
