import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import App from './App';
import thunk from 'redux-thunk'
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from "redux";
import wordreducer from './views/User/reducers/wordreducer';

const store = createStore(wordreducer, applyMiddleware(thunk))
ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>  , 
document.getElementById('root'));
serviceWorker.unregister();
