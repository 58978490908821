import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { UsersToolbar, UsersTable } from './components';
import { getData } from '../../provider/data-loader';
import constant from '../../constant/constant';
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));

const UserList = props => {
  const classes = useStyles();
  const [comments, setComments] = useState([]);
  const [category, setCategory] = useState('all');
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(10);
  const [total, setTotal] = useState(0);

  function getEnquiryData() {
    const token = window.sessionStorage.getItem(constant.sessionKey);
    let query = `?token=${token}&page=${page + 1}&count=${count}`
    if (search.trim().length > 0) {
      query += "&search=" + search;
    }
    if (category !== 'all') {
      query += '&category=' +
        category;
    }
    let url = constant.endPoint.wordComment + query;
    getData(url).then(data => {
      if (data.code === 1) {
        setComments(data.data);
        setTotal(data.pagination.total_items);
      } else if (data.code === 0) {
        console.log('error clients');
      } else {
        console.log('error server');
      }
    });
  }

  useEffect(getEnquiryData, [search, page, count, category]);

  const handleRefresh = e => {
    getEnquiryData();
  };

  const handleChangeCategory = category => {
    setCategory(category);
  };

  const onSearchChange = e => {
    let search = e.target.value;
    setSearch(search);
  }

  const onChangePage = (event, page) => {
    setPage(page);
  }

  const onChangeRowsPerPage = (event) => {
    setCount(event.target.value);
  }

  return (
    <div className={classes.root}>
      <UsersToolbar
        handleRefresh={handleRefresh}
        handleChangeCategory={handleChangeCategory}
        onSearchChange={onSearchChange}

      />
      <div className={classes.content}>
        <UsersTable comments={comments}
          handleRefresh={handleRefresh}
          total={total}
          page={page}
          count={count}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
        />
      </div>
    </div>
  );
};

export default UserList;
