import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Button,
  Box,
  Grid,
  Typography,
} from '@material-ui/core';
import moment from 'moment';
import c, { getUser } from 'constant/constant';
import { deleteData, postData, putData } from 'provider/data-loader';
import Swal from 'sweetalert2';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 1050,
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: 'flex-end',
  },
  button: {
    margin: theme.spacing(0.5),
    textTransform: 'capitalize',
    fontFamily: 'calibri',
  },
  text: {
    fontFamily: 'Calibri',
    fontSize: c.fontSize.text,
  },
  tableContent: {
    fontSize: c.fontSize.texfield,
    fontFamily: 'Calibri',
  },
  enFont: {
    fontFamily: 'Calibri',
  },
  publish: {
    backgroundColor: c.color.main,
    '&:hover': {
      backgroundColor: c.color.darkMain,
    },
  },
  delete: {
    backgroundColor: c.color.red,
    '&:hover': {
      backgroundColor: c.color.darkRed,
    },
  },
  reject: {
    backgroundColor: c.color.yellow,
    '&:hover': {
      backgroundColor: c.color.darkYellow,
    },
  },
  view: {
    backgroundColor: c.color.green,
    '&:hover': {
      backgroundColor: c.color.darkgreen,
    },
  },
  dialogRow: {
    padding: '8px 0px 8px 0px',
  },
  action: {
    display: 'flex',
    flexFlow: 'row',
  },
}));

const WordRequestTable = props => {
  const { className, wordRequests, handleRefresh } = props;
  const classes = useStyles();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openApprovalModel, setOpenApprovalModel] = useState(false);
  const [deletedRequest, setDeletedRequest] = useState(null);
  const [existedWord, setExistedWord] = useState(null);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [wordView, setWordView] = useState(null);
  const user = JSON.parse(getUser());

  const handleClickOpenDeleteModal = (event, selectedItem) => {
    setOpenDeleteModal(true);
    setDeletedRequest(selectedItem);
  };

  const handleCloseApprovalModal = () => {
    setOpenApprovalModel(false);
  };

  const handleOpenViewModal = (event, word) => {
    setOpenViewModal(true);
    setWordView(word);
  };

  const handleCloseViewModal = () => {
    setOpenViewModal(false);
  };

  const deleteAfterApprove = (id) => {
    deleteData(
      c.endPoint.wordRequest +
        '/' + id +'?token=' +
        window.sessionStorage.getItem(c.sessionKey)
    )
      .then(response => {
        handleRefresh();
        if (response.code !== 1) {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            html:
              '<div style="font-family: Battambang-Regular; font-size: 14 "> <p>' +
               `You can not delete this word` +
              '</p></div>',
          });
        }
      }).catch(err => {
        Swal.fire({
          icon: 'error',
          title: 'Opps, something went wrong.',
          html:
            '<div style="font-family: Battambang-Regular; font-size: 14 "> <p>' +
            err +
            '</p></div>',
        });
      });
  }

  const onConfirmDelete = () => {
    setOpenDeleteModal(false);
    if (deletedRequest) {
      deleteData(
        c.endPoint.wordRequest +
          '/' +
          deletedRequest._id +
          '?token=' +
          window.sessionStorage.getItem(c.sessionKey)
      )
        .then(response => {
          if (response.code === 1) {
            Swal.fire({
              icon: 'success',
              title: 'លុបទិន្នន័យបានជោគជ័យ',
            });
            handleRefresh();
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Opps, something went wrong.',
              html:
                '<div style="font-family: Battambang-Regular; font-size: 14 "> <p>' +
                response.message +
                '</p></div>',
            });
          }
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            title: 'Opps, something went wrong.',
            html:
              '<div style="font-family: Battambang-Regular; font-size: 14 "> <p>' +
              err +
              '</p></div>',
          });
        });
    }
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  const onApproveClick = (event, word) => {
    var form_data = new FormData();
    form_data.append('word', word.word);
    form_data.append('definition', word.definition);
    form_data.append('type', 'KK');
    form_data.append('part_of_speech', word.part_of_speech);
    form_data.append('requester', word.requester);
    form_data.append('contact', word.contact);
    form_data.append('reference', word.ref);
    form_data.append('images', word.images);
    
    let token = window.sessionStorage.getItem(c.sessionKey);
    postData(
      c.endPoint.wordRequest + '/' + word._id + '/approved?token=' + token,
      form_data
    )
      .then(response => {
        if (response.code === 1) {
          let data = response.data;
          if (data.existed_word) {
            let obj = data;
            obj._id = word._id;
            setExistedWord(obj);
            setOpenApprovalModel(true);
          } else {
            Swal.fire({
              icon: 'success',
              title: 'Success Approval',
            });
            deleteAfterApprove(word._id);
            handleRefresh();
          }
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Opps, ',
            html:
              '<div style="font-family: Battambang-Regular; font-size: 14 "> <p>' +
              response.message +
              '</p></div>',
          });
        }
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          title: 'Opps, something went wrong.',
          html:
            '<div style="font-family: Battambang-Regular; font-size: 14 "> <p>' +
            err +
            '</p></div>',
        });
      });
  };

  const onRejectBtnClick = (event, word) => {
    let token = window.sessionStorage.getItem(c.sessionKey);
    putData(c.endPoint.wordRequest + '/' + word._id + '?token=' + token, {
      status: 'rejected',
    })
      .then(response => {
        if (response.code === 1) {
          Swal.fire({
            icon: 'success',
            title: 'បដិសេដពាក្យនេះបានជោគជ័យ',
          });
          handleRefresh();
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Opps, something went wrong.',
            html:
              '<div style="font-family: Battambang-Regular; font-size: 14 "> <p>' +
              response.message +
              '</p></div>',
          });
        }
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          title: 'Opps, something went wrong.',
          html:
            '<div style="font-family: Battambang-Regular; font-size: 14 "> <p>' +
            err +
            '</p></div>',
        });
      });
  };

  const onConfirmApprove = () => {
    let token = window.sessionStorage.getItem(c.sessionKey);
    postData(
      c.endPoint.wordRequest +
        '/' +
        existedWord._id +
        '/must-approved?token=' +
        token,
      {
        word_id: existedWord.existed_word,
        type: existedWord.type,
      }
    )
      .then(response => {
        setOpenApprovalModel(false);
        if (response.code === 1) {
          Swal.fire({
            icon: 'success',
            title: 'Approve Success',
          });
          deleteAfterApprove(existedWord._id);
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Opps, something went wrong.',
            html:
              '<div style="font-family: Battambang-Regular; font-size: 14 "> <p>' +
              response.message +
              '</p></div>',
          });
        }
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          title: 'Opps, something went wrong.',
          html:
            '<div style="font-family: Battambang-Regular; font-size: 14 "> <p>' +
            err +
            '</p></div>',
        });
      });
  };

  const getCategory = type => {
    if (type === 'KK') return 'ខ្មែរ-ខ្មែរ';
    if (type === 'KE') return 'ខ្មែរ-ENG';
    if (type === 'EK') return 'ENG-ខ្មែរ';
  };

  return (
    <div>
      <Card className={clsx(classes.root, className)}>
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow className={classes.text}>
                    <TableCell className={classes.text}>#</TableCell>
                    <TableCell className={classes.text}>Category</TableCell>
                    <TableCell className={classes.text}>Word</TableCell>
                    <TableCell className={classes.text}>POS</TableCell>
                    <TableCell className={classes.text}>Definition</TableCell>
                    <TableCell className={classes.text}>Image</TableCell>
                    <TableCell className={classes.text}>Requester</TableCell>
                    <TableCell className={classes.text}>Date</TableCell>
                    <TableCell className={classes.text}>Status</TableCell>
                    <TableCell className={classes.text}>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {wordRequests
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((wordRequest, index) => (
                      <TableRow
                        className={classes.tableRow}
                        hover
                        key={index}
                        // selected={selectedUsers.indexOf(user._id) !== -1}
                      >
                        <TableCell className={classes.tableContent}>
                          {(page * rowsPerPage) + (index + 1)}
                        </TableCell>
                        <TableCell className={classes.tableContent}>
                          {getCategory(wordRequest.type)}
                        </TableCell>
                        <TableCell className={classes.tableContent}>
                          {wordRequest.word}
                        </TableCell>
                        <TableCell className={classes.tableContent}>
                          {wordRequest.part_of_speech}
                        </TableCell>
                        <TableCell className={classes.tableContent}>
                          {wordRequest.definition}
                        </TableCell>
                        <TableCell>
                          {wordRequest.images.map((img, index) => {
                            return (
                              <div
                                key={index}
                                style={{ float: 'left', margin: 5 }}
                              >
                                <Box
                                  border={1}
                                  borderColor="#cfd8dc"
                                  style={{ padding: 5 }}
                                >
                                  <a
                                    href={c.endPoint.file + '/' + img}
                                    download
                                  >
                                    <img
                                      src={c.endPoint.file + '/' + img}
                                      alt={'image' + index}
                                      width="80"
                                      height="80"
                                    />
                                  </a>
                                </Box>
                              </div>
                            );
                          })}
                        </TableCell>
                        <TableCell
                          className={classes.tableContent}
                          style={{ fontFamily: 'Calibri' }}
                        >
                          {wordRequest.requester}
                        </TableCell>
                        <TableCell className={classes.tableContent}>
                          {moment(wordRequest.createdAt).format('DD/MM/YYYY')}
                        </TableCell>
                        <TableCell
                          className={`${classes.tableContent} ${classes.enFont}`}
                        >
                          {wordRequest.status.toUpperCase()}
                        </TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            size="small"
                            color="secondary"
                            className={`${classes.button} ${classes.view}`}
                            onClick={event =>
                              handleOpenViewModal(event, wordRequest)
                            }
                          >
                            View
                          </Button>
                          {(user.role === c.userRole.admin ||
                            user.role === c.userRole.editor) && (
                            <div>
                              {wordRequest.status === 'requested' ? (
                                <div>
                                  <Button
                                    variant="contained"
                                    size="small"
                                    color="secondary"
                                    className={`${classes.button} ${classes.publish}`}
                                    onClick={event =>
                                      onApproveClick(event, wordRequest)
                                    }
                                  >
                                    Approve
                                  </Button>
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    size="small"
                                    className={`${classes.button} ${classes.reject}`}
                                    onClick={event =>
                                      onRejectBtnClick(event, wordRequest)
                                    }
                                  >
                                    Reject
                                  </Button>
                                </div>
                              ) : (
                                ''
                              )}
                              <Button
                                variant="contained"
                                color="secondary"
                                size="small"
                                className={`${classes.button} ${classes.delete}`}
                                onClick={event =>
                                  handleClickOpenDeleteModal(event, wordRequest)
                                }
                              >
                                Delete
                              </Button>
                            </div>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={wordRequests.length}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            labelRowsPerPage="ចំនួនទិន្នន័យក្នុងមួយទំព័រ"
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </CardActions>
      </Card>
      <div>
        <Dialog
          open={openDeleteModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleCloseDeleteModal}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">បញ្ជាក់</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              តើអ្នកពិតជាចង់លុបសំណើរនៃពាក្យមែនឬទេ ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseDeleteModal}
              color="primary"
              variant="outlined"
            >
              បោះបង់
            </Button>
            <Button
              onClick={onConfirmDelete}
              variant="contained"
              color="secondary"
            >
              លុប
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <div>
        <Dialog
          open={openApprovalModel}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleCloseApprovalModal}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">បញ្ជាក់</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              ពាក្យមុននេះ មានរួចមកហើយ តើអ្នកពិតជាចង់កែប្រែលើទិន្នន័យចាស់មែនឬទេ ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseApprovalModal}
              color="primary"
              variant="outlined"
            >
              បោះបង់
            </Button>
            <Button
              onClick={onConfirmApprove}
              variant="contained"
              color="secondary"
            >
              Approve
            </Button>
          </DialogActions>
        </Dialog>
      </div>

      <div>
        {openViewModal && (
          <Dialog
            open={openViewModal}
            TransitionComponent={Transition}
            fullWidth={true}
            maxWidth={'sm'}
            onClose={handleCloseViewModal}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle id="alert-dialog-slide-title">
              <Typography className={classes.text}>View Detail</Typography>
            </DialogTitle>
            <DialogContent>
              <Grid container>
                <Grid item xs={4} className={classes.dialogRow}>
                  <p className={classes.text}>Word</p>
                </Grid>
                <Grid item xs={8} className={classes.dialogRow}>
                  <p className={classes.text}>{wordView.word}</p>
                </Grid>
                <Grid item xs={4} className={classes.dialogRow}>
                  <p className={classes.text}>Type</p>
                </Grid>
                <Grid item xs={8} className={classes.dialogRow}>
                  <p className={classes.text}>{getCategory(wordView.type)}</p>
                </Grid>
                <Grid item xs={4} className={classes.dialogRow}>
                  <p className={classes.text}>Part of Speech</p>
                </Grid>
                <Grid item xs={8} className={classes.dialogRow}>
                  <p className={classes.text}>{wordView.part_of_speech}</p>
                </Grid>
                <Grid item xs={4} className={classes.dialogRow}>
                  <p className={classes.text}>Definition</p>
                </Grid>
                <Grid item xs={8} className={classes.dialogRow}>
                  <p className={classes.text}>{wordView.definition}</p>
                </Grid>
                <Grid item xs={4} className={classes.dialogRow}>
                  <p className={classes.text}>Requester</p>
                </Grid>
                <Grid item xs={8} className={classes.dialogRow}>
                  <p className={classes.text}>{wordView.requester}</p>
                </Grid>
                <Grid item xs={4} className={classes.dialogRow}>
                  <p className={classes.text}>Contact</p>
                </Grid>
                <Grid item xs={8} className={classes.dialogRow}>
                  <p className={classes.text}>{wordView.contact}</p>
                </Grid>
                <Grid item xs={4} className={classes.dialogRow}>
                  <p className={classes.text}>Date</p>
                </Grid>
                <Grid item xs={8} className={classes.dialogRow}>
                  <p className={classes.text}>
                    {moment(wordView.createdAt).format('DD/MM/YYYY')}
                  </p>
                </Grid>
                <Grid item xs={4} className={classes.dialogRow}>
                  <p className={classes.text}>Status</p>
                </Grid>
                <Grid item xs={8} className={classes.dialogRow}>
                  <p className={classes.text}>{wordView.status}</p>
                </Grid>
                <Grid item xs={4} className={classes.dialogRow}>
                  <p className={classes.text}>Images</p>
                </Grid>
                <Grid item xs={8} className={classes.dialogRow}>
                  <div className={classes.text}>
                    {wordView.images.map((img, index) => {
                      return (
                        <div key={index} style={{ float: 'left', margin: 5 }}>
                          <Box
                            border={1}
                            borderColor="#cfd8dc"
                            style={{ padding: 5 }}
                          >
                            <a href={c.endPoint.file + '/' + img} download>
                              <img
                                src={c.endPoint.file + '/' + img}
                                alt={'image' + index}
                                width="80"
                                height="80"
                              />
                            </a>
                          </Box>
                        </div>
                      );
                    })}
                  </div>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleCloseViewModal}
                color="primary"
                variant="outlined"
                className={classes.button}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </div>
    </div>
  );
};

WordRequestTable.propTypes = {
  className: PropTypes.string,
  wordRequests: PropTypes.array.isRequired,
  handleRefresh: PropTypes.func,
};

export default WordRequestTable;
