import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import { RouteWithLayout, RouteWithLayoutUser } from './components';
import {
  Main as MainLayout,
  Minimal as MinimalLayout,
  MainLayoutUser,
} from './layouts';

import {
  Dashboard as DashboardView,
  KhKhWord as KhKhWordView,
  UserList as UserListView,
  Icons as IconsView,
  Settings as SettingsView,
  SignUp as SignUpView,
  SignIn as SignInView,
  NotFound as NotFoundView,
  Home as HomeView,
  Word as WordView,
  About as AboutView,
  Founder as FounderView,
  Enquiry as EnquiryView,
  Acknowledgement as AcknowledgementView,
  KhEnWord as KhEnWordView,
  EnKhWord as EnKhWordView,
  Enquiries as EnquiriesView,
  Version as VersionView,
  ImageRequest as ImageRequestView,
  Comment as CommentView,
  WordRequest as WordRequestView,
  ChangeRequest as ChangeRequestView,
  Slider as SlideView,
  KhKhWordList as KhKhWordListView,
  KhEnWordList as KhEnWordListView,
  EnKhWordList as EnKhWordListView,
  BookMarkWord as BookMarkWordView,
  PartOfSpeech as PartOfSpeechView,
  AllWordList,
  RequestChange as RequestChangeView,
  EnKhRequestChange as EnKhRequestChangeView,
  KhEnRequestChange as KhEnRequestChangeView,
  Abbrevation as AbbrevationView,
  Note as NoteView,
  Privacy as PrivacyView
} from './views';

const Routes = props => {
  return (
    <Switch>
      <RouteWithLayoutUser
        component={HomeView}
        exact
        layout={MainLayoutUser}
        path="/"
      />

      <RouteWithLayoutUser
        component={WordView}
        exact
        layout={MainLayoutUser}
        path="/words"
      />
      <RouteWithLayoutUser
        component={AboutView}
        exact
        layout={MainLayoutUser}
        path="/about"
      />
      <RouteWithLayoutUser
        component={FounderView}
        exact
        layout={MainLayoutUser}
        path="/founder"
      />
      <RouteWithLayoutUser
        component={AcknowledgementView}
        exact
        layout={MainLayoutUser}
        path="/acknowledgement"
      />
      <RouteWithLayoutUser
        component={EnquiryView}
        exact
        layout={MainLayoutUser}
        path="/enquiry"
      />
      <RouteWithLayoutUser
        component={BookMarkWordView}
        exact
        layout={MainLayoutUser}
        path="/bookmardword"
      />
      <RouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/dashboard"
      />
      <RouteWithLayout
        component={WordRequestView}
        exact
        layout={MainLayout}
        path="/wordrequets"
      />
      <RouteWithLayout
        component={UserListView}
        exact
        layout={MainLayout}
        path="/users"
      />
      <RouteWithLayout
        component={SlideView}
        exact
        layout={MainLayout}
        path="/slide"
      />
      <RouteWithLayout
        component={KhKhWordListView}
        exact
        layout={MainLayout}
        path="/kh-kh-words"
      />
      <RouteWithLayout
        component={KhEnWordListView}
        exact
        layout={MainLayout}
        path="/kh-en-words"
      />
      <RouteWithLayout
        component={EnKhWordListView}
        exact
        layout={MainLayout}
        path="/en-kh-words"
      />
      <RouteWithLayout
        component={KhKhWordView}
        exact
        layout={MainLayout}
        path="/kh-kh-word"
      />
      <RouteWithLayout
        component={EnKhWordView}
        exact
        layout={MainLayout}
        path="/en-kh-word"
      />
      <RouteWithLayout
        component={IconsView}
        exact
        layout={MainLayout}
        path="/icons"
      />
      <RouteWithLayout
        component={EnquiriesView}
        exact
        layout={MainLayout}
        path="/enquiries"
      />
      <RouteWithLayout
        component={VersionView}
        exact
        layout={MainLayout}
        path="/versions"
      />
      <RouteWithLayout
        component={KhEnWordView}
        exact
        layout={MainLayout}
        path="/kh-en-word"
      />
      <RouteWithLayout
        component={ImageRequestView}
        exact
        layout={MainLayout}
        path="/image-request"
      />
      <RouteWithLayout
        component={ChangeRequestView}
        exact
        layout={MainLayout}
        path="/change-request"
      />
      <RouteWithLayout
        component={CommentView}
        exact
        layout={MainLayout}
        path="/word-comment"
      />
      <RouteWithLayout
        component={SettingsView}
        exact
        layout={MainLayout}
        path="/settings"
      />
      <RouteWithLayout
        component={SignUpView}
        exact
        layout={MinimalLayout}
        path="/sign-up"
      />
      <RouteWithLayout
        component={SignInView}
        exact
        layout={MinimalLayout}
        path="/sign-in"
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <RouteWithLayout
        component={AllWordList}
        exact
        layout={MainLayout}
        path="/all_words"
      />
      <RouteWithLayout
        component={PartOfSpeechView}
        exact
        layout={MainLayout}
        path="/part_of_speeches"
      />
      <RouteWithLayoutUser
        component={RequestChangeView}
        exact
        layout={MainLayoutUser}
        path="/khkh_request_change"
      />
      <RouteWithLayoutUser
        component={KhEnRequestChangeView}
        exact
        layout={MainLayoutUser}
        path="/khen_request_change"
      />
      <RouteWithLayoutUser
        component={EnKhRequestChangeView}
        exact
        layout={MainLayoutUser}
        path="/enkh_request_change"
      />
      <RouteWithLayoutUser
        component={AbbrevationView}
        exact
        layout={MainLayoutUser}
        path="/abbrevation"
      />
      <RouteWithLayoutUser
        component={NoteView}
        exact
        layout={MainLayoutUser}
        path="/note"
      />
      <Route path="/privacy-policy" component={PrivacyView}/>

      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
