import {
  getData,
  postData,
  deleteData,
  patchData,
} from '../provider/data-loader';
import axios from 'axios';
import constant from '../constant/constant';
const token = window.sessionStorage.getItem(constant.sessionKey);

let headers = { 'Content-Type': 'application/json' }
export const userService = {
  getAllUsers: (search = "", page = 0, count = 10) => {
    let query = `?token=${token}&page=${page + 1}&count=${count}`;
    if (search.trim().length > 0) {
      query += "&search=" + search;
    }
    return getData(constant.endPoint.user + query);
  },
  createUser: user => {
    return postData(constant.endPoint.user + '?token=' + token, user);
  },
  updateUser: (userId, updateInfo) => {
    return patchData(
      constant.endPoint.user + '/' + userId + '?token=' + token,
      updateInfo
    );
  },
  deleteUser: userId => {
    return deleteData(
      constant.endPoint.user + '/' + userId + '?token=' + token
    );
  },
};
export const advertisementService = {
  getAllAds: query => {
    return getData(constant.endPoint.user + '?token=' + token);
  },
  createAds: user => {
    return postData(constant.endPoint.user + '?token=' + token, user);
  },
  updateAds: (userId, updateInfo) => {
    return patchData(
      constant.endPoint.user + '/' + userId + '?token=' + token,
      updateInfo
    );
  },
  deleteAds: userId => {
    return deleteData(
      constant.endPoint.user + '/' + userId + '?token=' + token
    );
  },
};

export const wordService = {
  getWordById: async (type, wordId) => {
    let url = ""
    switch (type) {
      case "EK":
        url = constant.endPoint.enKhWord;
        break;
        case "KE":
          url = constant.endPoint.khEnWord;
          break;
      default:
        url = constant.endPoint.khKhWord;
        break;
    }
    url = url +"/"+ wordId;
    console.log('GET: ', url);
    try {
      let res = await axios.get(url, { headers });
      if (res.data.code === 1){
        return res.data.data;
      }else{
        throw new Error(res.data.message); 
      }
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  },
  getWordByKeyword: async (type, keyword) => {
    console.log("KEYWORD:", keyword);
    let url = ""
    switch (type) {
      case "EK":
        url = constant.endPoint.enKhWord;
        break;
        case "KE":
          url = constant.endPoint.khEnWord;
          break;
      default:
        url = constant.endPoint.khKhWord;
        break;
    }
    url = url +"/search?keyword="+ keyword;
    console.log('GET: ', url);
    try {
      let res = await axios.get(url, { headers });
      if (res.data.code === 1){
        return res.data.data;
      }else{
        throw new Error(res.data.message); 
      }
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  },
  createAds: user => {
    return postData(constant.endPoint.user + '?token=' + token, user);
  },
  updateAds: (userId, updateInfo) => {
    return patchData(
      constant.endPoint.user + '/' + userId + '?token=' + token,
      updateInfo
    );
  },
  deleteAds: userId => {
    return deleteData(
      constant.endPoint.user + '/' + userId + '?token=' + token
    );
  },
};
