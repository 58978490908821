import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import c from 'constant/constant';
import clsx from 'clsx';
import {
    Card,
    CardHeader,
    Divider,
    Grid,
    MenuList,
    MenuItem
} from '@material-ui/core';
import SyncLoader from "react-spinners/SyncLoader";
import '../../../../index.css';
import KhKhRequestWord from '../khkhword';
import KhEnRequestWord from '../khenword';
import EnKhRequestWord from '../enkhword';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        margin: '20px 0px 20px 0px',
        '& .Mui-selected': {
            color: c.color.yellow,
        },
    },
    itemCenter: {
        textAlign: 'center'
    },
    menuItem: {
        justifyContent: 'space-between',
        padding: '16px 16px',
        fontSize: c.fontSize.texfield,
        color: c.color.main,
        fontWeight: 500,
    }
}));

const wordMenu = [
    createData(1, "ខ្មែរ-ខ្មែរ", true),
    createData(2, "ខ្មែរ-អង់គ្លេស", false),
    createData(3, "អង់គ្លេស-ខ្មែរ", false),
]

function createData(id, text, selected) {
    return { id, text, selected };
}

const KhKhWordTab = ({ props }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [menu, setMenu] = useState(wordMenu);
    const [menuId, setMenuId] = useState(1);

    const classes = useStyles();

    const handleClickMenuItem = (item, index) => {
        setIsLoading(false);
        setMenuId(item.id);
        let newArr = [...menu];
        for (let i = 0; i < newArr.length; i++) {
            if (i === index) {
                newArr[index].selected = true;
            } else {
                newArr[i].selected = false;
            }
        }
        setMenu(newArr);
    }

    return (
        <div className={clsx({
            [classes.root]: true,
        })}>
            {isLoading && (
                <div style={{ paddingTop: 200, textAlign: 'center', position: 'absolute', alignItems: 'center', width: '60%' }}>
                    <SyncLoader
                        size={15}
                        color={c.color.main}
                        loading={isLoading}
                    />
                </div>
            )}

            <Grid container spacing={3}>
                <Grid item xs={12} sm={3} lg={3} md={3} xl={3}>
                    <Card>
                        <CardHeader
                            className={classes.itemCenter}
                            title={
                                <p style={{
                                    color: c.color.main,
                                    fontFamily: c.fontFamily.Khmer_Battambong_Regular,
                                    fontSize: c.fontSize.text
                                }}
                                >
                                    ពាក្យថ្មី
                            </p>}
                        />
                        <Divider />
                        <MenuList style={{ width: '100%' }}>
                            {menu.map((item, index) => (
                                <MenuItem
                                    key={index}
                                    selected={item.selected}
                                    className={classes.menuItem}
                                    onClick={e => handleClickMenuItem(item, index)}
                                    style={{ borderBottom: index !== (menu.length - 1) ? '1px solid #00000029' : 0 }}
                                >
                                    {item.text}
                                </MenuItem>
                            ))
                            }
                        </MenuList>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={9} lg={9} md={9} xl={9}>
                    {
                        menuId === 1 ? <KhKhRequestWord />
                            : menuId === 2 ? <KhEnRequestWord />
                                : <EnKhRequestWord />
                    }
                </Grid>
            </Grid>
        </div>
    );
};

KhKhWordTab.propTypes = {
    className: PropTypes.string
};

export default KhKhWordTab;
