import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import c from 'constant/constant';
import { Divider, List, ListItemText, ListItem } from '@material-ui/core';
import SyncLoader from "react-spinners/SyncLoader";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getWordToSearch } from '../actions';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexGrow: 1,
    },
    title: {
        fontSize: c.fontSize.text,
        paddingTop: 20
    },
}));

const BookmarkWord = props => {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const [allWords, setAllWords] = useState([]);
    const { getWordToSearch } = props;

    useEffect(() => {
        setAllWords(getAllKhWords());
    }, []);

    const handleListWords = (targe, word) => {
        getWordToSearch(word);
        props.history.push('/', {
            type: word.type
        });
    }

    function getAllKhWords() {
        setIsLoading(true);
        var oldItems = JSON.parse(localStorage.getItem(c.localStorageKey.WORDS)) || [];
        setIsLoading(false)

        return oldItems;
    }

    return (
        <div className={clsx({
            [classes.root]: true,
        })}>
            {isLoading ? (
                <div style={{ paddingTop: 200, textAlign: 'center' }}>
                    <SyncLoader
                        size={15}
                        color={c.color.main}
                        loading={isLoading}
                    />
                </div>
            ) :
                (
                    <div style={{ width: '100%' }} >
                        <div className={classes.title}>ពាក្យរក្សាទុក</div>
                        <List>
                            {
                                allWords.map((word, wordIndex) => {
                                    return (
                                        <div key={wordIndex}>
                                            <ListItem button onClick={(e) => {
                                                handleListWords(e, word)
                                            }}>
                                                <ListItemText primary={word.word} />
                                            </ListItem>
                                            <Divider />
                                        </div>
                                    )
                                })
                            }
                        </List>
                    </div>
                )
            }
        </div>
    )
};

BookmarkWord.propTypes = {
    className: PropTypes.string
};

const mapStateToProps = (state) => {
    return state
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getWordToSearch
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(BookmarkWord);
