import React, { useState, useRef } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles, withStyles } from '@material-ui/styles';
import SaveIcon from '@material-ui/icons/Save';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Swal from 'sweetalert2';
import {
  Divider,
  Grid,
  TextField,
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Button,
  Typography,
  Box,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import constant, { getUser } from '../../../../constant/constant';
import { patchData, deleteData } from '../../../../provider/data-loader';
import CustomSelect from 'components/select/custom-select';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 1050,
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: 'flex-end',
  },
  button: {
    margin: theme.spacing(0.5),
    textTransform: 'capitalize',
    fontFamily: 'Calibri',
  },
  tableHeader: {
    fontSize: constant.fontSize.text,
    fontFamily: 'Calibri',
  },
  text: {
    fontSize: constant.fontSize.placholder,
    fontFamily: 'Calibri',
  },
  btn: {
    textTransform: 'capitalize',
  },
  delete: {
    backgroundColor: constant.color.red,
    '&:hover': {
      backgroundColor: constant.color.darkRed,
    },
  },
  edit: {
    backgroundColor: constant.color.yellow,
    '&:hover': {
      backgroundColor: constant.color.darkYellow,
    },
  },
  view: {
    backgroundColor: constant.color.green,
    '&:hover': {
      backgroundColor: constant.color.darkgreen,
    },
  },
  dialogRow: {
    padding: '8px 0px 8px 0px',
  },
  action: {
    display: 'flex',
    flexFlow: 'row',
  },
}));

const adsType = [
  { value: 'long', label: 'long' },
  { value: 'rectangle', label: 'rectangle' },
  { value: 'square', label: 'square' },
];

const GreenCheckbox = withStyles({
  root: {
    color: 'black',
    '&$checked': {
      color: constant.color.green,
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

const UsersTable = props => {
  const { className, slides, onItemClick, handleRefresh, ...rest } = props;
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deletedAds, setDeletedAds] = useState(null);
  const [imageLink, setImageLink] = useState({ preview: '', raw: '' });
  const [linkErrTxt, setLinkErrTxt] = useState('');
  const [imgErrTxt, setImgErrTxt] = useState('');
  const [openViewModal, setOpenViewModal] = useState(false);
  const [forMobile, setForMobile] = useState(0);
  const [forWeb, setForWeb] = useState(0);
  const [slidView, setSlideView] = useState(null);
  const [adsObj, setAdsObj] = useState({
    link: '',
    image: '',
  });
  const user = JSON.parse(getUser());
  let fileRef = useRef();

  const [selectedAdsType, setSelectedAdsType] = useState({
    value: 'long',
    label: 'long',
  });

  const onOpenAddUserModal = (event, u) => {
    console.log('called onOpenAddUserModal', u);
    setSelectedAdsType({
      value: u.type,
      label: u.type,
    });
    setAdsObj(u);
    setForMobile(u.for_mobile);
    setForWeb(u.for_web);
    setOpenModal(true);
  };

  const handleChangeValue = option => {
    setSelectedAdsType({
      value: option.value,
      label: option.label,
    });
  };

  const onCloseAddUserModal = () => {
    console.log('called onCloseAddUserModal');
    setOpenModal(false);
  };

  const handleChange = event => {
    setAdsObj({
      ...adsObj,
      [event.target.name]: event.target.value,
    });
  };

  const handleOpenViewModal = (event, item) => {
    setOpenViewModal(true);
    setSlideView(item);
    setForMobile(item.for_mobile);
    setForWeb(item.for_web);
  };

  const handleCloseViewModal = () => {
    setOpenViewModal(false);
  };

  const handleClickOpenDeleteModal = (event, deleteItem) => {
    setOpenDeleteModal(true);
    setDeletedAds(deleteItem);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const uploadMultipleFiles = e => {
    setImageLink({
      preview: URL.createObjectURL(e.target.files[0]),
      raw: e.target.files[0],
    });
    setImgErrTxt('');
  };

  function handleValidatFormData() {
    let formIsValid = true;
    if (adsObj.link === '') {
      formIsValid = false;
      setLinkErrTxt('Please input your link');
    }
    return formIsValid;
  }

  const handleCheckWeb = event => {
    if (event.target.checked === true) {
      setForWeb(1);
    } else {
      setForWeb(0);
    }
  };

  const handleCheckMobile = event => {
    if (event.target.checked === true) {
      setForMobile(1);
    } else {
      setForMobile(0);
    }
  };

  const handleOnUpdate = () => {
    const token = window.sessionStorage.getItem(constant.sessionKey);
    if (handleValidatFormData()) {
      var form_data = new FormData();
      if (imageLink.raw !== '') {
        form_data.append('image', imageLink.raw);
      }
      if (adsObj.link === '') {
        setLinkErrTxt('Please input your link');
      }

      console.log('forMobile', forMobile);
      console.log('forweb', forWeb);
      form_data.append('link', adsObj.link);
      form_data.append('type', selectedAdsType.value);
      form_data.append('for_mobile', forMobile);
      form_data.append('for_web', forWeb);

      patchData(
        constant.endPoint.advertisements + '/' + adsObj._id + '?token=' + token,
        form_data
      )
        .then(response => {
          console.log('Value', response);
          if (response.code === 1) {
            setOpenModal(false);
            Swal.fire({
              icon: 'success',
              title: 'កែប្រែជោគជ័យ',
            });
            handleRefresh();
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Opps, something went wrong.',
              html:
                '<div style="font-family: Battambang-Regular; font-size: 14 "> <p>' +
                response.message +
                '</p></div>',
            });
          }
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            title: 'Opps, something went wrong.',
            html:
              '<div style="font-family: Battambang-Regular; font-size: 14 "> <p>' +
              err +
              '</p></div>',
          });
        });
    }
  };

  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  const onConfirmDelete = () => {
    setOpenDeleteModal(false);
    if (deletedAds) {
      deleteData(
        constant.endPoint.advertisements +
          '/' +
          deletedAds._id +
          '?token=' +
          window.sessionStorage.getItem(constant.sessionKey)
      )
        .then(response => {
          if (response.code === 1) {
            Swal.fire({
              icon: 'success',
              title: 'លុបទិន្នន័យបានជោគជ័យ',
            });
            handleRefresh();
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Opps, something went wrong.',
              html:
                '<div style="font-family: Battambang-Regular; font-size: 14 "> <p>' +
                response.message +
                '</p></div>',
            });
          }
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            title: 'Opps, something went wrong.',
            html:
              '<div style="font-family: Battambang-Regular; font-size: 14 "> <p>' +
              err +
              '</p></div>',
          });
        });
    }
  };

  return (
    <div>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableHeader}>No</TableCell>
                    <TableCell className={classes.tableHeader}>Link</TableCell>
                    <TableCell className={classes.tableHeader}>Image</TableCell>
                    <TableCell className={classes.tableHeader}>Type</TableCell>
                    <TableCell className={classes.tableHeader}>
                      Created Date
                    </TableCell>
                    <TableCell className={classes.tableHeader}>
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {slides.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((slide, index) => (
                    <TableRow
                      className={classes.tableRow}
                      hover
                      key={slide._id}
                    >
                      <TableCell className={classes.text}>
                        {index + 1}
                      </TableCell>
                      <TableCell className={classes.text}>
                        {slide.link}
                      </TableCell>
                      <TableCell>
                        <img
                          src={constant.endPoint.file + '/' + slide.image}
                          alt={'image' + index}
                          width="200"
                        />
                      </TableCell>
                      <TableCell className={classes.text}>
                        {slide.type}
                      </TableCell>
                      <TableCell className={classes.text}>
                        {moment(slide.createdAt).format('DD/MM/YYYY')}
                      </TableCell>
                      <TableCell className={classes.action}>
                        <Button
                          variant="contained"
                          size="small"
                          color="secondary"
                          className={`${classes.button} ${classes.view}`}
                          onClick={event => handleOpenViewModal(event, slide)}
                        >
                          View
                        </Button>
                        {(user.role === constant.userRole.admin ||
                          user.role === constant.userRole.editor) && (
                          <div>
                            <Button
                              variant="contained"
                              color="secondary"
                              size="small"
                              className={`${classes.button} ${classes.edit}`}
                              onClick={event =>
                                onOpenAddUserModal(event, slide)
                              }
                              startIcon={<EditIcon />}
                            >
                              Edit
                            </Button>
                            <Button
                              variant="contained"
                              color="secondary"
                              size="small"
                              onClick={event =>
                                handleClickOpenDeleteModal(event, slide)
                              }
                              className={`${classes.button} ${classes.delete}`}
                              startIcon={<DeleteIcon />}
                            >
                              Delete
                            </Button>
                          </div>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={slides.length}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            labelRowsPerPage="ចំនួនទិន្នន័យក្នុងមួយទំព័រ"
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </CardActions>
      </Card>
      <div>
        <Dialog
          open={openModal}
          onClose={onCloseAddUserModal}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Edit Advertisement</DialogTitle>
          <Divider />
          <DialogContent>
            <Grid container spacing={1}>
              <Grid
                item
                md={3}
                xs={3}
                sm={3}
                lg={3}
                xl={3}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <p className={classes.text}>Link</p>
              </Grid>
              <Grid item md={9} xs={9} sm={9} lg={9} xl={9}>
                <TextField
                  error={adsObj.link === '' ? true : false}
                  helperText={adsObj.link === '' ? linkErrTxt : null}
                  fullWidth
                  label="link"
                  name="link"
                  onChange={handleChange}
                  value={adsObj.link}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={3}
                xs={3}
                sm={3}
                lg={3}
                xl={3}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <p className={classes.text}>type</p>
              </Grid>
              <Grid item md={9} xs={9} sm={9} lg={9} xl={9}>
                <div style={{ float: 'left', width: '100%' }}>
                  <CustomSelect
                    options={adsType}
                    selectedOption={selectedAdsType}
                    handleChangeValue={handleChangeValue}
                  />
                </div>
              </Grid>
              <Grid
                item
                md={3}
                xs={3}
                sm={3}
                lg={3}
                xl={3}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <p className={classes.text}>Option</p>
              </Grid>
              <Grid item md={9} xs={9} sm={9} lg={9} xl={9}>
                <FormControlLabel
                  control={
                    <GreenCheckbox
                      checked={forMobile === 1 ? true : false}
                      onChange={event => handleCheckMobile(event)}
                      name="forMobile"
                    />
                  }
                  label={<p className={classes.text}>For Mobile</p>}
                />
                <FormControlLabel
                  control={
                    <GreenCheckbox
                      checked={forWeb === 1 ? true : false}
                      onChange={event => handleCheckWeb(event)}
                      name="forWeb"
                    />
                  }
                  label={<p className={classes.text}>For Web</p>}
                />
              </Grid>
              <Grid item md={3} xs={3} sm={3} lg={3} xl={3}>
                <p className={classes.text}>Image</p>
              </Grid>
              <Grid
                item
                md={9}
                xs={9}
                sm={9}
                lg={9}
                xl={9}
                style={{ display: 'flex', flexFlow: 'column' }}
              >
                <Button
                  color="primary"
                  variant="outlined"
                  style={{
                    fontFamily: constant.fontFamily.Khmer_Battambong_Regular,
                    textTransform: 'capitalize',
                  }}
                  onClick={() => fileRef.current.click()}
                >
                  Choose FIle
                </Button>
                <input
                  ref={fileRef}
                  type="file"
                  style={{ display: 'none' }}
                  onChange={uploadMultipleFiles}
                />
                <div style={{ marginLeft: 16, fontSize: 12, color: 'red' }}>
                  {adsObj.image === '' ? imgErrTxt : ''}
                </div>

                {imageLink.preview !== '' ? (
                  <div>
                    <div style={{ marginTop: 10 }}>
                      <Box
                        border={1}
                        borderColor="#cfd8dc"
                        style={{ padding: 5 }}
                      >
                        <img
                          src={imageLink.preview}
                          style={{ width: 200 }}
                          alt={imageLink.preview}
                        />
                      </Box>
                    </div>
                  </div>
                ) : (
                  <img
                    src={constant.endPoint.file + '/' + adsObj.image}
                    alt={adsObj.link}
                    width="80"
                    height="80"
                    style={{ marginTop: 10 }}
                  />
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className={classes.dialogAction}>
            <Button
              onClick={onCloseAddUserModal}
              className={`${classes.btn} ${classes.text}`}
              color="default"
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              onClick={handleOnUpdate}
              className={`${classes.btn} ${classes.text}`}
              color="primary"
              variant="contained"
              startIcon={<SaveIcon />}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <div>
        <Dialog
          open={openDeleteModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleCloseDeleteModal}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            <Typography className={classes.text}>Confirm</Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-slide-description"
              className={classes.text}
            >
              Are you sure to delete this Advertisement?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseDeleteModal}
              color="primary"
              variant="outlined"
              className={`${classes.btn} ${classes.text}`}
            >
              Cancel
            </Button>
            <Button
              onClick={onConfirmDelete}
              variant="contained"
              color="secondary"
              className={`${classes.btn} ${classes.text}`}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <div>
        {openViewModal && (
          <Dialog
            open={openViewModal}
            TransitionComponent={Transition}
            fullWidth={true}
            maxWidth={'sm'}
            onClose={handleCloseViewModal}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle id="alert-dialog-slide-title">
              <Typography className={classes.text}>View Detail</Typography>
            </DialogTitle>
            <DialogContent>
              <Grid container>
                <Grid item xs={4} className={classes.dialogRow}>
                  <p className={classes.text}>Link</p>
                </Grid>
                <Grid item xs={8} className={classes.dialogRow}>
                  <p className={classes.text}>{slidView.link}</p>
                </Grid>
                <Grid item xs={4} className={classes.dialogRow}>
                  <p className={classes.text}>Type</p>
                </Grid>
                <Grid item xs={8} className={classes.dialogRow}>
                  <p className={classes.text}>{slidView.type}</p>
                </Grid>
                <Grid item xs={4} className={classes.dialogRow}>
                  <p className={classes.text}>Option</p>
                </Grid>
                <Grid item xs={8} className={classes.dialogRow}>
                  <FormControlLabel
                    control={
                      <GreenCheckbox
                        checked={forMobile === 1 ? true : false}
                        name="forMobile"
                      />
                    }
                    label={<p className={classes.text}>For Mobile</p>}
                  />
                  <FormControlLabel
                    control={
                      <GreenCheckbox
                        checked={forWeb === 1 ? true : false}
                        name="forWeb"
                      />
                    }
                    label={<p className={classes.text}>For Web</p>}
                  />
                </Grid>
                <Grid item xs={4} className={classes.dialogRow}>
                  <p className={classes.text}>Date</p>
                </Grid>
                <Grid item xs={8} className={classes.dialogRow}>
                  <p className={classes.text}>
                    {moment(slidView.createdAt).format('DD/MM/YYYY')}
                  </p>
                </Grid>
                <Grid item xs={4} className={classes.dialogRow}>
                  <p className={classes.text}>Images</p>
                </Grid>
                <Grid item xs={8} className={classes.dialogRow}>
                  <a
                    href={constant.endPoint.file + '/' + slidView.image}
                    download
                  >
                    <img
                      src={constant.endPoint.file + '/' + slidView.image}
                      alt="slide"
                      width="100%"
                    />
                  </a>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleCloseViewModal}
                color="primary"
                variant="outlined"
                className={classes.button}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </div>
    </div>
  );
};

UsersTable.propTypes = {
  className: PropTypes.string,
  slides: PropTypes.array.isRequired,
  onConfirmDelete: PropTypes.func,
  handleRefresh: PropTypes.func,
};

export default UsersTable;
