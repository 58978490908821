import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { Grid, createMuiTheme } from '@material-ui/core';
import c from 'constant/constant';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { getData } from 'provider/data-loader';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import constants from 'constant/constant';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexFlow: 'column',
        height: '100%',
        overflow: 'none',
    },
    content: {
        flex: '1 1 auto',
        [theme.breakpoints.down("xs")]: {
            display: 'flex',
            flex: '1 1 auto',
        },
        backgroundColor: 'white',
    },
    adsBottom: {
        bottom: 0,
        display: 'flex',
        alignItems: 'flex-end',
    },
    contentData: {
        flex: '1 1 auto',
        display: 'flex',
        flexFlow: 'column',
        paddingLeft: '3%',
        paddingRight: '3%',
        backgroundColor: constants.color.bgContent
    },

    fixedContent: {
        height: '100%',
        top: 0,
        position: "sticky",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    },
    slide: {
        marginTop: 10,
        [theme.breakpoints.up("lg")]: {
            width: '200px',
        },
        [theme.breakpoints.only("md")]: {
            width: '180px',
        },
        [theme.breakpoints.only("sm")]: {
            width: '100px',
        }
    },
    rectangle: {
        padding: 20
    }
}));

const MainLayoutUser = props => {
    const { children } = props;
    const classes = useStyles();
    const theme = createMuiTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('xs'));
    const [rectangleSlides, setRectangleSlides] = useState([]);
    const [squareSlides, setSquareSlides] = useState([]);
    const [longSlides, setLongSlides] = useState([]);
    const path = children.props.location.pathname;

    useEffect(() => {
        getData(c.endPoint.webAdvertisements).then(data => {
            if (data.code === 1) {
                setRectangleSlides(data.data.filter(test => test.type === 'rectangle'));
                setSquareSlides(data.data.filter(test => test.type === 'square'));
                setLongSlides(data.data.filter(test => test.type === 'long'));
            } else if (data.code === 0) {
                console.log('error clients');
            } else {
                console.log('error server');
            }
        });
    }, [])

    return (
        <div
            className={clsx({
                [classes.root]: true,
            })}
        >
            <Header {...props} />
            <main {...props} className={classes.content}>
                <Grid container>
                    {
                        !isXs &&
                        <Grid item xs={2} sm={2} md={2} lg={2} className={classes.fixedContent}>
                            {/* <div className={classes.rectangle}> */}
                                <Slider
                                    autoplay={true}
                                    autoplaySpeed={10000}
                                    arrows={false}
                                    className={classes.slide}
                                >
                                    {
                                        rectangleSlides.reverse().map((slide, index) =>
                                            <div key={index}>
                                                <a href={slide.link}
                                                    target="_blank"
                                                    rel="noopener noreferrer">
                                                    <img src={c.endPoint.file + '/' + slide.image} width="100%" alt={slide.tlin} />
                                                </a>
                                            </div>
                                        )
                                    }
                                </Slider>
                            {/* </div> */}

                            {/* <div className={classes.square}> */}
                                <Slider
                                    autoplay={true}
                                    arrows={false}
                                    autoplaySpeed={10000}
                                    className={classes.slide}>
                                    {
                                        squareSlides.reverse().map((slide, index) =>
                                            <div key={index}>
                                                <a href={slide.link}
                                                    target="_blank"
                                                    rel="noopener noreferrer">
                                                    <img src={c.endPoint.file + '/' + slide.image} width="100%" alt={slide.tlin} />
                                                </a>
                                            </div>
                                        )
                                    }
                                </Slider>
                            {/* </div> */}
                        </Grid>
                    }

                    <Grid item xs={isXs ? 12 : 8} sm={isXs ? 12 : 8} md={isXs ? 12 : 8} lg={isXs ? 12 : 8} className={classes.contentData}>
                        <div style={{ marginBottom: path === "/" ? isXs ? '100px' : '190px' : '0px', flex: '1 1 auto' }}>
                            {children}
                        </div>
                        {path !== "/" &&
                            <Grid container className={classes.adsBottom}>
                                <Grid item xs={6} sm={6} md={6} lg={6}>
                                    <div style={{ paddingTop: 20 }}>
                                        <Slider
                                            autoplay={true}
                                            autoplaySpeed={10000}
                                            arrows={false}>
                                            {
                                                longSlides.map((slide, index) =>
                                                    <div key={index}>
                                                        <a href={slide.link}
                                                            target="_blank"
                                                            rel="noopener noreferrer">
                                                            <img src={c.endPoint.file + '/' + slide.image} width="100%" alt={slide.tlin} />
                                                        </a>
                                                    </div>
                                                )
                                            }
                                        </Slider>
                                    </div>
                                </Grid >
                                <Grid item xs={6} sm={6} md={6} lg={6} >
                                    <div style={{ paddingTop: 20, paddingLeft: 10 }}>
                                        <Slider
                                            autoplay={true}
                                            arrows={false}
                                            autoplaySpeed={10000}
                                        >
                                            {
                                                longSlides.reverse().map((slide, index) =>
                                                    <div key={index}>
                                                        <a href={slide.link}
                                                            target="_blank"
                                                            rel="noopener noreferrer">
                                                            <img src={c.endPoint.file + '/' + slide.image} width="100%" alt={slide.tlin} />
                                                        </a>
                                                    </div>
                                                )
                                            }
                                        </Slider>
                                    </div>
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                    {
                        !isXs &&
                        <Grid item xs={2} sm={2} md={2} lg={2} className={classes.fixedContent}>
                            {/* <div className={classes.rectangle}> */}
                                <Slider
                                    autoplay={true}
                                    arrows={false}
                                    autoplaySpeed={10000}
                                    className={classes.slide}>
                                    {
                                        rectangleSlides.reverse().map((slide, index) =>
                                            <div key={index}>
                                                <a href={slide.link}
                                                    target="_blank"
                                                    rel="noopener noreferrer">
                                                    <img src={c.endPoint.file + '/' + slide.image} width="100%" alt={slide.tlin} />
                                                </a>
                                            </div>
                                        )
                                    }
                                </Slider>
                            {/* </div> */}

                            {/* <div className={classes.square}> */}
                                <Slider
                                    autoplay={true}
                                    arrows={false}
                                    autoplaySpeed={10000}
                                    className={classes.slide}>
                                    {
                                        squareSlides.reverse().map((slide, index) =>
                                            <div key={index}>
                                                <a href={slide.link}
                                                    target="_blank"
                                                    rel="noopener noreferrer">
                                                    <img src={c.endPoint.file + '/' + slide.image} width="100%" alt={slide.tlin} />
                                                </a>
                                            </div>
                                        )
                                    }
                                </Slider>
                            {/* </div> */}
                        </Grid>
                    }
                </Grid>
            </main>
            <Footer {...props} slides={longSlides} />
        </div>
    );
};

MainLayoutUser.propTypes = {
    children: PropTypes.node,
    onStarUpdate: PropTypes.func
};

export default MainLayoutUser;
