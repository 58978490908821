import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import c from 'constant/constant';
import SearchInput from "components/SearchInput"
const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    marginRight: theme.spacing(1),
  },
  button: {
    marginRight: '8px',
    textTransform: 'capitalize',
    fontFamily: 'Calibri',
    fontSize: c.fontSize.placholder
  },
  dialogAction: {
    paddingRight: '25px',
  },
}));

const Toolbar = props => {
  const { className, onSearchChange, handleRefresh, ...rest } = props;

  const classes = useStyles();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.row}>
        <Button
          color="default"
          variant="outlined"
          className={classes.button}
          onClick={handleRefresh}
          startIcon={<RefreshIcon />}
        >
          Refresh
        </Button>
        <span className={classes.spacer} />
        <SearchInput
          className={classes.searchInput}
          placeholder="Search"
          onChange={onSearchChange}
        />
      </div>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
  handleRefresh: PropTypes.func,
  onSearchChange: PropTypes.func,
};

export default Toolbar;
