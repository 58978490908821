import React from "react";

const Privacy = (props) => {
  return (
    <div style={{padding: '16px'}}>
      <h2>Privacy Policy</h2>
    </div>
  );
};

export default Privacy;
