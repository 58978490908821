import React, { useState } from "react";
import { makeStyles, Typography, } from "@material-ui/core";
import c from "../../constant/constant";
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons'

const useStyles = makeStyles((theme) => ({
    text: {
        color: c.color.text,
        fontSize: c.fontSize.texfield,
        fontWeight: 500,
    },
    rowCenter: {
        display: 'flex',
        flexFlow: 'row',
        alignItems: 'center',
        padding: 10
    },
}));

const CustomSelect = props => {
    const classes = useStyles();
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const {
        options,
        height,
        handleChangeValue,
        selectedOption
    } = props;

    const style = {
        control: (base, state) => ({
            ...base,
            lineHeight: 1.1,
            height: height ? height : 50,
        })
    };

    const DropdownIndicator = props => {
        return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    <FontAwesomeIcon icon={menuIsOpen ? faAngleUp : faAngleDown} />
                </components.DropdownIndicator>
            )
        );
    };

    const { Option } = components;
    const IconOption = (props) => (
        <Option {...props}>
            <div className={classes.rowCenter}>
                <Typography>{props.data.label}</Typography>
            </div>
        </Option>
    );

    return (
        <Select
            value={{
                label: <div className={classes.rowCenter}>
                    <Typography className={classes.text}>{selectedOption.label}</Typography>
                </div>,
                value: selectedOption.value
            }}
            onChange={e => handleChangeValue(e)}
            onMenuOpen={() => setMenuIsOpen(true)}
            onMenuClose={() => setMenuIsOpen(false)}
            autoFocus={false}
            options={options}
            hideSelectedOptions={true}
            components={{ Option: IconOption, DropdownIndicator, IndicatorSeparator: () => null }}
            styles={style}
        />
    );
};

CustomSelect.propTypes = {
    className: PropTypes.string,
    height: PropTypes.string,
    options: PropTypes.array,
    handleChangeValue: PropTypes.func,
    selectedOption: PropTypes.object
};

export default CustomSelect;
