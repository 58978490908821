import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import SaveIcon from '@material-ui/icons/Save';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Swal from 'sweetalert2';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {
  Divider,
  Grid,
  TextField,
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  Button,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import constant from '../../../../constant/constant';
import { userService } from 'services/user';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 1050,
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: 'flex-end',
  },
  button: {
    margin: theme.spacing(0.5),
    textTransform: 'capitalize',
    fontFamily: 'Calibri',
  },
  text: {
    fontFamily: 'Calibri',
    fontSize: constant.fontSize.text,
  },
  tableContent: {
    fontSize: constant.fontSize.placholder,
    fontFamily: 'calibri',
  },
  delete: {
    backgroundColor: constant.color.red,
    '&:hover': {
      backgroundColor: constant.color.darkRed,
    },
  },
  edit: {
    backgroundColor: constant.color.yellow,
    '&:hover': {
      backgroundColor: constant.color.darkYellow,
    },
  },
  view: {
    backgroundColor: constant.color.green,
    '&:hover': {
      backgroundColor: constant.color.darkgreen,
    },
  },
  dialogRow: {
    padding: '8px 0px 8px 0px',
  },
}));

const UsersTable = props => {
  const { className, users, handleRefresh, onRowsPerPageChange, onPageChange, page, total, rowsPerPage, ...rest } = props;
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deletedUser, setDeletedUser] = useState(null);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [userView, setUserView] = useState(null);
  const [user, setUser] = React.useState({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    username: '',
    role: '',
  });
  const onOpenAddUserModal = (event, u) => {
    console.log('called onOpenAddUserModal');
    u.password = '';
    setUser(u);
    setOpenModal(true);
    console.log('User: ', user);
  };

  const onCloseAddUserModal = () => {
    console.log('called onCloseAddUserModal');
    setOpenModal(false);
  };

  const handleChange = event => {
    setUser({
      ...user,
      [event.target.name]: event.target.value,
    });
  };

  const handleOpenViewModal = (event, user) => {
    setOpenViewModal(true);
    setUserView(user);
  };

  const handleCloseViewModal = () => {
    setOpenViewModal(false);
  };

  const handleClickOpenDeleteModal = (event, u) => {
    setOpenDeleteModal(true);
    setDeletedUser(u);
  };

  const onConfirmDelete = () => {
    setOpenDeleteModal(false);
    if (deletedUser) {
      userService
        .deleteUser(deletedUser._id)
        .then(response => {
          if (response.code === 1) {
            Swal.fire({
              icon: 'success',
              title: 'លុបទិន្នន័យបានជោគជ័យ',
            });
            handleRefresh();
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Opps, something went wrong.',
              html:
                '<div style="font-family: Battambang-Regular; font-size: 14 "> <p>' +
                response.message +
                '</p></div>',
            });
          }
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            title: 'Opps, something went wrong.',
            html:
              '<div style="font-family: Battambang-Regular; font-size: 14 "> <p>' +
              err +
              '</p></div>',
          });
        });
    }
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handleOnUpdate = () => {
    if (!user.password) {
      user.password = undefined;
    }
    userService
      .updateUser(user._id, user)
      .then(response => {
        setOpenModal(false);
        if (response.code === 1) {
          Swal.fire({
            icon: 'success',
            title: 'UPDATE SUCCESS',
          });
          handleRefresh();
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Opps, something went wrong.',
            html:
              '<div style="font-family: Battambang-Regular; font-size: 14 "> <p>' +
              response.message +
              '</p></div>',
          });
        }
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          title: 'Opps, something went wrong.',
          html:
            '<div style="font-family: Battambang-Regular; font-size: 14 "> <p>' +
            err +
            '</p></div>',
        });
      });
  };

  const classes = useStyles();

  return (
    <div>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.text}>N.</TableCell>
                    <TableCell className={classes.text}>Username</TableCell>
                    <TableCell className={classes.text}>First name</TableCell>
                    <TableCell className={classes.text}>Last name</TableCell>
                    <TableCell className={classes.text}>Email</TableCell>
                    <TableCell className={classes.text}>Role</TableCell>
                    <TableCell className={classes.text}>Created Date</TableCell>
                    <TableCell className={classes.text}>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users.map((user, index) => (
                    <TableRow className={classes.tableRow} hover key={user._id}>
                      <TableCell className={classes.tableContent}>
                        {(page * rowsPerPage) + (index + 1)}
                      </TableCell>
                      <TableCell className={classes.tableContent}>
                        {user.username}
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="body1"
                          className={classes.tableContent}
                        >
                          {user.first_name}
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.tableContent}>
                        <Typography
                          variant="body1"
                          className={classes.tableContent}
                        >
                          {user.last_name}
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.tableContent}>
                        {user.email}
                      </TableCell>
                      <TableCell className={classes.tableContent}>
                        {user.role}
                      </TableCell>
                      <TableCell className={classes.tableContent}>
                        {moment(user.createdAt).format('DD/MM/YYYY')}
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          size="small"
                          color="secondary"
                          className={`${classes.button} ${classes.view}`}
                          onClick={event => handleOpenViewModal(event, user)}
                        >
                          View
                        </Button>
                        <Button
                          variant="contained"
                          color="secondary"
                          size="small"
                          className={`${classes.button} ${classes.edit}`}
                          onClick={event => onOpenAddUserModal(event, user)}
                          startIcon={<EditIcon />}
                        >
                          Edit
                        </Button>
                        <Button
                          variant="contained"
                          color="secondary"
                          size="small"
                          onClick={event =>
                            handleClickOpenDeleteModal(event, user)
                          }
                          className={`${classes.button} ${classes.delete}`}
                          startIcon={<DeleteIcon />}
                        >
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={total}
            onChangePage={onPageChange}
            onChangeRowsPerPage={onRowsPerPageChange}
            labelRowsPerPage="ចំនួនទិន្នន័យក្នុងមួយទំព័រ"
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[10, 25, 50]}
          />
        </CardActions>
      </Card>
      <div>
        <Dialog
          open={openModal}
          onClose={onCloseAddUserModal}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            កែប្រែពត៍មានអ្នកប្រើប្រាស់
          </DialogTitle>
          <Divider />
          <DialogContent>
            <Grid container spacing={1}>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="គោត្តនាម"
                  margin="dense"
                  name="first_name"
                  onChange={handleChange}
                  required
                  value={user.first_name}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="នាម"
                  margin="dense"
                  name="last_name"
                  onChange={handleChange}
                  required
                  value={user.last_name}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="សារអេឡិចត្រូនិច"
                  margin="dense"
                  name="email"
                  onChange={handleChange}
                  required
                  value={user.email}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Username"
                  margin="dense"
                  name="username"
                  onChange={handleChange}
                  required
                  value={user.username}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  className={classes.formControl}
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                    Role
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    onChange={handleChange}
                    value={user.role}
                    label="Role"
                    name="role"
                  >
                    <MenuItem value="admin">Admin</MenuItem>
                    <MenuItem value="editor">Editor</MenuItem>
                    <MenuItem value="contributor">Contributor</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="ពាក្យសម្ងាត់ថ្មី"
                  margin="dense"
                  required
                  name="password"
                  onChange={handleChange}
                  type="text"
                  value={user.password}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className={classes.dialogAction}>
            <Button
              onClick={onCloseAddUserModal}
              color="default"
              variant="outlined"
            >
              បោះបង់
            </Button>
            <Button
              onClick={handleOnUpdate}
              color="primary"
              variant="contained"
              startIcon={<SaveIcon />}
            >
              កែប្រែ
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <div>
        <Dialog
          open={openDeleteModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleCloseDeleteModal}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">បញ្ជាក់</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              តើអ្នកពិតជាចង់លុបអ្នកប្រើប្រាស់
              {deletedUser
                ? ' ' +
                deletedUser.first_name +
                ' ' +
                deletedUser.last_name +
                ' '
                : ''}
              នេះមែនឬទេ ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseDeleteModal}
              color="primary"
              variant="outlined"
            >
              បោះបង់
            </Button>
            <Button
              onClick={onConfirmDelete}
              variant="contained"
              color="secondary"
            >
              លុប
            </Button>
          </DialogActions>
        </Dialog>
      </div>

      <div>
        {openViewModal && (
          <Dialog
            open={openViewModal}
            TransitionComponent={Transition}
            fullWidth={true}
            maxWidth={'sm'}
            onClose={handleCloseViewModal}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle id="alert-dialog-slide-title">
              <Typography className={classes.text}>View Detail</Typography>
            </DialogTitle>
            <DialogContent>
              <Grid container>
                <Grid item xs={4} className={classes.dialogRow}>
                  <p className={classes.text}>Username</p>
                </Grid>
                <Grid item xs={8} className={classes.dialogRow}>
                  <p className={classes.text}>{userView.username}</p>
                </Grid>
                <Grid item xs={4} className={classes.dialogRow}>
                  <p className={classes.text}>First name</p>
                </Grid>
                <Grid item xs={8} className={classes.dialogRow}>
                  <p className={classes.text}>{userView.first_name}</p>
                </Grid>
                <Grid item xs={4} className={classes.dialogRow}>
                  <p className={classes.text}>Last name</p>
                </Grid>
                <Grid item xs={8} className={classes.dialogRow}>
                  <p className={classes.text}>{userView.last_name}</p>
                </Grid>
                <Grid item xs={4} className={classes.dialogRow}>
                  <p className={classes.text}>Email</p>
                </Grid>
                <Grid item xs={8} className={classes.dialogRow}>
                  <p className={classes.text}>{userView.email}</p>
                </Grid>
                <Grid item xs={4} className={classes.dialogRow}>
                  <p className={classes.text}>Role</p>
                </Grid>
                <Grid item xs={8} className={classes.dialogRow}>
                  <p className={classes.text}>{userView.role}</p>
                </Grid>
                <Grid item xs={4} className={classes.dialogRow}>
                  <p className={classes.text}>Date</p>
                </Grid>
                <Grid item xs={8} className={classes.dialogRow}>
                  <p className={classes.text}>
                    {moment(userView.createdAt).format('DD/MM/YYYY')}
                  </p>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleCloseViewModal}
                color="primary"
                variant="outlined"
                className={classes.button}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </div>
    </div>
  );
};

UsersTable.propTypes = {
  className: PropTypes.string,
  users: PropTypes.array.isRequired,
  handleRefresh: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
  onPageChange: PropTypes.func,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  total: PropTypes.number,
};

export default UsersTable;
