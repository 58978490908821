import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button, Grid, } from '@material-ui/core';
import AddBoxIcon from '@material-ui/icons/AddBox';
import RefreshIcon from '@material-ui/icons/Refresh';
import SearchInput from "components/SearchInput"
import c from 'constant/constant';
import CustomSelect from 'components/select/custom-select';
const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    marginRight: theme.spacing(1),
  },
  button: {
    marginRight: '8px',
    textTransform: 'capitalize',
    fontFamily: 'Calibri',
    fontSize: c.fontSize.placholder,
  },
  dialogAction: {
    paddingRight: '25px',
  },
}));

const partOfSpeechTypes = [
  { value: 'all', label: 'All' },
  { value: 'kh', label: 'Kh' },
  { value: 'en', label: 'En' },
];

const PartOfSpeechToolBar = props => {
  const { className, handleRefresh, onSearchHandle, onOpenPartOfSpeechModal, handleFilterByPartOfSpeechType, ...rest } = props;
  const [selectedPartOfSpeech, setSelectedPartOfSpeech] = useState({
    value: 'all', label: 'All',
  });
  const classes = useStyles();

  const handleChangeValue = (option) => {
    setSelectedPartOfSpeech({
      value: option.value,
      label: option.label,
    });

    handleFilterByPartOfSpeechType(option.value);
  }

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={6} lg={6} style={{ display: 'flex', flexDirection: 'row' }}>
          <Button
            color="primary"
            variant="contained"
            className={classes.button}
            startIcon={<AddBoxIcon />}
            onClick={onOpenPartOfSpeechModal}
          >
            New
        </Button>
          <Button
            color="default"
            variant="outlined"
            className={classes.button}
            onClick={handleRefresh}
            startIcon={<RefreshIcon />}
          >
            Refresh
        </Button>
          <div style={{ width: '100px', height: '46px' }}>
            <CustomSelect
              options={partOfSpeechTypes}
              height='45px'
              selectedOption={selectedPartOfSpeech}
              borderRadius="5px"
              borderColor="white"
              handleChangeValue={handleChangeValue} />
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <div style={{ width: '300px' }}>
            <SearchInput
              className={classes.searchInput}
              placeholder="Search"
              onChange={onSearchHandle}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

PartOfSpeechToolBar.propTypes = {
  className: PropTypes.string,
  handleOpenAddUserModal: PropTypes.func,
  handleRefresh: PropTypes.func,
  onSearchHandle: PropTypes.func,
  handleFilterByPartOfSpeechType: PropTypes.func
};

export default PartOfSpeechToolBar;
