import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { UsersToolbar, UsersTable } from './components';
import { Divider, Grid, Button, TextField } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import SaveIcon from '@material-ui/icons/Save';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Swal from 'sweetalert2';
import { userService } from '../../services/user';
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));

const UserList = props => {
  const classes = useStyles();
  const [users, setUsers] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [search, setSearch] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const onOpenAddUserModal = () => {
    setOpenModal(true);
  };

  const onCloseAddUserModal = () => {
    console.log('called onCloseAddUserModal');
    setOpenModal(false);
  };

  const [user, setUser] = React.useState({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    role: '',
    username: '',
  });

  const handleChange = event => {
    setUser({
      ...user,
      [event.target.name]: event.target.value,
    });
  };

  const handleOnSave = () => {
    userService
      .createUser(user)
      .then(response => {
        if (response.code === 1) {
          setOpenModal(false);
          Swal.fire({
            icon: 'success',
            title: 'ADD SUCCESS',
          });
          handleRefresh();
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Opps, something went wrong.',
            html:
              '<div style="font-family: Battambang-Regular; font-size: 14 "> <p>' +
              response.message +
              '</p></div>',
          });
        }
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          title: 'Opps, something went wrong.',
          html:
            '<div style="font-family: Battambang-Regular; font-size: 14 "> <p>' +
            err +
            '</p></div>',
        });
      });
  };
  function getUserData() {
    userService
      .getAllUsers(search, page, rowsPerPage)
      .then(data => {
        if (data.code === 1) {
          let users = data.data;
          setUsers(users);
          setTotal(data.pagination.total_items);
        } else if (data.code === 0) {
          Swal.fire({
            icon: 'error',
            title: 'Opps, something went wrong.',
            html:
              '<div style="font-family: Battambang-Regular; font-size: 14 "> <p>' +
              data.message +
              '</p></div>',
          });
        }
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          title: 'Opps, something went wrong.',
          html:
            '<div style="font-family: Battambang-Regular; font-size: 14 "> <p>' +
            err +
            '</p></div>',
        });
      });
  }
  // useEffect(() => {
  //   getUserData();
  // }, []);
  useEffect(getUserData
    , [search, page, rowsPerPage]);

  const handleRefresh = e => {
    getUserData();
  };

  const onSearchChange = e => {
    let search = e.target.value;
    setSearch(search);
  }
  const onPageChange = (event, page) => {
    setPage(page);
  }
  const onRowsPerPageChange = e => {
    let value = e.target.value;
    setRowsPerPage(value);
  }
  return (
    <div className={classes.root}>
      <UsersToolbar
        handleRefresh={handleRefresh}
        onSearchChange={onSearchChange}
        handleOpenAddUserModal={onOpenAddUserModal}
      />
      <div className={classes.content}>
        <UsersTable users={users} handleRefresh={handleRefresh} onRowsPerPageChange={onRowsPerPageChange} onPageChange={onPageChange} page={page} rowsPerPage={rowsPerPage} total={total} />
        <div>
          <Dialog
            open={openModal}
            onClose={onCloseAddUserModal}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">អ្នកប្រើប្រាស់ថ្មី</DialogTitle>
            <Divider />
            <DialogContent>
              <Grid container spacing={1}>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="First name"
                    margin="dense"
                    name="first_name"
                    onChange={handleChange}
                    required
                    value={user.first_name}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Last name"
                    margin="dense"
                    name="last_name"
                    onChange={handleChange}
                    required
                    value={user.last_name}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Email"
                    margin="dense"
                    name="email"
                    onChange={handleChange}
                    required
                    value={user.email}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Username"
                    margin="dense"
                    name="username"
                    onChange={handleChange}
                    required
                    value={user.username}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    className={classes.formControl}
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      Role
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      onChange={handleChange}
                      value={user.role}
                      label="Role"
                      name="role"
                    >
                      <MenuItem value="admin">Admin</MenuItem>
                      <MenuItem value="editor">Editor</MenuItem>
                      <MenuItem value="contributor">Contributor</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="ពាក្យសម្ងាត់"
                    margin="dense"
                    required
                    name="password"
                    onChange={handleChange}
                    type="text"
                    value={user.password}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogAction}>
              <Button
                onClick={onCloseAddUserModal}
                color="default"
                variant="outlined"
              >
                បោះបង់
              </Button>
              <Button
                onClick={handleOnSave}
                color="primary"
                variant="contained"
                startIcon={<SaveIcon />}
              >
                រក្សាទុក
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </div>
  );
};

export default UserList;
