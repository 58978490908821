import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import WordRequestToolbar from './WordRequestToolbar/WordRequestToolbar';
import WordRequestTable from './WordRequestTable/WordRequestTable';
import { getData } from '../../provider/data-loader';
import constant from '../../constant/constant';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));

const WordRequest = props => {
  const classes = useStyles();
  const [wordRequests, setWordRequests] = useState([]);

  const fetchData = (type = 'all', search = "") => {
    const token = window.sessionStorage.getItem(constant.sessionKey);
    let url = constant.endPoint.wordRequest + '?token=' + token;
    if (type !== 'all') {
      url += '&type=' + type;
    }
    if (search.trim().length > 0) {
      url += '&search=' + search;
    }
    getData(url).then(data => {
      if (data.code === 1) {
        let wordRequest = data.data;
        console.log("AA", data.data)
        setWordRequests(wordRequest);
      } else if (data.code === 0) {
        console.log('error clients');
      } else {
        console.log('error server');
      }
    });
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleRefresh = e => {
    fetchData();
  };

  const handleChangeCategory = type => {
    fetchData(type);
  };

  const handleSearch = e => {
    e.preventDefault();
    fetchData('all', e.target.value);
  };

  return (
    <div className={classes.root}>
      <WordRequestToolbar
        handleRefresh={handleRefresh}
        handleSearch={handleSearch}
        handleChangeCategory={handleChangeCategory}
      />
      <div className={classes.content}>
        <WordRequestTable
          wordRequests={wordRequests}
          handleRefresh={handleRefresh}
        />
      </div>
    </div>
  );
};

export default WordRequest;
