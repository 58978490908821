import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import c from 'constant/constant';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexFlow: 'column',
    height: '100%',
  },
  content: {
    flexGrow: 1,
    [theme.breakpoints.down('xs')]: {
      padding: 10
    },
  },
  photo: {
    width: '100%',
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexFlow: 'row',
  },
  circular: {
    display: 'inline-block',
    position: 'relative',
    width: '150px',
    height: '150px',
    overflow: 'hidden',
    borderRadius: '50%',
    marginBottom: '10px',
  },
  img: {
    width: 'auto',
    height: '100%',
  },
  title: {
    height: '30px',
    width: '100%',
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '25px',
    flexFlow: 'row',
    color: c.color.main,
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  history: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '10px',
  },
  list: {
    marginLeft: '16px'
  }
}));

const Founder = props => {
  const classes = useStyles();

  return (
    <div
      className={clsx({
        [classes.root]: true,
      })}
    >
      <div className={classes.content}>
        <div className={classes.photo}>
          <div className={classes.circular}>
            <img className={classes.img} src="images/founder.jpeg" alt="founder" />
          </div>
        </div>
        <div className={classes.title}>ប្រវតិ្តសិក្សា និងការងារ</div>
        <div className={classes.history}>
          <ul className={classes.list}>
            <li>
              ជំនួយការ សម្តេចអគ្គមហាសេនាបតីតេជោ
              នាយករដ្ឋមន្ត្រីនៃព្រះរាជាណាចក្រកម្ពុជា
              </li>
            <li>រដ្ឋលេខាធិការក្រសួងបរិស្ថាន</li>
            <li>ប្រធានកិត្តិយស សាលាអន្តរជាតិ PARAGON</li>
            <li>សហស្ថាបនិក និងជាប្រធានកិត្តិយសសាជីវកម្ម PARAGON </li>
            <li>អតីតប្រធានសមាគមប្រទេសអ្នកផលិតកៅស៊ូធម្មជាតិ</li>
            <li>អតីតអនុរដ្ឋលេខាធិការក្រសួងកសិកម្ម រុក្ខាប្រមាញ់ និងនេសាទ</li>
            <li>អតីតអនុរដ្ឋលេខាធិការក្រសួងសាធារណការ និងដឹកជញ្ជូន</li>
            <li>អតីតនាយកគ្រប់គ្រងក្រុមហ៊ុនសាងសង់កម្ពុជា </li>
            <li>
              អតីតវិស្វករស្រាវជ្រាវ និងអភិវឌ្ឍន៍ ក្រុមហ៊ុន BETTIS CANADA LTD.
              </li>
            <li>អតីតប្រតិបត្តិករវិទ្យុហ្វ្រេកង់កម្រិតខ្ពស់ UNHCR ប្រទេសថៃ</li>
            <li>អតីតជំនួយការគ្រូពេទ្យ MEDICINES SANS FRONTIRES</li>
            <li>អតីតនិស្សិតវិស្វករ សកលវិទ្យាល័យ CALGARY ប្រទេសកាណាដា</li>
            <li>អតីតសិស្សសាលា VICTORIA COMPOSITE HIGH SCHOOL</li>
            <li>អតីតសិស្សវិទ្យាល័យទួលទំពូង</li>
            <li>អតីតសិស្សសាលាវត្តកោះ</li>
            <li>អតីតសិស្សសាលាចតុមុខ </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

Founder.propTypes = {
  className: PropTypes.string,
};

export default Founder;
