import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Dialog,
  DialogTitle,
  Divider,
  DialogContent,
  TextField,
  Button,
  Typography,
} from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import SyncLoader from 'react-spinners/SyncLoader';
import { TotalUsers } from './components';
import { postData, getData } from 'provider/data-loader';
import constants from 'constant/constant';
import CommentIcon from '@material-ui/icons/Comment';
import HelpIcon from '@material-ui/icons/Help';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import WallpaperIcon from '@material-ui/icons/Wallpaper';
import SpellcheckIcon from '@material-ui/icons/Spellcheck';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
  },
  dialogAction: {
    justifyContent: 'center',
  },
  textField: {
    '& .MuiFormLabel-root': {
      fontFamily: 'Calibri',
      fontSize: constants.fontSize.placholder,
    },
  },
  input: {
    fontFamily: 'Calibri',
    fontSize: constants.fontSize.placholder,
  },
  loginTitle: {
    textAlign: 'center',
  },
  lgoinBtn: {
    textTransform: 'capitalize',
  },
}));

const Dashboard = props => {
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [dashboard, setDashboard] = useState(null);
  const [user, setUser] = React.useState({
    email: '',
    password: '',
  });

  const [emailErrText, setEmailErrText] = useState('');
  const [pwdErrText, setPwdErrText] = useState('');
  const [check, setCheck] = useState(false);
  const [login, setLogin] = useState(true);
  const [loginErr, setLoginErr] = useState(true);

  useEffect(() => {
    const token = window.sessionStorage.getItem(constants.sessionKey);
    if (token !== null) {
      setLogin(false);
    } else {
      setLogin(true);
    }
    getData(constants.endPoint.dashboard + '?token=' + token)
      .then(result => {
        if (result.code === 1) {
          setDashboard(result.data);
        } else {
          console.error(result.message);
        }
      })
      .catch(err => console.error(err));
  }, []);

  const onCloseAddUserModal = () => {
    setOpenModal(false);
  };

  const handleChange = event => {
    setUser({
      ...user,
      [event.target.name]: event.target.value,
    });
  };

  const onKeyPressedOnPassword = event => {
    if (event.key === 'Enter') {
      handleLogin();
    }
  };

  function handleValidation() {
    let formIsValid = true;
    if (user.email === '') {
      formIsValid = false;
      setEmailErrText('សូមបញ្ចូលសារអេឡិចត្រូនិច');
    }
    if (user.password === '') {
      formIsValid = false;
      setPwdErrText('សូមបញ្ចូលពាក្យសម្ងាត់');
    }
    return formIsValid;
  }

  const handleLogin = () => {
    setCheck(true);
    if (handleValidation()) {
      setIsLoading(true);
      postData(constants.endPoint.auth, user)
        .then(value => {
          if (value.code === 1) {
            setIsLoading(false);
            window.sessionStorage.setItem('TOKEN', value.data.token);
            window.sessionStorage.setItem('USER', JSON.stringify(value.data));
            window.location.reload(false);
            onCloseAddUserModal();
          } else if (value.code === 0) {
            setIsLoading(false);
            setLoginErr('សារអេឡិចត្រូនិច ឬ ពាក្យសម្ងាត់ មិនត្រឹមត្រូវ');
          }
        })
        .catch(err => {
          console.log(err);
          setIsLoading(false);
          alert('Something went wrong');
        });
    }
  };

  const handleClick = (path) => {
    props.history.push(path);
  }

  const LoginDialog = () => {
    return (
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={openModal}
        onClose={onCloseAddUserModal}
        aria-labelledby="form-dialog-title"
        disableBackdropClick
      >
        <DialogTitle id="form-dialog-title" className={classes.loginTitle}>
          <Typography className={classes.input}>Login Form</Typography>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item md={12} xs={12}>
              <p style={{ color: constants.color.errText, paddingTop: 5, paddingBottom: 5, fontSize: 14 }}>
                {loginErr}
              </p>
              <TextField
                error={user.email === '' && check ? true : false}
                helperText={user.email === '' ? emailErrText : null}
                fullWidth
                label="Username or email"
                margin="dense"
                name="email"
                onChange={handleChange}
                required
                value={user.email}
                variant="outlined"
                className={classes.textField}
                InputProps={{
                  className: classes.input,
                }}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                error={user.password === '' && check ? true : false}
                helperText={user.password === '' ? pwdErrText : null}
                fullWidth
                label="Password"
                margin="dense"
                required
                name="password"
                onChange={handleChange}
                type="password"
                value={user.password}
                variant="outlined"
                className={classes.textField}
                onKeyPress={onKeyPressedOnPassword}
                InputProps={{
                  className: classes.input,
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={classes.dialogAction}>
          <div style={{ flexFlow: 'row', display: 'flex' }}>
            <div style={{ paddingRight: 10 }}>
              <Button
                color="primary"
                variant="outlined"
                className={`${classes.input} ${classes.lgoinBtn}`}
                onClick={() => props.history.push('/')}
              >
                Back
              </Button>
            </div>
            <div style={{ paddingLeft: 10 }}>
              <Button
                color="primary"
                variant="contained"
                className={`${classes.input} ${classes.lgoinBtn}`}
                onClick={handleLogin}
                endIcon={<ExitToAppIcon />}
              >
                Login
              </Button>
            </div>
          </div>
        </DialogActions>
        {isLoading ? (
          <div
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              justifyContent: 'center',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <SyncLoader
              size={15}
              color={constants.color.main}
              loading={isLoading}
            />
          </div>
        ) : null}
      </Dialog>
    );
  };

  return (
    <div className={classes.root}>
      {login && LoginDialog()}
      <Grid container spacing={4}>
        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <TotalUsers
            icon={<EqualizerIcon style={{ fontSize: 30 }} />}
            text="Total ខ្មែរ-ខ្មែរ"
            data={dashboard?.numberOfKKWord}
            fontSize={20}
            sub_text={dashboard?.numberOfPublishedKKWord}
            path="/kh-kh-words?page=1&count=10&status=all"
            handleClick={handleClick}
          />
        </Grid>
        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <TotalUsers
            icon={<EqualizerIcon style={{ fontSize: 30 }} />}
            text="Total ខ្មែរ-En"
            data={dashboard?.numberOfKEWord}
            fontSize={20}
            sub_text={dashboard?.numberOfPublishedKEWord}
            path="/kh-en-words?page=1&count=10&status=all"
            handleClick={handleClick}
          />
        </Grid>
        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <TotalUsers
            icon={<EqualizerIcon style={{ fontSize: 30 }} />}
            text="Total En-ខ្មែរ"
            data={dashboard?.numberOfEKWord}
            sub_text={dashboard?.numberOfPublishedEKWord}
            fontSize={20}
            path="/en-kh-words?page=1&count=10&status=all"
            handleClick={handleClick}
          />
        </Grid>
        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <TotalUsers
            icon={<AccountCircleIcon style={{ fontSize: 30 }} />}
            text="Total Users"
            data={dashboard?.numberOfUser}
            fontSize={20}
            path="/users"
            handleClick={handleClick}
          />
        </Grid>
        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <TotalUsers
            icon={<SpellcheckIcon style={{ fontSize: 30 }} />}
            text="Total Word Requested"
            data={dashboard?.numberOfWordRequest}
            fontSize={16}
            path="/wordrequets"
            handleClick={handleClick}
          />
        </Grid>
        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <TotalUsers
            icon={<WallpaperIcon style={{ fontSize: 30 }} />}
            text="Total Picture Requested"
            data={dashboard?.numberOfImageRequest}
            fontSize={16}
            path="/image-request"
            handleClick={handleClick}
          />
        </Grid>
        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <TotalUsers
            icon={<CommentIcon style={{ fontSize: 30 }} />}
            text="Total Comments"
            data={dashboard?.numberOfComment}
            fontSize={20}
            path="/word-comment"
            handleClick={handleClick}
          />
        </Grid>
        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <TotalUsers
            icon={<HelpIcon style={{ fontSize: 30 }} />}
            text="Total Questions"
            data={dashboard?.numberOfEnquiry}
            fontSize={20}
            path="/enquiries"
            handleClick={handleClick}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;
