import axios from 'axios';
import Promise from 'promise';

export const appSecret =
  '9c58c50a4362f687cd4509991ba81db5cf50eaa0b3880f4f943f77ba3ac6386b';

export const getData = (
  url = null,
  headers = { 'Content-Type': 'application/json' }
) => {
  // console.log('GET: ', url);
  return new Promise((resolve, reject) => {
    axios
      .get(url, { headers })
      .then(function (response) {
        // console.log('GET RESPONSE: ', response.data);
        resolve(response.data);
      })
      .catch(function (error) {
        console.log('GET ERROR: ', error);
        reject(error);
      });
  });
};

export const postData = (
  url = null,
  data = null,
  headers = { 'Content-Type': 'application/json' }
) => {
  // console.log('POST: ', url);
  return new Promise((resolve, reject) => {
    axios
      .post(url, data, { headers })
      .then(function (response) {
        // console.log('POST RESPONSE: ', response.data);
        resolve(response.data);
      })
      .catch(function (error) {
        // console.log('POST ERROR: ', error);
        reject(error);
      });
  });
};

export const postDataWithImage = (
  url = null,
  data = null,
  headers = { 'Content-Type': 'multipart/form-data' }
) => {
  // console.log('POST: ', url);
  return new Promise((resolve, reject) => {
    axios
      .post(url, data, { headers })
      .then(function (response) {
        // console.log('POST RESPONSE: ', response.data);
        resolve(response.data);
      })
      .catch(function (error) {
        // console.log('POST ERROR: ', error);
        reject(error);
      });
  });
};

export const putData = (
  url = null,
  data = null,
  headers = { 'Content-Type': 'application/json' }
) => {
  // console.log('PUT: ', url);
  return new Promise((resolve, reject) => {
    axios
      .put(url, data, { headers })
      .then(function (response) {
        // console.log('PUT RESPONSE: ', response.data);
        resolve(response.data);
      })
      .catch(function (error) {
        // console.log('PUT ERROR: ', error);
        reject(error);
      });
  });
};

export const patchData = (
  url = null,
  data = null,
  headers = { 'Content-Type': 'application/json' }
) => {
  // console.log('PATCH: ', url);
  return new Promise((resolve, reject) => {
    axios
      .patch(url, data, { headers })
      .then(function (response) {
        // console.log('PUT RESPONSE: ', response.data);
        resolve(response.data);
      })
      .catch(function (error) {
        // console.log('PUT ERROR: ', error);
        reject(error);
      });
  });
};

export const deleteData = (
  url = null,
  headers = { 'Content-Type': 'application/json' }
) => {
  // console.log('DELETE: ', url);
  return new Promise((resolve, reject) => {
    axios
      .delete(url, { headers })
      .then(function (response) {
        // console.log('DELETE RESPONSE: ', response.data);
        resolve(response.data);
      })
      .catch(function (error) {
        // console.log('DELETE ERROR: ', error);
        reject(error);
      });
  });
};
