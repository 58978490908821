import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/styles';
import {
  IconButton,
  Grid,
  TextField,
  Button,
  Box,
  InputAdornment,
  Typography,
  Checkbox,
  FormControlLabel
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { CardActions } from '@material-ui/core';
import { postData, patchData, getData } from '../../provider/data-loader';
import constant from '../../constant/constant';
import AddBoxIcon from '@material-ui/icons/AddBox';
import DeleteIcon from '@material-ui/icons/Delete';
import Swal from 'sweetalert2';
import SyncLoader from 'react-spinners/SyncLoader';
import SelectOption from 'components/select/select';
import CustomSelect from 'components/select/custom-select';
import { useDropzone } from 'react-dropzone';
import "../KhKhWord/styles.css";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    "& .MuiFormLabel-root": {
      fontSize: constant.fontSize.placholder,
      padding: '4px 0px'
    }
  },
  loading: {
    position: 'absolute',
    flexGrow: 1,
    display: 'flex',
    width: '80%',
    height: '80%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    marginTop: theme.spacing(1)
  },
  pagination: {
    marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  button: {
    marginRight: '8px',
    '&:hover': {
      background: constant.color.darkgreen,
    },
  },
  grid: {
    padding: 16,
    margin: 0,
  },
  table: {
    minWidth: 650,
  },
  tabItem: {
    color: 'white',
    fontFamily: constant.fontFamily.Khmer_Battambong_Regular,
  },
  count: {
    backgroundColor: '#cfd8dc',
    width: 20,
    height: 20,
    borderRadius: '50%',
    textAlign: 'center',
  },
  addBtn: {
    borderWidth: 1,
    borderColor: 'red',
    backgroundColor: constant.color.yellow,
    padding: 6,
  },
  deleteBtn: {
    padding: 6,
  },
  btn: {
    fontFamily: 'Calibri',
    textTransform: 'capitalize',
    fontSize: constant.fontSize.texfield,
    color: 'white',
    width: 100,
    margin: theme.spacing(1)
  },
  btnPadding: {
    padding: '2px 8px 2px 8px'
  },
  backBtn: {
    border: '1px solid #00A652',
    color: 'black'
  },
  actionBtn: {
    backgroundColor: constant.color.green,
    '&:hover': {
      background: constant.color.darkgreen,
    },
  },
  textSize: {
    fontSize: constant.fontSize.text,
  },
  textField: {
    "& .MuiFormLabel-root": {
      fontSize: constant.fontSize.texfield
    },
    padding: '6px 0px'
  },
  input: {
    fontSize: constant.fontSize.texfield,
    lineHeight: 2,
  },
  errorText: {
    color: constant.color.errText,
    fontSize: 11,
    fontFamily: constant.fontFamily.Khmer_Battambong_Regular
  },
  thumbsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
  },
  thumb: {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
  },
  thumbInner: {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
  },
  img: {
    display: 'block',
    width: 'auto',
    height: '100%'
  },
  closeIcon: {
    position: 'absolute',
    width: 100,
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: -10
  },
  icon: {
    backgroundColor: 'red',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  text: {
    fontSize: 20
  }
}));

const GreenCheckbox = withStyles({
  root: {
    color: 'black',
    '&$checked': {
      color: constant.color.green,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
};

const KhEnWords = props => {
  const classes = useStyles();
  const [selectedWordClass, setSelectedWordClass] = useState([
    {
      value: '',
      label: "Part of Speech"
    }
  ]);

  const [selectedPartOfSpeech, setSelectedPartOfSpeech] = useState(
    {
      value: '',
      label: "Part of Speech"
    }
  );


  const [multiImage, setMultiImage] = useState({ preview: [], raw: [] });
  const [multiSound, setMultiSound] = useState({ preview: [], raw: [] });
  const [wordErrTxt, setWordErrTxt] = useState('');
  const [pathOfSpeechErrTxt, setpathOfSpeechErrTxt] = useState('');
  const [meaningErrTxt, setMeaningErrTxtt] = useState('');
  const [check, setCheck] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [alsoAddToEK, setAlsoAddToEK] = useState(false);
  const [listPartofSpeech, setListPartOfspeech] = useState([]);
  const [addpathOfSpeechErrTxt, setAddpathOfSpeechErrTxt] = useState('');
  const [tempFile, setTempFile] = useState([]);
  const [tempFilePreview, setTempFilePreview] = useState([]);
  const [imageIds, setImageIds] = useState([]);
  const [imageIdsDel, setImageIdsDel] = useState([]);
  const [audioIds, setAudioIds] = useState([]);
  const [audioIdsDel, setAudioIdsDel] = useState([]);

  // field
  const [mainWord, setMainWord] = useState({
    word: '',
    phonetic: '',
    sound: [],
    images: [],
    definitions: [
      {
        meanings: [
          {
            examples: [''],
            _id: '',
            meaning: '',
          },
        ],
        _id: '',
        part_of_speech: '',
      },
    ],
    EKPartOfSpeech: '',
    alsoAddToEK: false
  });

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*, audio/*',
    onDrop: acceptedFiles => {
      if (acceptedFiles[0].type === "audio/mp3") {
        const fileArray = [];

        for (let i = 0; i < acceptedFiles.length; i++) {
          fileArray.push(URL.createObjectURL(acceptedFiles[i]));
        }
        setMultiSound({ preview: fileArray, raw: acceptedFiles });
      } else {
        const fileArray = [];
        for (let i = 0; i < acceptedFiles.length; i++) {
          setTempFile(oldFile => [...oldFile, acceptedFiles[i]]);
          setTempFilePreview(oldFile => [...oldFile, URL.createObjectURL(acceptedFiles[i])]);
          fileArray.push(URL.createObjectURL(acceptedFiles[i]));
        }
      }
    }
  });

  function endPoint() {
    const queryParam = props.location?.search;
    const params = new URLSearchParams(queryParam);
    const authorParam = params.get("author");
    const statusParam = params.get("status");
    const startDateParam = params.get("startDate");
    const endDateParam = params.get("endDate");
    const pageParam = params.get("page");
    const countParam = params.get("count");

    let endPoint = '?page='+ (parseInt(pageParam)) +'&count='+ parseInt(countParam) +'&status=' + statusParam;
    if (authorParam !== '' && authorParam !== undefined && authorParam !== null) {
      endPoint += '&author=' + authorParam
    }

    if (endDateParam !== '' && startDateParam) {
      endPoint += '&startDate=' + startDateParam + '&endDate=' + endDateParam
    }
    return endPoint;
  }

  const removeImage = (file, index) => {
    let tempArr = [...tempFile];
    let tempArrPreview = [...tempFilePreview];
    tempArr.splice(index, 1);
    tempArrPreview.splice(index, 1);

    setTempFile(tempArr);
    setTempFilePreview(tempArrPreview)
  }

  const removeSound = () => {
    setMultiSound({ preview: [], raw: [] });
  }

  const removeSoundFromServer = () => {
    console.log(audioIds)
    setAudioIdsDel(audioIds);
    let tempArr = [...audioIds];
    tempArr.splice(0, 1);

    setAudioIds(tempArr)
  }

  const thumbs = multiImage?.preview.map((file, index) => (
    <div className={classes.thumb} key={index}>
      <div className={classes.thumbInner}>
        <img
          src={file}
          className={classes.img}
          alt={"Image" + index}
        />
        <div className={classes.closeIcon}>
          <div className={classes.icon} onClick={() => removeImage(file, index)}>
            <HighlightOffIcon style={{ color: 'white' }} />
          </div>
        </div>
      </div>
    </div>
  ));

  const thumbsData = imageIds?.map((file, index) => (
    <div className={classes.thumb} key={index}>
      <div className={classes.thumbInner}>
        <img
          src={constant.endPoint.images + '/' + file}
          className={classes.img}
          alt={"Image" + index}
        />
        <div className={classes.closeIcon}>
          <div className={classes.icon} onClick={() => removeImageFromServr(file, index)}>
            <HighlightOffIcon style={{ color: 'white' }} />
          </div>
        </div>
      </div>
    </div>
  ));

  const removeImageFromServr = (file, index) => {
    setImageIds(imageIds.filter(item => item !== file));
    setImageIdsDel(oldData => [...oldData, imageIds.find(item => item === file)])
  }

  useEffect(() => {
    setMultiImage({ preview: tempFilePreview, raw: tempFile });
  }, [tempFilePreview, tempFile])


  const updateWordChanged = e => {
    let obj = { ...mainWord };
    obj.word = e.target.value;
    setMainWord(obj);
  };

  const updatePhoneticChanged = e => {
    let obj = { ...mainWord };
    obj.phonetic = e.target.value;
    setMainWord(obj);
  };

  const updateMeaningChanged = (index, meaningInex) => e => {
    let obj = { ...mainWord };
    let newArr = obj.definitions;
    newArr[index].meanings[meaningInex].meaning = e.target.value;
    setMainWord(obj);
  }

  const updateExamplesChanged = (index, meaningInex, exampleIndex) => e => {
    let obj = { ...mainWord };
    let newArr = obj.definitions;
    newArr[index].meanings[meaningInex].examples[exampleIndex] = e.target.value;
    setMainWord(obj);
  };

  useEffect(() => {
    if (props.location.state) {
      setIsLoading(true)
      if (props.location.state.data !== null)
        setMainWord(props.location.state.data);
      let images = props.location.state.data.images;
      let sounds = props.location.state.data.sounds;

      setAudioIds(sounds);
      setImageIds(images);
      setIsLoading(false);
      let newArr = [];
      props.location.state.data.definitions.forEach(element => {
        let obj = {
          value: element.part_of_speech,
          label: element.part_of_speech
        }
        newArr.push(obj);
      });
      setSelectedWordClass(newArr);
    }
  }, [props.location.state]);

  useEffect(() => {
    getPartOfSpeech();
  }, [])

  function getPartOfSpeech() {
    const token = window.sessionStorage.getItem(constant.sessionKey);
    setIsLoading(true);
    getData(constant.endPoint.partofspeech + '?token=' + token).then(data => {
      if (data.code === 1) {
        setIsLoading(false);
        let newArr = [];
        for (let i = 0; i < data.data.length; i++) {
          if (data.data[i].type === "en") {
            newArr.push({
              value: data.data[i].name,
              label: data.data[i].name
            });
          }
        }
        setListPartOfspeech(newArr);
      } else if (data.code === 0) {
        setIsLoading(false);
        console.log('error clients');
      } else {
        setIsLoading(false);
        console.log('error server');
      }
    });
  }

  const addMoreDefinition = () => {
    let obj = { ...mainWord };
    let newArr = obj.definitions;
    let newItem = {
      meanings: [
        {
          examples: [''],
          meaning: '',
        },
      ],
      part_of_speech: '',
    };
    newArr.push(newItem);

    let newPart_of_speech = [...selectedWordClass];
    var part_of_speech = {
      value: '',
      label: 'Part of Speech'
    }
    newPart_of_speech.push(part_of_speech);
    setSelectedWordClass(newPart_of_speech);

    setMainWord(obj);
  };

  const addMoreMeaning = index => e => {
    let obj = { ...mainWord };
    let newArr = obj.definitions[index].meanings;
    let newItem = {
      examples: [''],
      meaning: '',
    };
    newArr.push(newItem);
    setMainWord(obj);
  };

  const addMoreExample = (index, meaningInex) => e => {
    let obj = { ...mainWord };
    let newArr = obj.definitions[index].meanings[meaningInex].examples;
    let newItem = '';
    newArr.push(newItem);
    setMainWord(obj);
  };

  const removeExample = (index, meaningInex, exampleIndex) => e => {
    let obj = { ...mainWord };
    let newArr = obj.definitions[index].meanings[meaningInex].examples;
    newArr.splice(exampleIndex, 1);
    setMainWord(obj);
  };

  const removeMeaning = (index, meaningInex) => e => {
    let obj = { ...mainWord };
    let newArr = obj.definitions[index].meanings;
    newArr.splice(meaningInex, 1);
    setMainWord(obj);
  };

  const removeDefintion = index => e => {
    let obj = { ...mainWord };
    let newArr = obj.definitions;
    newArr.splice(index, 1);
    setMainWord(obj);
  };

  function handleValidation() {
    let formIsValid = true;

    if (mainWord.word === '') {
      formIsValid = false;
      setWordErrTxt('សូមបញ្ចូលពាក្យជាភាសាខ្មែរ');
    }

    if (alsoAddToEK) {
      if (selectedPartOfSpeech.value === '') {
        formIsValid = false;
        setAddpathOfSpeechErrTxt('សូមបញ្ចូលថ្នាក់ពាក្យ');
      }
    }

    if (mainWord.phonetic === '') {
      let obj = { ...mainWord };
      mainWord.phonetic = " ";
      setMainWord(obj)
    }

    for (let i = 0; i < mainWord.definitions.length; i++) {
      if (mainWord.definitions[i].part_of_speech === '') {
        formIsValid = false;
        setpathOfSpeechErrTxt('សូមបញ្ចូលថ្នាក់ពាក្យ');
      }
      for (let j = 0; j < mainWord.definitions[i].meanings.length; j++) {
        if (mainWord.definitions[i].meanings[j].meaning === '') {
          formIsValid = false;
          setMeaningErrTxtt('សូមបញ្ចូលនិយមន័យជាភាសាអង់គ្លេស');
        }
        for (
          let k = 0; k < mainWord.definitions[i].meanings[j].examples.length; k++) {
          if (mainWord.definitions[i].meanings[j].examples[k] === '') {
            mainWord.definitions[i].meanings[j].examples[k] = " ";
            setMainWord(mainWord);
          }
        }
      }
    }
    return formIsValid;
  }

  const handleChangeAlsoAddToEk = () => {
    setAlsoAddToEK(!alsoAddToEK);
  }

  const submitWord = () => {
    setCheck(true);
    var form_data = new FormData();
    if (handleValidation()) {
      setIsLoading(true);
      for (let i = 0; i < mainWord.definitions.length; i++) {
        delete mainWord.definitions[i]._id;
        for (let j = 0; j < mainWord.definitions[i].meanings.length; j++) {
          delete mainWord.definitions[i].meanings[j]._id;
        }
      }
      if (!alsoAddToEK) {
        delete mainWord.EKPartOfSpeech;
      }
      form_data.append('definitions', JSON.stringify(mainWord.definitions));
      form_data.append('word', mainWord.word);
      form_data.append('phonetic', mainWord.phonetic);

      console.log("alsoAddToEK", alsoAddToEK);
      console.log("EKPartOfSpeech", selectedPartOfSpeech.value);

      form_data.append('alsoAddToEK', alsoAddToEK);
      form_data.append('EKPartOfSpeech', selectedPartOfSpeech.value);

      if (multiImage.raw.length > 0) {
        for (let i = 0; i < multiImage.raw.length; i++) {
          form_data.append('images', multiImage.raw[i]);
        }
      }

      if (multiSound.raw.length > 0) {
        for (let i = 0; i < multiSound.raw.length; i++) {
          form_data.append('sounds', multiSound.raw[i]);
        }
      }

      const token = window.sessionStorage.getItem(constant.sessionKey);
      postData(constant.endPoint.khEnWord + '?token=' + token, form_data)
        .then(value => {
          setIsLoading(false);
          if (value.code === 1) {
            props.history.push({
              pathname: '/kh-en-words',
              search: endPoint()
            });
            Swal.fire({
              icon: 'success',
              text: 'រក្សាទុកជោគជ័យ',
            });
          } else if (value.code === 0) {
            Swal.fire({
              icon: 'warning',
              text: 'ពាក្យនេះមានរួចហើយ​​ សូមបញ្ចូលពាក្យថ្មី',
            });
          }
        })
        .catch(err => {
          setIsLoading(false);
          alert('Something went wrong');
          console.log(err);
        });

    } else {
      console.log('failed');
    }
  };

  const updateFormData = () => {
    setCheck(true);
    var form_data = new FormData();
    if (handleValidation()) {
      setIsLoading(true);
      form_data.append('definitions', JSON.stringify(mainWord.definitions));
      form_data.append('word', mainWord.word);
      form_data.append('phonetic', mainWord.phonetic);
      if (multiImage.raw.length > 0) {
        for (let i = 0; i < multiImage.raw.length; i++) {
          form_data.append('images', multiImage.raw[i]);
        }
      }

      if (imageIdsDel.length > 0) {
        for (let i = 0; i < imageIdsDel.length; i++) {
          form_data.append('deleted_images', imageIdsDel[i]);
        }
      }

      if (audioIdsDel.length > 0) {
        for (let i = 0; i < audioIdsDel.length; i++) {
          form_data.append('deleted_sounds', audioIdsDel[i]);
        }
      }

      if (multiSound.raw.length > 0) {
        for (let i = 0; i < multiSound.raw.length; i++) {
          form_data.append('sounds', multiSound.raw[i]);
        }
      }

      const token = window.sessionStorage.getItem(constant.sessionKey);
      patchData(
        constant.endPoint.khEnWord + '/' + mainWord._id + '?token=' + token,
        form_data
      )
        .then(value => {
          setIsLoading(false);
          if (value.code === 1) {
            Swal.fire({
              icon: 'success',
              text: 'កែប្រែជោគជ័យ',
            });
            props.history.push({
              pathname: '/kh-en-words',
              search: endPoint()
            });
          } else {
            setIsLoading(false);
            Swal.fire({
              icon: 'success',
              text: 'Something went wrong',
            });
          }
        })
        .catch(err => {
          setIsLoading(false);
          console.log(err);
          alert('មិនមានអុីនធើណេត');
        });
    }
  };

  const handleChangeValue = (definition_item, option, index) => {
    let obj = { ...mainWord };
    let updatedWordClass = [...selectedWordClass];
    updatedWordClass[index] = option;
    setSelectedWordClass(updatedWordClass);
    definition_item.part_of_speech = option.value;
    obj.definitions[index] = definition_item;
    setMainWord(obj);
  }

  const handleChangePartOfSpeech = option => {
    setAddpathOfSpeechErrTxt('')
    setSelectedPartOfSpeech({
      value: option.value,
      label: option.label,
    });
  };

  const stepTwo = () => {
    return (
      <Grid container spacing={1} className={classes.grid}>
        {mainWord._id ? (
          <Grid container>
            <Grid container spacing={1} style={{ paddingTop: 5 }}>
              <Grid item md={6}>
                <p className={classes.text}>Image</p>
                <section className="container">
                  <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    <AddCircleOutlineIcon style={{ fontSize: 30 }} />
                    <p>Drag and drop or click to upload photo</p>
                  </div>
                  <aside style={thumbsContainer}>
                    {thumbsData}
                    {thumbs}
                  </aside>
                </section>
              </Grid>

              <Grid container style={{ marginTop: 20 }}>
                <Grid item md={6}>
                  <p className={classes.text}>Sound</p>
                  <section className="container">
                    <div {...getRootProps({ className: 'dropzone' })}>
                      <input {...getInputProps()} />
                      <AddCircleOutlineIcon style={{ fontSize: 30 }} />
                      <p>Drag and drop or click to upload sound</p>
                    </div>
                    <aside style={thumbsContainer}>

                    </aside>
                  </section>
                </Grid>
                <Grid item md={9} xs={12} style={{ display: 'flex', flexDirection: 'row' }}>
                  {multiSound.preview.length > 0 ? (
                    <div>
                      {multiSound.preview.map((sound, index) => {
                        return (
                          <div key={index}>
                            <audio
                              src={sound}
                              autoPlay={false}
                              controls={true}
                            />
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                      <div>
                        {
                          audioIds ?
                            audioIds.map((sound, index) => {
                              return (
                                <div key={index}>
                                  <audio
                                    src={constant.endPoint.sounds + '/' + sound}
                                    autoPlay={false}
                                    controls={true}
                                  />
                                </div>
                              );
                            }) : ""}
                      </div>
                    )}

                  {
                    audioIds.length > 0 &&
                    <IconButton
                      color="inherit"
                      onClick={removeSoundFromServer}
                    >
                      <DeleteIcon
                        style={{ color: constant.color.main }}
                      />
                    </IconButton>
                  }
                </Grid>
              </Grid>
            </Grid>

            <Grid item md={12}>
              <CardActions>
                <div style={{ width: '100%', textAlign: 'center' }}>
                  <Button
                    color="primary"
                    variant="outlined"
                    className={`${classes.btn} ${classes.backBtn}`}
                    onClick={() => props.history.goBack()}
                  >
                    Back
                  </Button>

                  <Button
                    color="primary"
                    variant="outlined"
                    className={`${classes.btn} ${classes.actionBtn}`}
                    onClick={updateFormData}
                  >
                    Update
                  </Button>
                </div>
              </CardActions>
            </Grid>
          </Grid>
        ) : (
            <Grid container spacing={1}>
              <Grid container spacing={1} style={{ paddingTop: 5 }}>
              <Grid item md={6} xs={12}>
                  <p className={classes.text}>Images</p>
                  <section className="container">
                    <div {...getRootProps({ className: 'dropzone' })}>
                      <input {...getInputProps()} />
                      <AddCircleOutlineIcon style={{fontSize: 30}}/>
                      <p>Drag and drop or click to upload photo</p>
                    </div>
                    <aside style={thumbsContainer}>
                      {thumbs}
                    </aside>
                  </section>
                </Grid>
              </Grid>

              <Grid container style={{ marginTop: 20 }}>
              <Grid item md={6} xs={12}>
                <p className={classes.text}>Sound</p>
                  <section className="container">
                    <div {...getRootProps({ className: 'dropzone' })}>
                      <input {...getInputProps()} />
                      <AddCircleOutlineIcon style={{fontSize: 30}}/>
                      <p>Drag and drop or click to upload sound</p>
                    </div>
                    <aside style={thumbsContainer}>

                    </aside>
                  </section>

                </Grid>
                <Grid item md={9} xs={12} style={{ display: 'flex', flexDirection: 'row' }}>
                  {multiSound.preview.length > 0 && (
                    <div>
                      {multiSound.preview.map((sound, index) => {
                        return (
                          <div key={index}>
                            <audio src={sound} autoPlay={false} controls={true} />
                          </div>
                        );
                      })}
                    </div>
                  )}

                  {
                    multiSound.preview.length > 0 &&
                    <IconButton
                      color="inherit"
                      onClick={removeSound}
                    >
                      <DeleteIcon
                        style={{ color: constant.color.main }}
                      />
                    </IconButton>
                  }

                </Grid>
              </Grid>

              <Grid item md={12}>
                <CardActions>
                  <div style={{ width: '100%', textAlign: 'center' }}>
                    <Button
                      color="primary"
                      variant="outlined"
                      className={`${classes.btn} ${classes.backBtn}`}
                      onClick={() => props.history.goBack()}
                    >
                      Back
                  </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      className={`${classes.btn} ${classes.actionBtn}`}
                      onClick={submitWord}
                    >
                      Save
                  </Button>
                  </div>
                </CardActions>
              </Grid>
            </Grid>
          )}
      </Grid>
    );
  };

  const stepOne = () => {
    return (
      <div>
        {mainWord._id ? (
          <Grid container spacing={1} className={classes.grid}>
            <Grid item md={6} xs={12}>
              <TextField
                error={mainWord.word === '' && check ? true : false}
                helperText={mainWord.word === '' && check ? wordErrTxt : null}
                fullWidth
                label="ពាក្យជាភាសាខ្មែរ"
                margin="dense"
                name="word"
                required
                value={mainWord.word}
                InputLabelProps={
                  mainWord.word !== '' ? { shrink: true } : { shrink: false }
                }
                onChange={e => {
                  updateWordChanged(e);
                }}
                variant="outlined"
                className={classes.textField}
                InputProps={{
                  className: classes.input
                }}
              />
            </Grid>
            <Grid item md={5} xs={11}>
              <TextField
                fullWidth
                label="សូរអាន"
                margin="dense"
                name="phonetic"
                required
                value={mainWord.phonetic}
                InputLabelProps={
                  mainWord.phonetic !== ''
                    ? { shrink: true }
                    : { shrink: false }
                }
                onChange={e => {
                  updatePhoneticChanged(e);
                }}
                variant="outlined"
                className={classes.textField}
                InputProps={{
                  className: classes.input
                }}
              />
            </Grid>
            <Grid item md={12} style={{ marginTop: 16 }}>
              <p>និយមន័យ</p>
            </Grid>
            <Box
              border={1}
              borderColor="#cfd8dc"
              style={{ padding: 10, borderRadius: 5, width: '100%' }}
            >
              <Grid container>
                {mainWord.definitions.map((item, index) => {
                  return (
                    <Grid
                      container
                      spacing={1}
                      style={{ paddingLeft: 3, paddingRight: 3 }}
                      key={index}
                    >
                      <Grid item md={3} xs={12}>
                        <div style={{ float: 'left', width: '100%', marginTop: 14 }}>
                          <SelectOption
                            index={index}
                            item={item}
                            options={listPartofSpeech}
                            selectedOption={selectedWordClass[index]}
                            handleChangeValue={handleChangeValue} />
                        </div>
                        {
                          selectedWordClass[index].value === '' &&
                          <p className={classes.errorText}>{pathOfSpeechErrTxt}</p>
                        }
                      </Grid>
                      <Grid item md={9} xs={12}>
                        {item.meanings.map((meaningItem, meaningInex) => {
                          return (
                            <Grid container key={meaningInex}>
                              <Grid item md={11} xs={12}>
                                <TextField
                                  error={
                                    meaningItem.meaning === '' && check
                                      ? true
                                      : false
                                  }
                                  helperText={
                                    meaningItem.meaning === '' && check
                                      ? meaningErrTxt
                                      : null
                                  }
                                  fullWidth
                                  label="និយមន័យជាភាសាអង់គ្លេស"
                                  margin="dense"
                                  name="meaning"
                                  required
                                  multiline={true}
                                  rows={3}
                                  className={classes.textField}
                                  InputProps={{
                                    className: classes.input,
                                    startAdornment: (
                                      <InputAdornment position="start" style={{ marginTop: -70 }}>
                                        <div className={classes.count}>
                                          <Typography>
                                            {meaningInex + 1}
                                          </Typography>
                                        </div>
                                      </InputAdornment>
                                    ),
                                  }}
                                  value={meaningItem.meaning}
                                  // InputLabelProps={meaningItem.meaning != '' ? { shrink: true } : { shrink: false }}
                                  onChange={updateMeaningChanged(
                                    index,
                                    meaningInex
                                  )}
                                  variant="outlined"
                                />
                              </Grid>
                              <Grid item md={1} xs={12}>
                                <div style={{ margin: 9 }}>
                                  {meaningInex === 0 ? (
                                    <IconButton
                                      className={classes.addBtn}
                                      color="inherit"
                                      onClick={addMoreMeaning(index)}
                                    >
                                      <AddBoxIcon
                                        style={{ color: constant.color.main }}
                                      />
                                    </IconButton>
                                  ) : (
                                      <IconButton
                                        className={classes.deleteBtn}
                                        color="inherit"
                                        onClick={removeMeaning(
                                          index,
                                          meaningInex
                                        )}
                                      >
                                        <DeleteIcon fontSize="small" />
                                      </IconButton>
                                    )}
                                </div>
                              </Grid>

                              <Grid container style={{ paddingLeft: 16 }}>
                                {meaningItem.examples.map(
                                  (exampleItem, exampleIndex) => {
                                    return (
                                      <Grid container key={exampleIndex}>
                                        <Grid item md={11} xs={12}>
                                          <TextField
                                            fullWidth
                                            label="ឧទាហរណ៍"
                                            margin="dense"
                                            name="example"
                                            required
                                            multiline={true}
                                            rows={2}
                                            className={classes.textField}
                                            InputProps={{
                                              className: classes.input,
                                              startAdornment: (
                                                <InputAdornment position="start">
                                                  <div
                                                    className={classes.count}
                                                  >
                                                    <Typography style={{ marginTop: 1 }}>
                                                      {exampleIndex + 1}
                                                    </Typography>
                                                  </div>
                                                </InputAdornment>
                                              ),
                                            }}
                                            value={exampleItem}
                                            // InputLabelProps={exampleItem != '' ? { shrink: true } : { shrink: false }}
                                            onChange={updateExamplesChanged(
                                              index,
                                              meaningInex,
                                              exampleIndex
                                            )}
                                            variant="outlined"
                                          />
                                        </Grid>
                                        <Grid item md={1} xs={12}>
                                          {exampleIndex === 0 ? (
                                            <div style={{ margin: 9 }}>
                                              <IconButton
                                                className={classes.addBtn}
                                                color="inherit"
                                                onClick={addMoreExample(
                                                  index,
                                                  meaningInex
                                                )}
                                              >
                                                <AddBoxIcon
                                                  style={{
                                                    color: constant.color.main,
                                                  }}
                                                />
                                              </IconButton>
                                            </div>
                                          ) : (
                                              <div style={{ margin: 9 }}>
                                                <IconButton
                                                  className={classes.deleteBtn}
                                                  color="inherit"
                                                  onClick={removeExample(
                                                    index,
                                                    meaningInex,
                                                    exampleIndex
                                                  )}
                                                >
                                                  <DeleteIcon fontSize="small" />
                                                </IconButton>
                                              </div>
                                            )}
                                        </Grid>
                                      </Grid>
                                    );
                                  }
                                )}
                              </Grid>
                            </Grid>
                          );
                        })}
                      </Grid>
                      {index !== 0 && (
                        <Grid
                          item
                          md={12}
                          xs={12}
                          style={{ textAlign: 'right' }}
                        >
                          <Button
                            color="primary"
                            variant="outlined"
                            style={{
                              fontFamily:
                                constant.fontFamily.Khmer_Battambong_Regular,
                            }}
                            onClick={removeDefintion(index)}
                          >
                            លុបថ្នាក់ពាក្យ
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  );
                })}
                <Grid
                  container
                  spacing={1}
                  style={{ paddingLeft: 3, paddingRight: 3 }}
                >
                  <Grid item md={6} xs={12}>
                    <Button
                      color="primary"
                      variant="outlined"
                      style={{
                        fontFamily:
                          constant.fontFamily.Khmer_Battambong_Regular,
                        fontSize: constant.fontSize.texfield
                      }}
                      onClick={addMoreDefinition}
                    >
                      បន្ថែមថ្នាក់ពាក្យ
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        ) : (
            <Grid container spacing={1} className={classes.grid}>
              <Grid item md={6} xs={12}>
                <TextField
                  error={mainWord.word === '' && check ? true : false}
                  helperText={mainWord.word === '' && check ? wordErrTxt : null}
                  fullWidth
                  label="ពាក្យជាភាសាខ្មែរ"
                  margin="dense"
                  name="word"
                  required
                  value={mainWord.word}
                  InputLabelProps={
                    mainWord.word !== '' ? { shrink: true } : { shrink: false }
                  }
                  onChange={e => {
                    updateWordChanged(e);
                  }}
                  variant="outlined"
                  className={classes.textField}
                  InputProps={{
                    className: classes.input
                  }}
                />
              </Grid>
              <Grid item md={5} xs={11}>
                <TextField
                  fullWidth
                  label="សូរអាន"
                  margin="dense"
                  name="phonetic"
                  required
                  value={mainWord.phonetic}
                  InputLabelProps={
                    mainWord.phonetic !== ''
                      ? { shrink: true }
                      : { shrink: false }
                  }
                  onChange={e => {
                    updatePhoneticChanged(e);
                  }}
                  variant="outlined"
                  className={classes.textField}
                  InputProps={{
                    className: classes.input
                  }}
                />
              </Grid>
              <Grid item md={12} style={{ marginTop: 16 }}>
                {/* <p>{wordClass.filter(word => word.value === 'កិរិយាសព្ទ')}</p> */}
                <p>និយមន័យ</p>
              </Grid>

              <Box
                border={1}
                borderColor="#cfd8dc"
                style={{ padding: 10, borderRadius: 5, width: '100%' }}
              >
                <Grid container>
                  {mainWord.definitions.map((item, index) => {
                    return (
                      <Grid
                        container
                        spacing={1}
                        style={{ paddingLeft: 3, paddingRight: 3 }}
                        key={index}
                      >
                        <Grid item md={3} xs={12}>
                          <div style={{ float: 'left', width: '100%', marginTop: 14 }}>
                            <SelectOption
                              item={item}
                              index={index}
                              options={listPartofSpeech}
                              selectedOption={selectedWordClass[index]}
                              handleChangeValue={handleChangeValue}
                            />
                          </div>
                          {
                            selectedWordClass[index].value === '' &&
                            <p className={classes.errorText}>{pathOfSpeechErrTxt}</p>
                          }
                        </Grid>
                        <Grid item md={9} xs={12}>
                          {item.meanings.map((meaningItem, meaningInex) => {
                            return (
                              <Grid container key={meaningInex}>
                                <Grid item md={11} xs={12}>
                                  <TextField
                                    error={
                                      meaningItem.meaning === '' && check
                                        ? true
                                        : false
                                    }
                                    helperText={
                                      meaningItem.meaning === '' && check
                                        ? meaningErrTxt
                                        : null
                                    }
                                    fullWidth
                                    label="និយមន័យជាភាសាអង់គ្លេស"
                                    margin="dense"
                                    name="meaning"
                                    required
                                    multiline={true}
                                    rows={3}
                                    className={classes.textField}
                                    InputProps={{
                                      className: classes.input,
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <div className={classes.count} style={{ marginTop: -70 }}>
                                            <Typography>
                                              {meaningInex + 1}
                                            </Typography>
                                          </div>
                                        </InputAdornment>
                                      ),
                                    }}
                                    value={meaningItem.meaning}
                                    onChange={updateMeaningChanged(
                                      index,
                                      meaningInex
                                    )}
                                    variant="outlined"
                                  />
                                </Grid>
                                <Grid item md={1} xs={12}>
                                  <div style={{ margin: 9 }}>
                                    {meaningInex === 0 ? (
                                      <IconButton
                                        className={classes.addBtn}
                                        color="inherit"
                                        onClick={addMoreMeaning(index)}
                                      >
                                        <AddBoxIcon
                                          style={{ color: constant.color.main }}
                                        />
                                      </IconButton>
                                    ) : (
                                        <IconButton
                                          className={classes.deleteBtn}
                                          color="inherit"
                                          onClick={removeMeaning(
                                            index,
                                            meaningInex
                                          )}
                                        >
                                          <DeleteIcon fontSize="small" />
                                        </IconButton>
                                      )}
                                  </div>
                                </Grid>
                                <Grid container style={{ paddingLeft: 16 }}>
                                  {meaningItem.examples.map(
                                    (exampleItem, exampleIndex) => {
                                      return (
                                        <Grid container key={exampleIndex}>
                                          <Grid item md={11} xs={12}>
                                            <TextField
                                              fullWidth
                                              label="ឧទាហរណ៍"
                                              margin="dense"
                                              name="example"
                                              required
                                              multiline={true}
                                              rows={2}
                                              className={classes.textField}
                                              InputProps={{
                                                className: classes.input,
                                                startAdornment: (
                                                  <InputAdornment position="start">
                                                    <div
                                                      className={classes.count}
                                                    >
                                                      <Typography>
                                                        {exampleIndex + 1}
                                                      </Typography>
                                                    </div>
                                                  </InputAdornment>
                                                ),
                                              }}
                                              value={exampleItem}
                                              onChange={updateExamplesChanged(
                                                index,
                                                meaningInex,
                                                exampleIndex
                                              )}
                                              variant="outlined"
                                            />
                                          </Grid>
                                          <Grid item md={1} xs={12}>
                                            {exampleIndex === 0 ? (
                                              <div style={{ margin: 9 }}>
                                                <IconButton
                                                  className={classes.addBtn}
                                                  color="inherit"
                                                  onClick={addMoreExample(
                                                    index,
                                                    meaningInex
                                                  )}
                                                >
                                                  <AddBoxIcon
                                                    style={{
                                                      color: constant.color.main,
                                                    }}
                                                  />
                                                </IconButton>
                                              </div>
                                            ) : (
                                                <div style={{ margin: 9 }}>
                                                  <IconButton
                                                    className={classes.deleteBtn}
                                                    color="inherit"
                                                    onClick={removeExample(
                                                      index,
                                                      meaningInex,
                                                      exampleIndex
                                                    )}
                                                  >
                                                    <DeleteIcon fontSize="small" />
                                                  </IconButton>
                                                </div>
                                              )}
                                          </Grid>
                                        </Grid>
                                      );
                                    }
                                  )}
                                </Grid>
                              </Grid>
                            );
                          })}
                        </Grid>

                        {index !== 0 && (
                          <Grid
                            item
                            md={12}
                            xs={12}
                            style={{ textAlign: 'right' }}
                          >
                            <Button
                              color="primary"
                              variant="outlined"
                              style={{
                                fontFamily:
                                  constant.fontFamily.Khmer_Battambong_Regular,
                              }}
                              onClick={removeDefintion(index)}
                            >
                              លុបថ្នាក់ពាក្យ
                          </Button>
                          </Grid>
                        )}
                      </Grid>
                    );
                  })}
                  <Grid
                    container
                    spacing={1}
                    style={{ paddingLeft: 3, paddingRight: 3 }}
                  >
                    <Grid item md={6} xs={12} style={{ display: 'flex', flexDirection: 'column' }}>
                      <Button
                        color="primary"
                        variant="outlined"
                        style={{
                          fontFamily: constant.fontFamily.Khmer_Battambong_Regular,
                          fontSize: constant.fontSize.placholder,
                          width: '180px'
                        }}
                        onClick={addMoreDefinition}
                      >
                        បន្ថែមថ្នាក់ពាក្យ
                    </Button>
                      <div style={{ marginTop: 16 }}>
                        <FormControlLabel
                          control={<GreenCheckbox checked={alsoAddToEK} onChange={handleChangeAlsoAddToEk} />}
                          label={<p className={classes.text} style={{
                            fontSize: 20
                          }}>Add to EN-KH word</p>}
                        />
                      </div>
                      {
                        alsoAddToEK &&

                        <Grid container>
                          <Grid item md={6} xs={12}>
                            <div style={{ float: 'left', width: '100%', marginTop: 14 }}>
                              <CustomSelect
                                options={listPartofSpeech}
                                selectedOption={selectedPartOfSpeech}
                                handleChangeValue={handleChangePartOfSpeech}
                              />
                            </div>
                            <div className={classes.errorText}>
                              {addpathOfSpeechErrTxt}
                            </div>
                          </Grid>
                        </Grid>

                      }
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          )}
      </div>
    );
  };

  return (
    <div className={classes.root}>
      {isLoading && (
        <div className={classes.loading}>
          <SyncLoader
            size={15}
            color={constant.color.main}
            loading={isLoading}
          />
        </div>
      )}
      <div className={classes.content}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12}>
            <form>
              <Paper className={classes.paper}>
                <div style={{ backgroundColor: constant.color.main, justifyContent: 'center', display: 'flex', padding: 16 }}>
                  <Typography className={classes.textSize} style={{ color: 'white' }}>បញ្ចូលពាក្យ ខ្មែរ-អង់គ្លេស</Typography>
                </div>
                {stepOne()}
                {stepTwo()}
              </Paper>
            </form>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default KhEnWords;
