import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Swal from 'sweetalert2';
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Button,
  Grid,
  Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import constant, { getUser } from '../../../../constant/constant';
import { deleteData } from '../../../../provider/data-loader';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 1050,
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: 'flex-end',
  },
  button: {
    margin: theme.spacing(0.5),
    textTransform: 'Capitalize',
    fontFamily: 'Calibri',
  },
  text: {
    fontFamily: 'Calibri',
    fontSize: constant.fontSize.text,
  },
  tableContent: {
    fontSize: constant.fontSize.placholder,
  },
  enFont: {
    fontFamily: 'calibri',
  },
  delete: {
    backgroundColor: constant.color.red,
    '&:hover': {
      backgroundColor: constant.color.darkRed,
    },
  },
  view: {
    backgroundColor: constant.color.green,
    '&:hover': {
      backgroundColor: constant.color.darkgreen,
    },
  },
  dialogRow: {
    padding: '8px 0px 8px 0px',
  },
  action: {
    display: 'flex',
    flexFlow: 'row',
  },
}));

const List = props => {
  const { className, requests, onItemClick, handleRefresh, ...rest } = props;
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deletedRequest, setDeletedRequest] = useState(null);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [requestView, setRequestView] = useState(null);
  const user = JSON.parse(getUser());

  const handleClickOpenDeleteModal = (event, selectedItem) => {
    setOpenDeleteModal(true);
    setDeletedRequest(selectedItem);
  };

  const handleOpenViewModal = (event, item) => {
    setOpenViewModal(true);
    setRequestView(item);
  };

  const handleCloseViewModal = () => {
    setOpenViewModal(false);
  };

  const onConfirmDelete = () => {
    setOpenDeleteModal(false);
    if (deletedRequest) {
      deleteData(
        constant.endPoint.changeRequest +
          '/' +
          deletedRequest._id +
          '?token=' +
          window.sessionStorage.getItem(constant.sessionKey)
      )
        .then(response => {
          if (response.code === 1) {
            Swal.fire({
              icon: 'success',
              title: 'លុបទិន្នន័យបានជោគជ័យ',
            });
            handleRefresh();
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Opps, something went wrong.',
              html:
                '<div style="font-family: Battambang-Regular; font-size: 14 "> <p>' +
                response.message +
                '</p></div>',
            });
          }
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            title: 'Opps, something went wrong.',
            html:
              '<div style="font-family: Battambang-Regular; font-size: 14 "> <p>' +
              err +
              '</p></div>',
          });
        });
    }
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const classes = useStyles();

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  const getWord = item => {
    if (item.kh_kh_word) return item.kh_kh_word.word;
    if (item.kh_en_word) return item.kh_en_word.word;
    if (item.en_kh_word) return item.en_kh_word.word;
  };

  const getCategory = item => {
    if (item.kh_kh_word) return 'ខ្មែរ-ខ្មែរ';
    if (item.kh_en_word) return 'ខ្មែរ-ENG';
    if (item.en_kh_word) return 'ENG-ខ្មែរ';
  };

  return (
    <div>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.text}>#</TableCell>
                    <TableCell className={classes.text}>Category</TableCell>
                    <TableCell className={classes.text}>Word</TableCell>
                    <TableCell className={classes.text}>
                      Requested Definition
                    </TableCell>
                    <TableCell className={classes.text}>Date</TableCell>
                    <TableCell className={classes.text}>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {requests.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => (
                    <TableRow className={classes.tableRow} hover key={item._id}>
                      <TableCell className={classes.tableContent}>
                        {(page * rowsPerPage) + (index + 1)}
                      </TableCell>
                      <TableCell className={classes.tableContent}>
                        {getCategory(item)}
                      </TableCell>
                      <TableCell className={classes.tableContent}>
                        {getWord(item)}
                      </TableCell>
                      <TableCell
                        className={`${classes.tableContent} ${classes.enFont}`}
                      >
                        <ul>
                          {item.meanings.map((meaning, index) => {
                            return <li key={index}>{meaning}</li>;
                          })}
                        </ul>
                      </TableCell>
                      <TableCell className={classes.tableContent}>
                        {moment(item.createdAt).format('DD/MM/YYYY')}
                      </TableCell>
                      <TableCell className={classes.action}>
                        <Button
                          variant="contained"
                          size="small"
                          color="secondary"
                          className={`${classes.button} ${classes.view}`}
                          onClick={event => handleOpenViewModal(event, item)}
                        >
                          View
                        </Button>

                        {(user.role === constant.userRole.admin ||
                          user.role === constant.userRole.editor) && (
                          <div>
                            <Button
                              variant="contained"
                              color="secondary"
                              size="small"
                              onClick={event =>
                                handleClickOpenDeleteModal(event, item)
                              }
                              className={`${classes.button} ${classes.delete}`}
                              startIcon={<DeleteIcon />}
                            >
                              Delete
                            </Button>
                          </div>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={requests.length}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            labelRowsPerPage="ចំនួនទិន្នន័យក្នុងមួយទំព័រ"
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </CardActions>
      </Card>
      <div>
        <Dialog
          open={openDeleteModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleCloseDeleteModal}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">បញ្ជាក់</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              តើអ្នកពិតជាចង់លុបសំណើនេះមែនឬទេ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseDeleteModal}
              color="primary"
              variant="outlined"
            >
              បោះបង់
            </Button>
            <Button
              onClick={onConfirmDelete}
              variant="contained"
              color="secondary"
            >
              លុប
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <div>
        {openViewModal && (
          <Dialog
            open={openViewModal}
            TransitionComponent={Transition}
            fullWidth={true}
            maxWidth={'sm'}
            onClose={handleCloseViewModal}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle id="alert-dialog-slide-title">
              <Typography className={classes.text}>View Detail</Typography>
            </DialogTitle>
            <DialogContent>
              <Grid container>
                <Grid item xs={5} className={classes.dialogRow}>
                  <p className={classes.text}>Word</p>
                </Grid>
                <Grid item xs={7} className={classes.dialogRow}>
                  <p className={classes.text}>{getWord(requestView)}</p>
                </Grid>
                <Grid item xs={5} className={classes.dialogRow}>
                  <p className={classes.text}>Category</p>
                </Grid>
                <Grid item xs={7} className={classes.dialogRow}>
                  <p className={classes.text}>{getCategory(requestView)}</p>
                </Grid>
                <Grid item xs={5} className={classes.dialogRow}>
                  <p className={classes.text}>Requested Definition</p>
                </Grid>
                <Grid item xs={7} className={classes.dialogRow}>
                  <ul>
                    {requestView.meanings.map((meaning, index) => {
                      return (
                        <li key={index} className={classes.text}>
                          {meaning}
                        </li>
                      );
                    })}
                  </ul>
                </Grid>
                <Grid item xs={5} className={classes.dialogRow}>
                  <p className={classes.text}>Date</p>
                </Grid>
                <Grid item xs={7} className={classes.dialogRow}>
                  <p className={classes.text}>
                    {moment(requestView.createdAt).format('DD/MM/YYYY')}
                  </p>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleCloseViewModal}
                color="primary"
                variant="outlined"
                className={classes.button}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </div>
    </div>
  );
};

List.propTypes = {
  className: PropTypes.string,
  requests: PropTypes.array.isRequired,
  handleRefresh: PropTypes.func,
  onItemClick: PropTypes.func,
};

export default List;
