import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import c from 'constant/constant';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		flexFlow: 'column',
		height: '50vh',
	},
	content: {
		flexGrow: 1,
		[theme.breakpoints.down('xs')]: {
			padding: 10
		},
	},
	title: {
		height: '30px',
		width: '100%',
		marginTop: '20px',
		marginBottom: '30px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		fontSize: '30px',
		flexFlow: 'row',
		color: c.color.main,
		fontWeight: 'bold',
		paddingTop: 16
	},
	text: {
		color: 'black',
		padding: 4,
		lineHeight: 2,
		fontFamily: `Calibri, ${c.fontFamily.Khmer_Battambong_Regular}`,
	},
}));

const Node = props => {
	const classes = useStyles();

	return (
		<div
			className={clsx({
				[classes.root]: true,
			})}
		>
			<div className={classes.content}>
				<div className={classes.title}>កំណត់សម្គាល់</div>
				<div>
					<Typography className={classes.text}>
						KhmerPedia ត្រូវបានចងក្រងឡើងដោយប្រជុំវចនានុក្រមបច្ចេកទេស និងពិសេសៗជាច្រើន ដោយយកវចនានុក្រមសម្តេចព្រះសង្ឃរាជ ជួន ណាត ជាគោល ក្នុងគោលដៅប្រមូលផ្តុំ អភិរក្ស និងអភិវឌ្ឍអក្សរសាស្ត្រខ្មែរសម្រាប់សាធារណជនប្រើប្រាស់ប្រកបដោយសុក្រឹតភាព។
					</Typography>
				</div>
			</div>
		</div>
	);
};

Node.propTypes = {
	className: PropTypes.string,
};

export default Node;
