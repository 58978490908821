import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar, Badge, Hidden, IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import  DictionaryLogo from '../../../../assets/images/khpediaLogo.png'
import { ProfileMenu, LogoutDialog } from '../Sidebar/components';
import { getUser } from 'constant/constant';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none',
  },
  flexGrow: {
    flexGrow: 1,
  },
  signOutButton: {
    marginLeft: theme.spacing(1),
  },
}));

const Topbar = props => {
  const { className, onSidebarOpen, children,  ...rest } = props;

  const classes = useStyles();

  const [notifications] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openForm, setOpenForm] = useState(false);
  const user = JSON.parse(getUser());

  const handleSignOut = () => {
    setAnchorEl(null);
    setOpenForm(true);
  }

  const handleFormClose = () => {
    setOpenForm(false);
  };

  const handleSignOutButton = () => {
    window.sessionStorage.clear();
    children.props.history.push('/');
  }

  useEffect(() => {
    setAnchorEl(null);
  }, [])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar {...rest} className={clsx(classes.root, className)}>
      <Toolbar>
        <RouterLink to="/">
          <img height="90px" alt="Logo" src={DictionaryLogo} />
        </RouterLink>
        <div className={classes.flexGrow} />
        <Hidden mdDown>
          <IconButton color="inherit">
            <Badge
              badgeContent={notifications.length}
              color="primary"
              variant="dot"
            >
              {/* <NotificationsIcon /> */}
            </Badge>
          </IconButton>
          <div className={classes.profile}>
            <IconButton className={classes.signOutButton} color="inherit" onClick={handleClick}>
              <AccountCircleIcon style={{fontSize: 50}}/>
            </IconButton>
          </div>
          <ProfileMenu {...props}
                    anchorEl={anchorEl}
                    username={user?.first_name + " " + user?.last_name}
                    handleSignOut={handleSignOut} 
                    handleClose={handleClose}/>
          <LogoutDialog open={openForm} handleClose={handleFormClose} handleSignOutButton={handleSignOutButton} />
        </Hidden>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onSidebarOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
};

export default Topbar;
