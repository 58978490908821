import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import c from 'constant/constant';
import {
    Grid,
    TextField,
    Button,
    Card
} from '@material-ui/core';
import Swal from 'sweetalert2';
import { postData } from 'provider/data-loader';
import SyncLoader from 'react-spinners/SyncLoader';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        flexGrow: 1,
        height: '100%'
    },
    input: {
        fontSize: c.fontSize.texfield,
        lineHeight: 1.5,
    },
    title: {
        textAlign: 'center',
        padding: '10px 0px 16px 0px'
    },
    textField: {
        "& .MuiFormLabel-root": {
            fontSize: c.fontSize.texfield
        }
    },
    content: {
        padding: '16px',
        marginTop: '16px'
    }
}));

const RequestChange = props => {
    const classes = useStyles();
    const [updateWord, setUpdateWord] = useState();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        if (props.location.state) {
            setUpdateWord(props.location.state.state.updateWord);
            setIsLoading(false);
        }
    }, [props.location.state])

    const updateMeaningChanged = (index, meaningInex) => event => {
        updateWord.definitions[index].meanings[meaningInex].meaning = event.target.value;
        setUpdateWord({ ...updateWord, definitions: updateWord.definitions });
    };

    const handleOnUpdate = (event, wordToUpdate) => {
        const data = {
            kh_kh_word: wordToUpdate._id,
            meanings: []
        }
        let newArr = wordToUpdate.definitions;

        for (let i = 0; i < newArr.length; i++) {
            for (let j = 0; j < newArr[i].meanings.length; j++) {
                data.meanings.push(newArr[i].meanings[j].meaning)
            }
        }

        postData(c.endPoint.changerequest, data)
            .then(response => {
                if (response.code === 1) {
                    Swal.fire({
                        icon: 'success',
                        title: 'កែប្រែជោគជ័យ',
                    });
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Opps, something went wrong.',
                        html:
                            '<div style="font-family: Battambang-Regular; font-size: 14 "> <p>' +
                            response.message +
                            '</p></div>',
                    });
                }
            })
            .catch(err => {
                Swal.fire({
                    icon: 'error',
                    title: 'Opps, something went wrong.',
                    html:
                        '<div style="font-family: Battambang-Regular; font-size: 14 "> <p>' +
                        err +
                        '</p></div>',
                });
            });
    };


    return (
        <div className={clsx({
            [classes.root]: true,
        })}>
            {isLoading && (
                <div style={{ position: 'absolute' }}>
                    <SyncLoader size={15} color={c.color.main} loading={isLoading} />
                </div>
            )}
            <Card className={classes.content}>
                <div className={classes.title}>ស្នើសំុកែប្រែនិយមន័យនៃពាក្យ {updateWord?.word}</div>
                {
                    updateWord?.word !== '' && (
                        <Grid container spacing={2}>
                            <Grid item md={6} xs={12}>
                                <TextField
                                    fullWidth
                                    label="ពាក្យជាភាសាខ្មែរ"
                                    name="khword"
                                    value={updateWord?.word || ''}
                                    variant="outlined"
                                    disabled
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <TextField
                                    fullWidth
                                    label="phonetic"
                                    name="phonetic"
                                    value={updateWord?.phonetic || ''}
                                    variant="outlined"
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12}>
                                {updateWord?.definitions.map((definiton, index) => {
                                    return (
                                        <Grid container spacing={2} key={index}>
                                            <Grid item md={6} xs={12}>
                                                <TextField
                                                    fullWidth
                                                    label="ថ្នាក់ពាក្យ"
                                                    name="part_of_speech"
                                                    value={definiton.part_of_speech || ''}
                                                    variant="outlined"
                                                    disabled
                                                    className={classes.textField}
                                                />
                                            </Grid>
                                            <Grid item md={6} xs={12}>
                                                {definiton.meanings.map((meaning, meaningIndex) => {
                                                    return (
                                                        <Grid container spacing={2} key={meaningIndex} style={{marginTop: meaningIndex !== 0 && 16}}>
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    label="និយមន័យ"
                                                                    name="def"
                                                                    multiline={true}
                                                                    rows={4}
                                                                    onChange={updateMeaningChanged(
                                                                        index,
                                                                        meaningIndex,
                                                                    )}
                                                                    required
                                                                    value={meaning.meaning || ''}
                                                                    variant="outlined"
                                                                    InputProps={{
                                                                        className: classes.input
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                {meaning.examples.map((example, exampleIndex) => {
                                                                    return (
                                                                        <Grid container spacing={2} key={exampleIndex} style={{marginBottom: 10}}>
                                                                            {
                                                                                example !== ' ' &&
                                                                                <Grid item xs={12}>
                                                                                    <TextField
                                                                                        fullWidth
                                                                                        label="ឧទាហរណ៍"
                                                                                        name="example"
                                                                                        required
                                                                                        value={example}
                                                                                        variant="outlined"
                                                                                        disabled
                                                                                    />
                                                                                </Grid>
                                                                            }
                                                                        </Grid>

                                                                    )
                                                                }
                                                                )}
                                                            </Grid>

                                                        </Grid>
                                                    )
                                                }
                                                )
                                                }
                                            </Grid>
                                        </Grid>
                                    )
                                }
                                )}
                            </Grid>
                        </Grid>
                    )
                }
                <div style={{ textAlign: 'center', padding: '24px 0px 20px 0px' }}>
                    <Button
                        onClick={() => props.history.goBack()}
                        color="default"
                        variant="outlined"
                    >
                        បោះបង់
                    </Button>
                    <Button
                        onClick={event => handleOnUpdate(event, updateWord)}
                        color="primary"
                        style={{ marginLeft: '16px' }}
                        variant="contained"
                    >
                        កែប្រែ
            </Button>
                </div>
            </Card>
        </div>
    );
};

RequestChange.propTypes = {
    className: PropTypes.string,
};

export default RequestChange
