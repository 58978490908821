
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, ListItemIcon } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import c from '../../../../constant/constant';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import GooglePlay from "../../../../assets/images/GooglePlay.png";
import AppStore from "../../../../assets/images/AppStore.png";

const StyledMenuItem = withStyles((theme) => ({
    root: {
		backgroundColor: c.color.main,
		'& .MuiListItemIcon-root, & .MuiListItemText-primary': {
			color: 'white',
		},
        '&:hover': {
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: c.color.yellow,
            },
        },
    },
}))(MenuItem);

const StyledMenu = withStyles({
	paper: {
		background: c.color.main,
		width: '250px'
	},
})((props) => (
	<Menu
		elevation={0}
		getContentAnchorEl={null}
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'center',
		}}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'center',
		}}
		{...props}
	/>
));

const DownloadMenu = (props) => {
	const { openNoteDialog, handleClose } = props;
	const handleDownloadiOSApp = () => {
		window.open("https://apps.apple.com/kh/app/khmerpedia-dictionary/id1526674748", "_blank");
	}
	const handleDownloadAndroidApp = () => {
		window.open("https://play.google.com/store/apps/details?id=com.khpedia.dictionary.app", "_blank");
	}

	return (
		<StyledMenu
			id="customized-menu"
			anchorEl={openNoteDialog}
			keepMounted
			open={Boolean(openNoteDialog)}
			onClose={handleClose}
			autoFocus={false}
		>
			<StyledMenuItem onClick={handleDownloadiOSApp}>
                <ListItemIcon style={{ minWidth: '60px' }}>
					<img alt="Logo" src={AppStore} width='60px' />
                </ListItemIcon>
                <ListItemText primary="ប្រព័ន្ធទូរស័ព្ទ​ iOS" style={{marginLeft: 8}}/>
            </StyledMenuItem>
			<StyledMenuItem onClick={handleDownloadAndroidApp}>
                <ListItemIcon style={{ minWidth: '60px' }}>
					<img alt="Logo" src={GooglePlay} width='60px' />
                </ListItemIcon>
                <ListItemText primary="ប្រព័ន្ធទូរស័ព្ទ​ Android" style={{marginLeft: 8}}/>
            </StyledMenuItem>
		</StyledMenu>
	);
};

DownloadMenu.propTypes = {
	className: PropTypes.string,
	openNoteDialog: PropTypes.object,
	handleClose: PropTypes.func,
};

export default DownloadMenu;
