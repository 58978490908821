import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import c from 'constant/constant';
import clsx from 'clsx';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
  TextField,
  Grid,
} from '@material-ui/core';
import { postDataWithImage, getData } from 'provider/data-loader';
import Swal from 'sweetalert2';
import SyncLoader from 'react-spinners/SyncLoader';
import '../../../../index.css';
import CustomSelect from 'components/select/custom-select';
import { useDropzone } from 'react-dropzone';
import "./styles.css";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& .Mui-selected': {
      color: c.color.yellow,
    },
  },
  word: {
    fontSize: c.fontSize.title,
    color: c.color.yellow,
    fontWeight: 'bold',
    paddingTop: 10,
  },
  subTitle: {
    fontSize: c.fontSize.text,
    color: c.color.text,
    marginTop: 5,
    marginBottom: 5,
  },
  partOfSpeech: {
    fontSize: c.fontSize.title,
    color: c.color.main,
    fontWeight: 'bold',
  },
  meaning: {
    paddingLeft: 20,
    paddingTop: 10,
    paddingBottom: 10,
  },
  number: {
    float: 'left',
    minWidth: 20,
    color: c.color.text,
  },
  example: {
    paddingLeft: 20,
    paddingTop: 10,
    color: c.color.text,
  },
  textFont: {
    fontSize: c.fontSize.content,
  },
  requestBtn: {
    fontFamily: c.fontFamily.Khmer_Battambong_Regular,
    backgroundColor: c.color.main,
  },
  textField: {
    '& .MuiFormLabel-root': {
      fontSize: c.fontSize.texfield,
      lineHeight: 1,
      backgroundColor: 'red',
    },
  },
  input: {
    fontSize: c.fontSize.texfield,
    lineHeight: 1.5,
  },
  itemCenter: {
    textAlign: 'center',
  },
  thumbsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
  },
  thumb: {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
  },
  thumbInner: {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
  },
  img: {
    display: 'block',
    width: 'auto',
    height: '100%'
  },
  closeIcon: {
    position: 'absolute',
    width: 100,
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: -10
  },
  icon: {
    backgroundColor: 'red',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    '&:hover': {
      cursor: 'pointer',
    },
  }
}));

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
};

const KhKhRequestWord = ({ props }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [word, setWord] = useState('');
  const [definition, setDefinition] = useState('');
  const [ref, setRef] = useState('');
  const [requester, setRequester] = useState('');
  const [contact, setContact] = useState('');
  const [mutiImage, setMutiImage] = useState({ preview: [], raw: [] });
  const [wordErrTxt, setWordErrTxt] = useState('');
  const [defErrTxt, setDefErrTxt] = useState('');
  const [check, setCheck] = useState(false);
  const [listPartofSpeech, setListPartOfspeech] = useState([]);
  const [selectedPartOfSpeech, setSelectedPartOfSpeech] = useState({
    value: 'នាម',
    label: 'នាម',
  });


  const [tempFile, setTempFile] = useState([]);
  const [tempFilePreview, setTempFilePreview] = useState([]);

  const classes = useStyles();

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      const fileArray = [];

      for (let i = 0; i < acceptedFiles.length; i++) {
        setTempFile(oldFile => [...oldFile, acceptedFiles[i]]);
        setTempFilePreview(oldFile => [...oldFile, URL.createObjectURL(acceptedFiles[i])]);
        fileArray.push(URL.createObjectURL(acceptedFiles[i]));
      }
    }
  });

  const removeImage = (file, index) => {
    console.log("Index", index)
    let tempArr = [...tempFile];
    let tempArrPreview = [...tempFilePreview];
    tempArr.splice(index, 1);
    tempArrPreview.splice(index, 1);

    setTempFile(tempArr);
    setTempFilePreview(tempArrPreview)
  }

  const thumbs = mutiImage?.preview.map((file, index) => (
    <div className={classes.thumb} key={index}>
      <div className={classes.thumbInner}>
        <img
          src={file}
          className={classes.img}
          alt={"Image" + index}
        />
        <div className={classes.closeIcon}>
          <div className={classes.icon} onClick={() => removeImage(file, index)}>
            <HighlightOffIcon style={{ color: 'white' }} />
          </div>
        </div>
      </div>
    </div>
  ));

  useEffect(() => {
    setMutiImage({ preview: tempFilePreview, raw: tempFile });
  }, [tempFilePreview, tempFile]);

  useEffect(() => {
    getPartOfSpeech()
  }, []);

  function getPartOfSpeech() {
    setIsLoading(true);
    getData(c.endPoint.partofspeech).then(data => {
      if (data.code === 1) {
        setIsLoading(false);
        let newArr = [];
        for (let i = 0; i < data.data.length; i++) {
          if (data.data[i].type === "kh") {
            newArr.push({
              value: data.data[i].name,
              label: data.data[i].name
            });
          }
        }
        setListPartOfspeech(newArr);
      } else if (data.code === 0) {
        setIsLoading(false);
        console.log('error clients');
      } else {
        setIsLoading(false);
        console.log('error server');
      }
    });
  }

  function handleValidation() {
    let formIsValid = true;
    if (word === '') {
      formIsValid = false;
      setWordErrTxt('សូមបញ្ចូលពាក្យជាភាសាខ្មែរ');
    }
    if (definition === '') {
      formIsValid = false;
      setDefErrTxt('សូមបញ្ចូលនិយមន័យភាសាខ្មែរ');
    }
    return formIsValid;
  }

  const handleChangeValue = option => {
    setSelectedPartOfSpeech({
      value: option.value,
      label: option.label,
    });
  };

  const clear = () => {
    setDefinition('');
    setWord('');
    setRef('');
    setRequester('');
    setContact('');
    setMutiImage({ preview: [], raw: [] });
  };

  const submitWord = () => {
    setCheck(true);
    var form_data = new FormData();
    if (handleValidation()) {
      setIsLoading(true);
      if (mutiImage.raw.length > 0) {
        for (let i = 0; i < mutiImage.raw.length; i++) {
          form_data.append('images', mutiImage.raw[i]);
        }
      }
      form_data.append('word', word);
      form_data.append('definition', definition);
      form_data.append('type', 'KK');
      form_data.append('part_of_speech', selectedPartOfSpeech.value);
      form_data.append('requester', requester);
      form_data.append('contact', contact);
      form_data.append('reference', ref);
      for (var value of form_data.values()) {
        console.log(value);
      }
      postDataWithImage(c.endPoint.wordRequest, form_data)
        .then(value => {
          console.log(value);
          setIsLoading(false);
          if (value.code === 1) {
            Swal.fire({
              icon: 'success',
              title: 'សំណើជោគជ័យ',
              html:
                '<div style="font-family: Battambang-Regular; font-size: 14 "> <p>សូមអរគុណចំពោះការចូលរួមអភិរក្សនិងលើកតម្កើងអក្សរសាស្រ្ដខ្មែរយើង!</p> <p>ក្រុមការងារយើងខ្ញំនឹងធ្វើការត្រួតពិនិត្យការដាក់ស្នើដាក់បញ្ចូលរបស់លោកអ្នក!</p></div>',
            }).then(value => {
              setCheck(false);
              clear();
            });
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  };

  return (
    <div
      className={clsx({
        [classes.root]: true,
      })}
    >
      {isLoading && (
        <div style={{ position: 'absolute' }}>
          <SyncLoader size={15} color={c.color.main} loading={isLoading} />
        </div>
      )}

      <Grid container>
        <Grid item xs={12} sm={12} lg={12} md={12} xl={12}>
          <Card>
            <form>
              <CardHeader
                className={classes.itemCenter}
                title={
                  <p
                    style={{
                      color: c.color.main,
                      fontFamily: c.fontFamily.Khmer_Battambong_Regular,
                      fontSize: c.fontSize.text,
                    }}
                  >
                    បញ្ចូលពាក្យខ្មែរ-ខ្មែរ
                  </p>
                }
              />
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} lg={12} md={12} xl={12}>
                    <p className={classes.textFont}>ពាក្យជាភាសាខ្មែរ</p>
                    <TextField
                      error={word === '' && check ? true : false}
                      id="standard-error-helper-text"
                      fullWidth
                      placeholder="បញ្ចូលពាក្យ ភាសាខ្មែរ"
                      name="word"
                      type="text"
                      variant="outlined"
                      value={word}
                      helperText={word === '' ? wordErrTxt : null}
                      onChange={e => setWord(e.currentTarget.value)}
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <p className={classes.textFont}>ថ្នាក់ពាក្យ</p>
                    <CustomSelect
                      options={listPartofSpeech}
                      selectedOption={selectedPartOfSpeech}
                      handleChangeValue={handleChangeValue}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} lg={12} md={12} xl={12}>
                    <p className={classes.textFont}>និយមន័យជាភាសាខ្មែរ</p>
                    <TextField
                      error={definition === '' && check ? true : false}
                      fullWidth
                      name="definition"
                      placeholder="និយមន័យ ភាសាខ្មែរ"
                      type="text"
                      className={classes.textField}
                      multiline={true}
                      rows={3}
                      variant="outlined"
                      helperText={definition === '' ? defErrTxt : null}
                      value={definition}
                      InputProps={{
                        className: classes.input,
                      }}
                      onChange={e => setDefinition(e.currentTarget.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} lg={12} md={12} xl={12}>
                    <section className="container">
                      <div {...getRootProps({ className: 'dropzone' })}>
                        <input {...getInputProps()} />
                        <AddCircleOutlineIcon style={{ fontSize: 30 }} />
                        <p>Drag and drop or click to upload photo</p>
                      </div>
                      <aside style={thumbsContainer}>
                        {thumbs}
                      </aside>
                    </section>
                  </Grid>
                  <Grid item xs={12}>
                    <p className={classes.textFont}>ជម្រើសផ្តល់ទិន្នន័យ</p>
                    <TextField
                      fullWidth
                      placeholder="ឯកសារយោង"
                      name="word"
                      type="text"
                      variant="outlined"
                      value={ref}
                      onChange={e => setRef(e.currentTarget.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <p className={classes.textFont}>
                      ឈ្មោះរបស់អ្នក(មិនជាចាំបាច់)
                    </p>
                    <TextField
                      fullWidth
                      placeholder="ឈ្មោះរបស់អ្នក"
                      name="requester"
                      type="text"
                      variant="outlined"
                      value={requester}
                      onChange={e => setRequester(e.currentTarget.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <p className={classes.textFont}>
                      សារអេឡិចត្រូនិច(មិនជាចាំបាច់)
                    </p>
                    <TextField
                      fullWidth
                      placeholder="សារអេឡិចត្រូនិច"
                      name="contact"
                      type="text"
                      variant="outlined"
                      value={contact}
                      onChange={e => setContact(e.currentTarget.value)}
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <CardActions
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.requestBtn}
                  onClick={submitWord}
                >
                  ដាក់ស្នើពិនិត្យ
                </Button>
              </CardActions>
            </form>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

KhKhRequestWord.propTypes = {
  className: PropTypes.string,
};

export default KhKhRequestWord;
