import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/styles';
import {
  Toolbar,
  IconButton,
  Button,
  Grid,
  Tab,
  Tabs,
  TextField,
  useMediaQuery,
  Drawer,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  List
} from '@material-ui/core';
import c from '../../../constant/constant';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getWordToSearch, setTabSelected, getWordIdToSearch, clearWordToSearch, setKhKhWords } from '../actions';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import DictionaryLogo from '../../../assets/images/khpediaLogo.png';
import Logo from '../../../assets/images/logoDic.png';
import { Link as RouterLink } from 'react-router-dom';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MenuIcon from '@material-ui/icons/Menu';
import PersonIcon from '@material-ui/icons/Person';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import ComputerIcon from '@material-ui/icons/Computer';
import AddIcon from '@material-ui/icons/Add';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import AssignmentIcon from '@material-ui/icons/Assignment';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import WbIncandescentIcon from '@material-ui/icons/WbIncandescent';
import NoteDialog from '../components/Note'
import { getData } from 'provider/data-loader';
import Swal from 'sweetalert2';
import DownloadMenu from '../components/download-menu';
import { debounce } from 'lodash';

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2)
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1)
    },
    boxShadow: 'none',
    backgroundColor: c.color.main,
  },
  rightMenu: {
    position: 'absolute',
    right: 0,
  },
  flexGrow: {
    flexGrow: 1,
  },
  signOutButton: {
    marginLeft: theme.spacing(1),
  },
  menuBtn: {
    color: 'white',
    width: '100%',
    fontFamily: c.fontFamily.Khmer_Battambong_Regular,
    borderRadius: 0,
    borderBottomWidth: '5px',
    borderBottomStyle: 'solid',
    borderBottomColor: c.color.main
  },
  itemCenter: {
    display: 'flex',
    justifyContent: 'center',
  },
  borderRight: {
    borderRight: 2,
    borderRightColor: c.color.blueBorder,
    borderRightStyle: 'solid'
  },
  downloadApp: {
    color: 'black',
    fontFamily: c.fontFamily.Khmer_Battambong_Regular,
    backgroundColor: 'white',
    marginLeft: '10px',
  },
  contentTab: {
    width: '100%'
  },
  enFont: {
    fontFamily: 'calibri',
    textTransform: 'capitalize'
  },
  text: {
    color: 'white',
    fontSize: 14
  },
  pointer: {
    cursor: 'pointer'
  },
  tabItem: {
    color: 'white',
    fontFamily: c.fontFamily.Khmer_Battambong_Regular,
    minWidth: '33.3%'
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: c.color.main,
    cursor: 'pointer'
  },
  rootInput: {
    borderRadius: '4px',
    alignItems: 'center',
    padding: theme.spacing(1),
    display: 'flex',
    width: '100%',
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  input: {
    flexGrow: 1,
    fontSize: '14px',
    lineHeight: 1,
    backgroundColor: 'white',
    borderRadius: '4px',
  },
  autoComplete: {
    width: '100%',
  },
  searchBtn: {
    height: 46,
    marginLeft: 10,
    backgroundColor: c.color.yellow,
    color: 'white',
    fontFamily: c.fontFamily.Khmer_Battambong_Regular,
  },
  tabLabel: {
    display: 'flex',
    flexFlow: 'row',
  },
  tabIcon: {
    color: c.color.yellow
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  drawer: {
    flexShrink: 0,
    width: 240
  },
  drawerPaper: {
    width: 240
  },
}));

const Header = props => {
  const { className, children, getWordToSearch, getWordIdToSearch, setTabSelected, newSearchWord, clearWordToSearch, newTab, khkhWords, khenWords, enkhWords } = props;
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [suggestionList, setSuggestionList] = useState([]);
  const [drawer, setDrawer] = useState(false);
  const [isSaveWord, setIsSaveWord] = useState(false);
  const [isNewWord, setIsNewWord] = useState(false);
  const [isDownload, setIsDownload] = useState(false);
  const [openNoteDialog, setOpenNoteDialog] = useState(null);
  const [openDownloadMenu, setOpenDownloadMenu] = useState(null);
  const [isUnderline, setIsUnderline] = useState(false)
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const menus = [
    { id: 1, text: 'អំពីស្ថាបនិក', part: '/founder', icon: <PersonIcon /> },
    { id: 2, text: 'សេចក្តីថ្លែងអំណរគុណ', part: '/acknowledgement', icon: <FavoriteBorderIcon /> },
    { id: 3, text: 'អំពីកម្មវិធីទូរស័ព្ទ​ KhmerPedia', part: '/about', icon: <ComputerIcon /> },
    { id: 4, text: 'ស្នើបញ្ចូលវាក្យស័ព្ទថ្មី ឬ រូបភាព', part: '/words', icon: <AddIcon /> },
    { id: 6, text: 'ពាក្យរក្សាទុក', part: '/bookmardword', icon: <BookmarkBorderIcon /> },
    { id: 7, text: 'ពាក្យកាត់', part: '/abbrevation', icon: <AssignmentIcon /> },
    { id: 8, text: 'កំណត់សម្គាល់', part: '/note', icon: <WbIncandescentIcon /> },
    { id: 9, text: 'តើអ្នកមានសំណួរអ្វី?', part: '/enquiry', icon: <QuestionAnswerIcon /> },
  ]

  useEffect(() => {
      setSearchText(newSearchWord);
  }, [newSearchWord]);

  const handleMenuItem = (part) => {
    children.props.history.push(part);
    setDrawer(false)
  }

  useEffect(() => {
    setActiveTab(newTab);
    if (newTab === 0) {
      setSuggestionList(khkhWords);
    } else if (newTab === 1) {
     setSuggestionList(khenWords)
    } else {
      setSuggestionList(enkhWords)
    }
  }, [newTab, khkhWords, khenWords, enkhWords])

  const handleChange = (event, value) => {
    setActiveTab(value);
    setSearchText('');
    checkActiveBtn(false, false, false);
    clearWordToSearch();
    setTabSelected(value);
    children.props.history.push("/", {
      state: { item: searchText, activeTab: value }
    })
  };

  // const searchWord = (event) => {
  //   if (searchText === '') {
  //     console.log('Empty');
  //   } else {
  //     getWordToSearch(searchText);
  //     setTabSelected(activeTab);
  //     children.props.history.push("/", {
  //       state: { item: searchText, activeTab: activeTab }
  //     })
  //   }
  // };

  const saveWordButton = () => {
    children.props.history.push('/words');
    setSearchText('');
    setActiveTab(0);
    checkActiveBtn(false, true, false);
  }

  function checkActiveBtn(isSaveWord, isNewWord, isDownload) {
    setIsSaveWord(isSaveWord);
    setIsNewWord(isNewWord);
    setIsDownload(isDownload);
  }

  const saveQuestionButton = () => {
    children.props.history.push('/bookmardword');
    setSearchText('');
    setActiveTab(0);
    setIsSaveWord(true);
    checkActiveBtn(true, false, false);
  }

  const _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      console.log('do validate', newSearchWord);
      getWordToSearch(newSearchWord);
    }
  }

  const onChangeKeyWord = (e) => {
    if (newTab === 0) {
      getSuggestionWords(c.endPoint.khkhSuggestions + "?keyword=" + e.target.value);
    } else if (newTab === 1) {
      getSuggestionWords(c.endPoint.khenSuggestions + "?keyword=" + e.target.value);
    } else {
      getSuggestionWords(c.endPoint.enkhSuggestions + "?keyword=" + e.target.value);
    }
  }

  const getSuggestionWords = debounce(async endPoint => {
    getData(endPoint)
      .then(response => {
        if (response.code === 1) {
          setSuggestionList(response.data);
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Opps, something went wrong.',
            html:
              '<div style="font-family: Battambang-Regular; font-size: 14 "> <p>' +
              response.message +
              '</p></div>',
          });
        }
      })
      .catch(err => console.error(err));
  }, 500);

  const handleClickWord = (event, value) => {
    let word = null;
    if (value !== '') {
      if (typeof value == "string") {
        word = { id: value, word: value }
      } else {
        word = value;
        getWordIdToSearch(value._id)
      }
      getWordToSearch(word);
      setTabSelected(activeTab);
      children.props.history.push("/", {
        state: { item: searchText, activeTab: activeTab }
      })
    }
  }

  const toggleDrawer = event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawer(!drawer);
  };

  const handleCloseNoteDialog = () => {
    setOpenNoteDialog(null);
    setIsUnderline(false);
  };

  const handleCloseDownloadMenu = () => {
    setOpenDownloadMenu(null);
  }

  const handleOpenNoteDialog = (event) => {
    setIsUnderline(true)
    setOpenNoteDialog(event.currentTarget);
  };

  const handleOpenDownloadMenu = (event) => {
    setOpenDownloadMenu(event.currentTarget);
    checkActiveBtn(false, false, true);
  };

  const handleOpenAbbrevation = () => {
    children.props.history.push('/abbrevation')
  }

  return (
    <div className={clsx(classes.root, className)}>
      <Toolbar>
        <Drawer
          className={classes.drawer}
          variant="temporary"
          classes={{
            paper: classes.drawerPaper
          }}
          anchor="right"
          open={drawer}
          onClose={toggleDrawer}
        >
          <div style={{ textAlign: 'center', padding: '16px 0px' }}>
            <img src={Logo} width="120px" alt="app logo" />
          </div>
          <Divider />
          <List>
            {menus.map((menu, index) => (
              <ListItem button key={menu.id} onClick={() => handleMenuItem(menu.part)}>
                <ListItemIcon>
                  {menu.icon}
                </ListItemIcon>
                <ListItemText primary={menu.text} />
              </ListItem>
            ))}
          </List>
        </Drawer>

        <Grid container>
          <Grid item xs={11} sm={12} md={4} lg={3} xl={3} style={{ alignItems: 'center', display: 'flex' }}>
            <div>
              <RouterLink to="/">
                <img height={isXs ? '80px' : '120px'} alt="Logo" src={DictionaryLogo} onClick={() => children.props.history.push('/')} style={{ cursor: 'pointer' }} />
              </RouterLink>
            </div>
          </Grid>

          {
            isXs &&
            <Grid item xs={1}>
              <div>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={toggleDrawer}
                  className={classes.menuButton}
                >
                  <MenuIcon style={{ color: 'white' }} />
                </IconButton>
              </div>
            </Grid>
          }
          <Grid item xs={12} sm={12} md={8} lg={6} xl={6} style={{ display: 'flex', justifyContent: 'center' }}>
            <Grid container style={{ justifyContent: 'center', display: 'flex' }}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Tabs
                  style={{ backgroundColor: c.color.main }}
                  value={activeTab}
                  onChange={handleChange}
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: c.color.yellow,
                      height: 5,
                    },
                  }}
                >
                  <Tab label={<div className={classes.tabLabel}>
                    <p>ខ្មែរ</p>
                    <ArrowRightIcon className={classes.tabIcon} />
                    <p>ខ្មែរ</p>
                  </div>}
                    className={`${classes.tabItem} ${classes.borderRight}`} />
                  <Tab
                    label={<div className={classes.tabLabel}>
                      <p>ខ្មែរ</p>
                      <ArrowRightIcon className={classes.tabIcon} />
                      <p className={classes.enFont}>English</p>
                    </div>}
                    className={`${classes.tabItem} ${classes.borderRight}`} />
                  <Tab
                    label={<div className={classes.tabLabel}>
                      <p className={classes.enFont}>English</p>
                      <ArrowRightIcon className={classes.tabIcon} />
                      <p>ខ្មែរ</p>
                    </div>}
                    className={classes.tabItem} />
                </Tabs>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ backgroundColor: c.color.main }}>
                <div className={classes.row}>
                  {/* <Paper className={clsx(classes.rootInput, className)}>
                    <Input
                      value={searchText}
                      placeholder="ស្វែងរកពាក្យខ្មែរ"
                      className={classes.input}
                      disableUnderline
                      onKeyDown={_handleKeyDown}
                      onChange={e => setSearchText(e.currentTarget.value)}
                    />
                    <SearchIcon className={classes.icon} onClick={searchWord} style={{ cursor: 'pointer' }} />
                  </Paper> */}
                  {/* <Paper className={clsx(classes.rootInput)}> */}
                  <Autocomplete
                    freeSolo
                    id="free-solo-2-demo"
                    disableClearable
                    value={searchText}
                    options={suggestionList}
                    getOptionLabel={(option) => option?.word}
                    className={classes.autoComplete}
                    onChange={handleClickWord}
                    noOptionsText={'មិនមានទិន្នន័យ'}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        margin="dense"
                        variant="outlined"
                        onKeyDown={_handleKeyDown}
                        onChange={onChangeKeyWord}
                        InputProps={{ ...params.InputProps, type: 'search', className: classes.input }}
                      />
                    )}
                  />
                  {/* </Paper> */}
                </div>
              </Grid>

              <Grid container style={{ backgroundColor: c.color.main }}>
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4} className={`${classes.borderRight} ${classes.itemCenter}`}>
                  <Button
                    className={classes.menuBtn}
                    style={{ borderBottomColor: isSaveWord && c.color.yellow }}
                    onClick={saveQuestionButton}
                  >
                    <span style={{ display: 'flex', flexDirection: 'column' }}>
                      <span>ពាក្យរក្សាទុក</span>
                      <span className={classes.enFont}>save</span>
                    </span>
                  </Button>
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4} className={`${classes.borderRight} ${classes.itemCenter}`}>
                  <Button
                    className={classes.menuBtn}
                    style={{ borderBottomColor: isNewWord && c.color.yellow }}
                    onClick={saveWordButton}
                  >
                    <span style={{ display: 'flex', flexDirection: 'column' }}>
                      <span>សំណើបញ្ចូលពាក្យថ្មី</span>
                      <span className={classes.enFont}>Add New Word</span>
                    </span>
                  </Button>
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4} className={classes.itemCenter}>
                  <Button
                    className={classes.menuBtn}
                    style={{ flexWrap: 'wrap', borderBottomColor: isDownload && c.color.yellow }}
                    onClick={handleOpenDownloadMenu}
                  >
                    {isXs ?
                      <span style={{ display: 'flex', flexDirection: 'column' }}>
                        <span>ទាញយកកម្មវិធី</span>
                        <span className={classes.enFont}>Download Mobile App</span>
                      </span> :
                      <span style={{ display: 'flex', flexDirection: 'column' }}>
                        <span>ទាញយកកម្មវិធីលើទូរស័ព្ទដៃ</span>
                        <span className={classes.enFont} >
                          Download Mobile App
                          </span>
                      </span>}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={3} xl={3}
            style={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
            {
              !isXs &&
              <div className={classes.row}>
                <div
                  className={classes.row}
                  style={{ marginRight: 30 }}
                  onClick={handleOpenNoteDialog}>
                  <WbIncandescentIcon style={{ color: c.color.yellow, marginRight: 5 }} />
                  <p className={classes.text} style={{ borderBottom: isUnderline && '1px solid orange' }}>កំណត់សម្គាល់</p>
                </div>
                <p className={`${classes.text} ${classes.pointer}`} onClick={handleOpenAbbrevation}>ពាក្យកាត់</p>
              </div>
            }
          </Grid>
        </Grid>

        <NoteDialog
          {...props}
          openNoteDialog={openNoteDialog}
          handleClose={handleCloseNoteDialog} />

        <DownloadMenu 
        {...props}
        openNoteDialog={openDownloadMenu}
        handleClose={handleCloseDownloadMenu} />

      </Toolbar>
    </div>
  );
};

Header.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
  children: PropTypes.node,
  onStarUpdate: PropTypes.func
};

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    getWordToSearch,
    getWordIdToSearch,
    clearWordToSearch,
    setTabSelected,
    setKhKhWords
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
