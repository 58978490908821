import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/styles';
import {
  Grid,
  Button,
  Typography,
  CardActions,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Badge,
  Divider,
  Box,
  Checkbox,
  TextField
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import {
  getData,
  deleteData,
  putData,
  postData,
} from '../../../provider/data-loader';
import c, { getUser } from '../../../constant/constant';
import Swal from 'sweetalert2';
import SyncLoader from 'react-spinners/SyncLoader';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import PerfectScrollbar from 'react-perfect-scrollbar';
import moment from 'moment';
import ImageIcon from '@material-ui/icons/Image';
import CommentIcon from '@material-ui/icons/Comment';
import { ProductsToolbar } from 'views/KhKhWord/components';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setAuthorGlobal, setStatusGlobal, setPageGlobal, setRowInPerPageGlobal } from '../../User/actions';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    width: '100%',
    height: '100%',
    fontSize: c.fontSize.placholder,
  },
  loading: {
    display: 'flex',
    width: '80%',
    height: '80%',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
  },
  content: {
    marginTop: theme.spacing(2),
  },
  pagination: {
    marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  button: {
    marginRight: '8px',
    fontSize: c.fontSize.placholder,
    '&:hover': {
      background: c.color.darkgreen,
    },
  },
  iconButton: {
    margin: theme.spacing(0.5),
    textTransform: 'capitalize',
    fontFamily: 'Calibri',
  },
  grid: {
    padding: 16,
    margin: 0,
  },
  actionBtn: {
    backgroundColor: c.color.green,
  },
  textSize: {
    fontSize: c.fontSize.text,
  },
  engFontFamily: {
    fontFamily: 'Calibri',
    fontSize: c.fontSize.text,
  },
  actions: {
    justifyContent: 'flex-end',
  },
  word: {
    fontSize: 30,
    fontWeight: 'bold',
    paddingTop: 10,
    float: 'left',
  },
  subTitle: {
    fontSize: c.fontSize.text,
    color: c.color.text,
    marginTop: 5,
    marginBottom: 5,
  },
  partOfSpeech: {
    fontSize: c.fontSize.title,
    color: c.color.red,
    fontWeight: 'bold',
  },
  meaning: {
    paddingLeft: 20,
    paddingTop: 10,
    paddingBottom: 10,
    fontFamily: c.fontFamily.Khmer_Battambong_Regular,
  },
  number: {
    float: 'left',
    minWidth: 20,
    color: c.color.text,
  },
  example: {
    paddingLeft: 20,
    paddingTop: 10,
    color: c.color.text,
  },
  row: {
    display: 'flex',
    flexFlow: 'row',
  },
  detail: {
    fontFamily: 'Calibri',
    fontSize: c.fontSize.title,
  },
  btn: {
    fontFamily: 'Calibri',
    textTransform: 'capitalize',
  },
  imageRequest: {
    float: 'left',
    textAlign: 'center',
  },
  commentRequest: {
    display: 'flex',
    flexFlow: 'column',
  },
  view: {
    backgroundColor: c.color.green,
    '&:hover': {
      backgroundColor: c.color.darkgreen,
    },
  },
  delete: {
    backgroundColor: c.color.red,
    '&:hover': {
      backgroundColor: c.color.darkRed,
    },
  },
  edit: {
    backgroundColor: c.color.yellow,
    '&:hover': {
      backgroundColor: c.color.darkYellow,
    },
  },
  publish: {
    backgroundColor: c.color.main,
    '&:hover': {
      backgroundColor: c.color.darkMain,
    },
  },
  tableContent: {
    fontSize: c.fontSize.placholder,
  },
  enFont: {
    fontFamily: 'calibri',
  },
  comment: {
    display: 'flex',
    flexFlow: 'row',
    borderBottom: '1px solid gray',
    paddingBottom: '10px',
  },
  userIcon: {
    display: 'flex',
    flexFlow: 'row',
    paddingRight: '32px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textField: {
    fontFamily: 'calibri'
  },
  goButton: {
    marginRight: '8px',
    backgroundColor: c.color.main,
    color: 'white',
    textTransform: 'capitalize',
    fontFamily: 'Calibri',
    '&:hover': {
      background: c.color.darkMain,
    },
  },
}));

const GreenCheckbox = withStyles({
  root: {
    color: 'black',
    '&$checked': {
      color: c.color.green,
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

let wordsData = [];

const KhEnWordList = props => {
  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [words, setWords] = useState(wordsData);
  const [isLoading, setIsLoading] = useState(true);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deletedWord, setDeletedWord] = useState(null);
  const [viewWord, setViewWord] = useState(null);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [openImageRequestModal, setOpenImageRequestModal] = useState(false);
  const [imageRequest, setImageRequest] = useState([]);
  const [commentRequest, setCommentRequest] = useState([]);
  const [openImgRequest, setOpenImgRequest] = useState(false);
  const [openCmtRequest, setOpenCmtRequest] = useState(false);
  const [checkWord, setCheckWord] = useState([]);
  const [wordIds, setWordIds] = useState([]);
  const [total, setTotal] = useState(0);
  const [status, setStatus] = useState('');
  const [author, setAuthor] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [keyword, setKeyword] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const user = JSON.parse(getUser());
  const [users, setUsers] = useState();
  const [goToPage, setGoToPage] = useState();

  const handleRowsPerPageChange = event => {
    let value = event.target.value;
    setRowsPerPage(value);
    props.history.push({
      pathname: "/kh-kh-words",
      search: endPoint(1, value, status, author, startDate, endDate)
    });
  };

  useEffect(() => {
    getAllUser();
  }, []);

  useEffect(() => {
    const queryParam = props.location?.search;
    const params = new URLSearchParams(queryParam);
    const authorParam = params.get("author");
    const statusParam = params.get("status");
    const startDateParam = params.get("startDate");
    const endDateParam = params.get("endDate");
    const pageParam = params.get("page");
    const countParam = params.get("count");
    if (pageParam) {
      setPage(parseInt(pageParam) - 1);
    }
    if (countParam) {
      setRowsPerPage(parseInt(countParam));
    }
    if (statusParam) {
      setStatus(statusParam);
    }
    if (authorParam) {
      setAuthor(authorParam);
    }
    if (startDateParam) {
      setStartDate(startDateParam);
    }
    if (endDateParam) {
      setEndDate(endDateParam);
    }
    getAllData({
      page: parseInt(pageParam) - 1,
      count: parseInt(countParam),
      author: authorParam,
      status: statusParam,
      startDate: startDateParam,
      endDate: endDateParam,
      keyword: keyword
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location?.search])

  const handleSearch = e => {
    e.preventDefault();
    setPage(0);
    setKeyword(e.target.value);
    getAllData({
      page: page,
      count: rowsPerPage,
      author: author,
      status: status,
      startDate: startDate,
      endDate: endDate,
      keyword: e.target.value
    });
  }

  const handleChangePage = (e, currentPage) => {
    setSelectAll(false);
    props.history.push({
      pathname: "/kh-en-words",
      search: endPoint(currentPage + 1, rowsPerPage, status, author, startDate, endDate)
    });
  };


  const getAllData = ({page, count, status, author, startDate, endDate, keyword }) => {
    setIsLoading(true);
    const token = window.sessionStorage.getItem(c.sessionKey);
    let query = `?token=${token}&page=${page + 1}&count=${count}`;
    if (keyword) {
      query += `&search=${keyword}`;
    }
    if (startDate) {
      query += `&start_date=${startDate}`;
    }
    if (endDate) {
      query += `&end_date=${endDate}`;
    }
    if (status !== 'all') {
      query += `&status=${status}`
    }
    if (author) {
      query += `&author=${author}`
    }


    let checkArray = [];
    getData(
      c.endPoint.khEnWord + query
    )
      .then(data => {
        if (data.code === 1) {
          for (let i = 0; i < data.data.length; i++) {
            checkArray.push(false);
          }
          setCheckWord(checkArray);
          setWords(data.data);
          setIsLoading(false);
          setTotal(data.pagination.total_items);
        } else {
          console.log('error');
          setIsLoading(false);
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  const handleImageRequest = word => {
    setOpenImgRequest(true);
    const token = window.sessionStorage.getItem(c.sessionKey);
    getData(
      c.endPoint.imageRequest +
      '/by_word?word_id=' +
      word._id +
      '&type=KE&token=' +
      token
    ).then(data => {
      console.log('Image-Request', data);
      if (data.code === 1) {
        setImageRequest(data.data);
      } else {
        console.log('error');
      }
    });
  };

  const handleCommentRequest = word => {
    setOpenCmtRequest(true);
    const token = window.sessionStorage.getItem(c.sessionKey);
    getData(
      c.endPoint.wordComment +
      '/by_word?word_id=' +
      word._id +
      '&type=KE&token=' +
      token
    ).then(data => {
      console.log('comment-request', data);
      if (data.code === 1) {
        setCommentRequest(data.data);
      } else {
        console.log('error');
      }
    });
  };

  const handleRefresh = () => {
    getAllData({
      page: page,
      count: rowsPerPage,
      author: author,
      status: status,
      startDate: startDate,
      endDate: endDate,
      keyword: keyword
    });
  };

  const handleCloseImgRequestModal = () => {
    setOpenImgRequest(false);
    setImageRequest(null);
  };

  const handleCloseCmtRequestModal = () => {
    setOpenCmtRequest(false);
  };

  const handleAddUser = () => {
    props.history.push({
      pathname: '/kh-en-word',
      search: endPoint((page + 1), rowsPerPage, status, author, startDate, endDate)
    });
  };
  const handleEditWord = (event, word) => {
    props.history.push({
      pathname: '/kh-en-word',
      state: { data: word },
      search: endPoint((page + 1), rowsPerPage, status, author, startDate, endDate)
    });
  };
  const handleClickOpenDeleteModal = (event, u) => {
    setOpenDeleteModal(true);
    setDeletedWord(u);
  };
  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handleClickOpenViewModal = (event, word) => {
    setOpenViewModal(true);
    setViewWord(word);
  };
  const handleCloseViewModal = () => {
    setOpenViewModal(false);
  };

  const handleCloseImageRequestModal = () => {
    setOpenImageRequestModal(false);
  };

  const onConfirmDelete = () => {
    setOpenDeleteModal(false);
    if (deletedWord) {
      deleteData(
        c.endPoint.khEnWord +
        '/' +
        deletedWord._id +
        '?token=' +
        window.sessionStorage.getItem(c.sessionKey)
      )
        .then(response => {
          if (response.code === 1) {
            Swal.fire({
              icon: 'success',
              title: 'លុបទិន្នន័យបានជោគជ័យ',
            });
            handleRefresh();
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Opps, something went wrong.',
              html:
                '<div style="font-family: Battambang-Regular; font-size: 14 "> <p>' +
                response.message +
                '</p></div>',
            });
          }
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            title: 'Opps, something went wrong.',
            html:
              '<div style="font-family: Battambang-Regular; font-size: 14 "> <p>' +
              err +
              '</p></div>',
          });
        });
    }
  };

  const handlePublishWord = (event, word) => {
    const token = window.sessionStorage.getItem(c.sessionKey);
    putData(c.endPoint.khEnWord + '/' + word._id + '?token=' + token, {
      status: 'published',
    })
      .then(value => {
        if (value.code === 1) {
          getAllData({
            page: page,
            count: rowsPerPage,
            author: author,
            status: status,
            startDate: startDate,
            endDate: endDate,
            keyword: keyword
          });
        } else {
          console.log('error');
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const handleSelectAll = event => {
    if (selectAll) {
      setSelectAll(false);
      words.forEach((word, index) => {
        if (word.status === 'drafted') {
          checkWord[index] = false;
          setWordIds([]);
        }
      });
    } else {
      setSelectAll(true);
      let checkArray = [];
      if (wordIds.length !== 0) {
        for (let i = 0; i < checkWord.length; i++) {
          checkArray.push(false);
        }
        setCheckWord(checkArray);
        setWordIds([]);
      }
      let tempWordIds = [];
      let tempCheckWord = [...checkWord];
      words.forEach((word, index) => {
        if (word.status === 'drafted') {
          tempCheckWord[index] = true;
          tempWordIds.push(word._id);
        }
      });
      setCheckWord(tempCheckWord);
      setWordIds(tempWordIds);
    }
  };

  const handleFilterByStatus = (status) => {
      let endPoint = '?page=1&count=' + rowsPerPage;
      if (status === "all") {
        setStatus('');
        endPoint += "&status=all";
      } else {
        setStatus(status);
        endPoint += "&status=" + status;
      }
      
      if (author !== '' && author !== undefined && author !== null) {
        endPoint += '&author=' + author
      }
  
      if (endDate !== '' && startDate) {
        endPoint += '&startDate=' + startDate + '&endDate=' + endDate
      }
  
      props.history.push({
        pathname: "/kh-en-words",
        search: endPoint
      });
  }

  const handleFilterByDate = (start, end) => {
    if (handleValidation()) {
      setStartDate(start);
      setEndDate(end);
      props.history.push({
        pathname: "/kh-en-words",
        search: endPoint(1, rowsPerPage, status, author, start, end)
      });
    }
  }

  const handleFilterByAuthor = (author) => {
    if (author.label !== "Author")
    setAuthor(author.value);  

    props.history.push({
      pathname: "/kh-en-words",
      search: endPoint(1, rowsPerPage, status, author.value, startDate, endDate)
    });
  }

  const handleCheckWord = (event, index, word) => {
    let obj = [...checkWord];
    if (obj[index] === true) {
      obj[index] = false;
      wordIds.forEach((element, idIndex) => {
        if (word._id === element) {
          wordIds.splice(idIndex, 1);
        }
      });
    } else {
      obj[index] = true;
      wordIds.push(word._id);
    }
    setCheckWord(obj);
  };

  const handleBatchPublish = () => {
    setIsLoading(true);
    let obj = {
      ids: wordIds,
    };
    const token = window.sessionStorage.getItem(c.sessionKey);
    postData(c.endPoint.khEnWord + '/batch-publish?token=' + token, obj)
      .then(value => {
        setIsLoading(false);
        if (value.code === 1) {
          setWordIds([]);
          setSelectAll(false);
          getAllData({
            page: page,
            count: rowsPerPage,
            author: author,
            status: status,
            startDate: startDate,
            endDate: endDate,
            keyword: keyword
          });
          Swal.fire({
            icon: 'success',
            text: 'Publish words success',
          });
        } else if (value.code === 0) {
          Swal.fire({
            icon: 'warning',
            text: 'ពាក្យនេះមានរួចហើយ​​ សូមបញ្ចូលពាក្យថ្មី',
          });
        }
      })
      .catch(err => {
        setIsLoading(false);
        alert('Something went wrong');
        console.log(err);
      });
  };

  function endPoint(page, count, status, author, startDate, endDate) {
    let endPoint = "?page=" + page + "&count=" + count + '&status=' + status;
    if (author !== '' && author !== undefined && author !== null) {
      endPoint += '&author=' + author
    }

    if (endDate !== '' && startDate) {
      endPoint += '&startDate=' + startDate + '&endDate=' + endDate
    }
    return endPoint;
  }

  function handleValidation() {
    let formIsValid = true;
   
    return formIsValid;
  }

  const handleSpecificPage = () => {
    if (parseInt(goToPage) > 0) {
      props.history.push({
        pathname: "/kh-en-words",
        search: endPoint(parseInt(goToPage), rowsPerPage, status, author, startDate, endDate)
      });
    }
  }

  const _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (parseInt(goToPage) > 0) {
        props.history.push({
          pathname: "/kh-en-words",
          search: endPoint(parseInt(goToPage), rowsPerPage, status, author, startDate, endDate)
        });
      }
    }
  }

  const handleChangeSpecificPage = event => {
    setGoToPage(event.target.value);
  }

  const getAllUser = () => {
    const token = window.sessionStorage.getItem(c.sessionKey);
    let tempUser = [];
    getData(c.endPoint.user + '?token=' + token)
      .then(data => {
        if (data.code === 1) {
          data.data.forEach(data => {
            tempUser.push({
              value: data._id, 
              label: data.first_name + data.last_name,
            })
          });
          setUsers(tempUser)
        } else if (data.code === 0) {
          Swal.fire({
            icon: 'error',
            title: 'Opps, something went wrong.',
            html:
              '<div style="font-family: Battambang-Regular; font-size: 14 "> <p>' +
              data.message +
              '</p></div>',
          });
        }
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          title: 'Opps, something went wrong.',
          html:
            '<div style="font-family: Battambang-Regular; font-size: 14 "> <p>' +
            err +
            '</p></div>',
        });
      });
  }

  let temArr = [];
  const renderImages = (imageRequests) => {

    imageRequests.forEach(element => {
      for (let i = 0; i < element.images.length; i++) {
        temArr.push(element.images[i]);
      }
    });

    const taskRows = temArr.reduce(function (rows, task, i) {
      let nbColofRow = 2;

      const rowIndex = Math.floor(i / nbColofRow);

      if (i % nbColofRow === 0) {
        rows[rowIndex] = [task];
      } else {
        rows[rowIndex].push(task);
      }
      return rows;
    }, []);

    return taskRows.map(renderTasksRow);
  };

  function renderTasksRow(rowTasks, index) {
    return <Grid container key={index}>{rowTasks.map(renderTask)}</Grid>;
  }

  function renderTask(item, index) {
    return (
      <Grid key={index} item md={6} style={{ display: 'flex', justifyContent: 'center' }}>
        <div style={{ padding: 8 }}>
          <img
            src={c.endPoint.file + '/' + item}
            width="150"
            height="150"
            alt={index}
          />
        </div>
      </Grid>
    );
  }

  return (
    <div className={classes.root}>
      {isLoading && (
        <div className={classes.loading}>
          <SyncLoader size={15} color={c.color.main} loading={isLoading} />
        </div>
      )}
      <div>
        <ProductsToolbar
          {...props}
          handleAddUser={handleAddUser}
          handleSearch={handleSearch}
          handleRefresh={handleRefresh}
          handleBatchPublish={handleBatchPublish}
          handleFilterByStatus={handleFilterByStatus}
          wordIds={wordIds}
          handleFilterByDate={handleFilterByDate}
          handleFilterByAuthor={handleFilterByAuthor}
          users={users}
        />
        <div className={classes.content}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12}>
              <Paper className={classes.paper}>
                <PerfectScrollbar>
                  <div className={classes.inner}>
                    <Table>
                      <TableHead>
                        <TableRow className={classes.tableTitle}>
                          <TableCell className={classes.engFontFamily}>
                            No
                          </TableCell>
                          <TableCell className={classes.engFontFamily}>
                          {
                              (words.length > 0 && !isLoading) &&
                            <div>
                              <GreenCheckbox
                                checked={selectAll}
                                onChange={e => handleSelectAll(e)}
                                name="selectall"
                              />
                            </div>
}
                          </TableCell>
                          <TableCell className={classes.engFontFamily}>
                            Word
                          </TableCell>
                          <TableCell className={classes.engFontFamily}>
                            Author
                          </TableCell>
                          <TableCell style={{ textAlign: 'center' }}>
                            <Typography className={classes.engFontFamily}>
                              Image
                            </Typography>
                            <Typography className={classes.engFontFamily}>
                              Request
                            </Typography>
                          </TableCell>
                          <TableCell className={classes.engFontFamily}>
                            Comments
                          </TableCell>
                          <TableCell className={classes.engFontFamily}>
                            Date
                          </TableCell>
                          <TableCell className={classes.engFontFamily}>
                            Status
                          </TableCell>
                          <TableCell className={classes.engFontFamily}>
                            Action
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {words?.length ?
                          words.map((word, index) => (
                            <TableRow
                              className={classes.tableRow}
                              hover
                              key={index}
                            >
                              <TableCell className={classes.tableContent}>
                                {(page * rowsPerPage) + (index + 1)}
                              </TableCell>
                              <TableCell>
                              {(word?.status?.toUpperCase() === 'DRAFTED' && !isLoading) && (
                                  <div>
                                    <GreenCheckbox
                                      checked={checkWord[index]}
                                      onChange={e =>
                                        handleCheckWord(e, index, word)
                                      }
                                      name={'check' + index}
                                    />
                                  </div>
                                )}
                              </TableCell>
                              <TableCell>
                                <Typography
                                  variant="body1"
                                  className={classes.tableContent}
                                >
                                  {word.word}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  variant="body1"
                                  className={`${classes.tableContent} ${classes.enFont}`}
                                >
                                  {word.author?.first_name}{' '}
                                  {word.author?.last_name}
                                </Typography>
                              </TableCell>
                              <TableCell style={{ textAlign: 'center' }}>
                                <div onClick={() => handleImageRequest(word)}>
                                  <Badge
                                    badgeContent={word.total_image_request}
                                    color="secondary"
                                  >
                                    <ImageIcon />
                                  </Badge>
                                </div>
                              </TableCell>
                              <TableCell style={{ textAlign: 'center' }}>
                                <div onClick={() => handleCommentRequest(word)}>
                                  <Badge
                                    badgeContent={word.total_comment}
                                    color="secondary"
                                  >
                                    <CommentIcon />
                                  </Badge>
                                </div>
                              </TableCell>
                              <TableCell className={classes.tableContent}>
                                {moment(word.updatedAt).format('DD/MM/YYYY')}
                              </TableCell>
                              <TableCell
                                className={`${classes.tableContent} ${classes.enFont}`}
                              >
                                {word.status?.toUpperCase()}
                              </TableCell>
                              <TableCell>
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  size="small"
                                  className={`${classes.iconButton} ${classes.view}`}
                                  onClick={event =>
                                    handleClickOpenViewModal(event, word)
                                  }
                                >
                                  View
                                </Button>
                                {(user.role === c.userRole.admin ||
                                  user._id === word.author._id) && (
                                    <Button
                                      variant="contained"
                                      color="secondary"
                                      size="small"
                                      className={`${classes.iconButton} ${classes.edit}`}
                                      onClick={event =>
                                        handleEditWord(event, word)
                                      }
                                    >
                                      Edit
                                    </Button>
                                  )}
                                {word.status?.toUpperCase() === 'DRAFTED' &&
                                  (user.role === c.userRole.admin ||
                                    user._id === word.author._id) && (
                                    <Button
                                      variant="contained"
                                      color="secondary"
                                      size="small"
                                      className={`${classes.iconButton} ${classes.publish}`}
                                      onClick={event =>
                                        handlePublishWord(event, word)
                                      }
                                    >
                                      Publish
                                    </Button>
                                  )}
                                {(user.role === c.userRole.admin ||
                                  user._id === word.author._id) && (
                                    <Button
                                      variant="contained"
                                      color="secondary"
                                      size="small"
                                      className={`${classes.iconButton} ${classes.delete}`}
                                      onClick={event =>
                                        handleClickOpenDeleteModal(event, word)
                                      }
                                    >
                                      Delete
                                    </Button>
                                  )}
                              </TableCell>
                            </TableRow>
                          ))
                          :
                          (words?.length === 0 && !isLoading) &&
                          <TableRow>
                            <TableCell
                              colSpan={9}
                              style={{ textAlign: 'center' }}
                            >
                              No Data
														</TableCell>
                          </TableRow>

                        }
                      </TableBody>
                    </Table>
                  </div>
                </PerfectScrollbar>

                {
                  words?.length > 0 &&
                  <CardActions className={classes.actions}>
                    <Typography className={classes.textField}>Go to page</Typography>
                    <TextField
                      margin="dense"
                      name="goToPage"
                      value={goToPage}
                      variant="outlined"
                      className={classes.textField}
                      onChange={handleChangeSpecificPage}
                      onKeyDown={_handleKeyDown}
                      InputProps={{
                        className: classes.input
                      }}
                    />
                    <Button className={classes.goButton} onClick={handleSpecificPage}>
                      Go 
                    </Button>
                    <TablePagination
                      component="div"
                      count={total}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleRowsPerPageChange}
                      labelRowsPerPage="ចំនួនទិន្នន័យក្នុងមួយទំព័រ"
                      page={page}
                      rowsPerPage={rowsPerPage}
                      rowsPerPageOptions={[10, 50, 100]}
                    />
                  </CardActions>
                }
              </Paper>
            </Grid>
          </Grid>
        </div>
      </div>

      <div>
        <Dialog
          open={openDeleteModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleCloseDeleteModal}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">បញ្ជាក់</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              តើអ្នកពិតជាចង់លុបពាក្យ
              {deletedWord ? ' ' + deletedWord.word + ' ' : ''}
              នេះមែនឬទេ ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseDeleteModal}
              color="primary"
              variant="outlined"
              className={classes.btn}
            >
              Cancel
            </Button>
            <Button
              onClick={onConfirmDelete}
              variant="contained"
              color="secondary"
              className={classes.btn}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </div>

      <div>
        <Dialog
          open={openViewModal}
          TransitionComponent={Transition}
          keepMounted
          fullWidth={true}
          onClose={handleCloseViewModal}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            <Typography className={classes.detail}>View Detail</Typography>
          </DialogTitle>
          <DialogContent>
            {viewWord && (
              <div>
                <div className={classes.row} style={{ alignItems: 'flex-end' }}>
                  <p className={classes.word}>{viewWord.word}</p>
                </div>
                {
                  viewWord.phonetic.trim() !== '' &&
                  <p className={classes.subTitle}>[{viewWord.phonetic}]</p>
                }
                <Divider />
                <div className={classes.meaning} style={{ width: '100%' }}>
                  {viewWord.definitions.map((item, index) => {
                    return (
                      <div key={index}>
                        <p className={classes.partOfSpeech}>
                          {item.part_of_speech}
                        </p>
                        {item.meanings.map((mean, meanIdex) => {
                          return (
                            <div key={meanIdex}>
                              <div className={classes.meaning}>
                                <div>
                                  <p className={classes.number}>
                                    {meanIdex + 1}
                                  </p>
                                  <p className={classes.meaningText}>
                                    {mean.meaning}
                                  </p>
                                </div>
                                {mean.examples.map((example, Exindex) => {
                                  return (
                                    <div
                                      className={classes.meaning}
                                      key={Exindex}
                                    >
                                      <p className={classes.exampleText}>
                                        {example}
                                      </p>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
                <div style={{ width: '100%' }}>
                  {viewWord.images.length !== 0 && (
                    <Grid container>
                      {viewWord.images.map((item, index) => {
                        return (
                          <Box
                            border={1}
                            key={index}
                            borderColor="#cfd8dc"
                            style={{ padding: 5, marginLeft: 5, marginTop: 5 }}
                          >
                            <Grid item xs={12} sm={6} md={6}>
                              <img
                                src={c.endPoint.images + '/' + item}
                                width="120"
                                height="120"
                                alt={'Image' + index}
                              />
                            </Grid>
                          </Box>
                        );
                      })}
                    </Grid>
                  )}
                </div>
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseViewModal}
              color="primary"
              variant="outlined"
              className={classes.btn}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>

      <div>
        <Dialog
          open={openImageRequestModal}
          TransitionComponent={Transition}
          keepMounted
          fullWidth={true}
          onClose={handleCloseImageRequestModal}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            <Typography className={classes.detail}>View Detail</Typography>
          </DialogTitle>
          <DialogContent>
            {viewWord && (
              <div>
                <div style={{ width: '100%' }}>
                  {viewWord.images.length !== 0 && (
                    <Grid container>
                      {viewWord.images.map((item, index) => {
                        return (
                          <Box
                            border={1}
                            key={index}
                            borderColor="#cfd8dc"
                            style={{ padding: 5, marginLeft: 5, marginTop: 5 }}
                          >
                            <Grid item xs={12} sm={6} md={6}>
                              <img
                                src={c.endPoint.images + '/' + item}
                                width="120"
                                height="120"
                                alt={'Image' + index}
                              />
                            </Grid>
                          </Box>
                        );
                      })}
                    </Grid>
                  )}
                </div>
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseImageRequestModal}
              color="primary"
              variant="outlined"
              className={classes.btn}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <div>
        {imageRequest && (
          <Dialog
            open={openImgRequest}
            fullWidth={true}
            onClose={handleCloseImgRequestModal}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle>
              <Typography className={classes.detail}>Image Request</Typography>
            </DialogTitle>
            <DialogContent style={{ padding: '0px 80px 0px 80px' }}>
            {imageRequest.length > 0 ? (
                renderImages(imageRequest)

              ) : (
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <p className={classes.engFontFamily}>
                      This word don't have picture request.
                  </p>
                  </div>
                )}
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleCloseImgRequestModal}
                color="primary"
                variant="outlined"
                className={classes.btn}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </div>
      <div>
        {openCmtRequest && (
          <Dialog
            open={openCmtRequest}
            fullWidth={true}
            onClose={handleCloseCmtRequestModal}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle>
              <Typography className={classes.detail}>Comments</Typography>
            </DialogTitle>
            <DialogContent>
              <div className={classes.commentRequest}>
                {commentRequest.length > 0 ? (
                  commentRequest.map((cmt, index) => (
                    <div key={index} className={classes.comment}>
                      <div className={classes.userIcon}>
                        <AccountCircleIcon fontSize="large" />
                        <p className={classes.engFontFamily}>{cmt.fullname}</p>
                      </div>
                      <div className={classes.userIcon}>
                        <p className={classes.engFontFamily}>{cmt.comment}</p>
                      </div>
                    </div>
                  ))
                ) : (
                    <div>
                      <p className={classes.engFontFamily}>
                        This word don't have comment from user.
                    </p>
                    </div>
                  )}
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleCloseCmtRequestModal}
                color="primary"
                variant="outlined"
                className={classes.btn}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    setAuthorGlobal,
    setStatusGlobal,
    setPageGlobal,
    setRowInPerPageGlobal
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(KhEnWordList)
