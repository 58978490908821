import React, { useState, useEffect, useRef } from 'react';
import { makeStyles, withStyles } from '@material-ui/styles';
import { VersionToolbar, VersionTable } from './components';
import { Divider, Grid, Button, TextField, Box, Typography, Checkbox, FormControlLabel } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Swal from 'sweetalert2';
import { getData, postData } from '../../provider/data-loader';
import constant from '../../constant/constant';
import CustomSelect from 'components/select/custom-select';
import constants from '../../constant/constant';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  btn: {
    fontFamily: 'Calibri',
    fontSize: constant.fontSize.TextField,
    textTransform: 'capitalize'
  },
  text: {
    fontFamily: 'Calibri',
    fontSize: constant.fontSize.TextField
  },
  textField: {
    "& .MuiFormLabel-root": {
      fontSize: constant.fontSize.texfield,
      lineHeight: 1,
    },
  },
  action: {
    display: 'flex',
    flexFlow: 'row'
  }
}));

const adsType = [
  { value: 'long', label: 'long', },
  { value: 'rectangle', label: 'rectangle' },
  { value: 'square', label: 'square' }
];

const GreenCheckbox = withStyles({
  root: {
    color: 'black',
    '&$checked': {
      color: constants.color.green,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const Slider = props => {
  const classes = useStyles();
  const [slides, setSlides] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [imageLink, setImageLink] = useState({ preview: '', raw: '' });
  const [linkErrTxt, setLinkErrTxt] = useState('');
  const [imgErrTxt, setImgErrTxt] = useState('');
  const [check, setCheck] = useState(false);
  const [forMobile, setForMobile] = useState(1);
  const [forWeb, setForWeb] = useState(1);
  const [adsObj, setAdsObj] = useState({
    link: '',
    image: '',
  });
  let fileRef = useRef();
  const [selectedAdsType, setSelectedAdsType] = useState(
    {
      value: 'long',
      label: "long"
    });

  const uploadMultipleFiles = e => {
    setImageLink({
      preview: URL.createObjectURL(e.target.files[0]),
      raw: e.target.files[0]
    })
    setImgErrTxt('');
  };

  const onOpenAddUserModal = () => {
    setOpenModal(true);
  };

  const onCloseAddUserModal = () => {
    console.log('called onCloseAddUserModal');
    setOpenModal(false);
  };

  const handleChange = event => {
    setAdsObj({
      ...adsObj,
      [event.target.name]: event.target.value,
    });
  };

  const handleCheckWeb = (event) => {
    if (event.target.checked === true) {
      setForWeb(1)
    } else {
      setForWeb(0);
    }
  }

  const handleCheckMobile = (event) => {
    if (event.target.checked === true) {
      setForMobile(1);
    } else {
      setForMobile(0);
    }
  }

  function handleValidatFormData() {
    let formIsValid = true;
    if (imageLink.raw === '') {
      formIsValid = false;
      setLinkErrTxt('Please input your image')
    }

    if (adsObj.link === '') {
      formIsValid = false;
      setImgErrTxt('Please input your link');
    }
    return formIsValid;
  }

  const clear = () => {
    setAdsObj({
      link: '', 
    })
    setForMobile(1);
    setForWeb(1);
    setImageLink({ preview: [], raw: [] });
    setSelectedAdsType({
      value: 'long',
      label: 'long',
    });
  };

  const handleOnSave = () => {
    setCheck(true);
    var form_data = new FormData();
    if (handleValidatFormData()) {
      if (imageLink.raw !== '') {
        form_data.append('image', imageLink.raw);
      }
      form_data.append('link', adsObj.link);
      form_data.append('type', selectedAdsType.value);
      form_data.append('for_mobile', forMobile);
      form_data.append('for_web', forWeb);

      const token = window.sessionStorage.getItem(constant.sessionKey);
      postData(constant.endPoint.advertisements + '?token=' + token, form_data)
        .then(response => {
          if (response.code === 1) {
            setOpenModal(false);
            Swal.fire({
              icon: 'success',
              title: 'Upload Success',
            });
            handleRefresh();
            clear();
            setCheck(false);
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Opps, something went wrong.',
              html:
                '<div style="font-family: Battambang-Regular; font-size: 14 "> <p>' +
                response.message +
                '</p></div>',
            });
          }
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            title: 'Opps, something went wrong.',
            html:
              '<div style="font-family: Battambang-Regular; font-size: 14 "> <p>' +
              err +
              '</p></div>',
          });
        });
    }
  };

  function getSlides() {
    const token = window.sessionStorage.getItem(constant.sessionKey);
    getData(constant.endPoint.advertisements + '?token=' + token).then(data => {
      console.log("data", data.data)
      if (data.code === 1) {
        setSlides(data.data);
      } else if (data.code === 0) {
        console.log('error clients');
      } else {
        console.log('error server');
      }
    });
  }
  useEffect(() => {
    getSlides();
  }, []);

  const handleRefresh = e => {
    getSlides();
  };

  const handleChangeValue = (option) => {
    setSelectedAdsType({
      value: option.value,
      label: option.label,
    });
  }

  return (
    <div className={classes.root}>
      <VersionToolbar
        handleRefresh={handleRefresh}
        handleOpenAddUserModal={onOpenAddUserModal}
      />
      <div className={classes.content}>
        <VersionTable slides={slides} handleRefresh={handleRefresh} />
        <div>
          <Dialog
            open={openModal}
            onClose={onCloseAddUserModal}
            aria-labelledby="form-dialog-title"
            fullWidth={true}
          >
            <DialogTitle id="form-dialog-title">
              <Typography className={classes.text} style={{ fontSize: constant.fontSize.text }}>New Advertise</Typography>
            </DialogTitle>
            <Divider />
            <DialogContent style={{ paddingBottom: 100 }}>
              <Grid container spacing={1}>
                <Grid item md={3} xs={3} sm={3} lg={3} xl={3} style={{ display: 'flex', alignItems: 'center' }}>
                  <p className={classes.text}>Link</p>
                </Grid>
                <Grid item md={9} xs={9} sm={9} lg={9} xl={9}>
                  <TextField
                    error={adsObj.link === '' && check ? true : false}
                    helperText={adsObj.link === '' && check ? linkErrTxt : null}
                    fullWidth
                    label="Link"
                    name="link"
                    onChange={handleChange}
                    value={adsObj.link}
                    variant="outlined"
                    className={classes.textField}
                  />
                </Grid>
                <Grid item md={3} xs={3} sm={3} lg={3} xl={3} style={{ display: 'flex', alignItems: 'center' }}>
                  <p className={classes.text}>type</p>
                </Grid>
                <Grid item md={9} xs={9} sm={9} lg={9} xl={9}>
                  <div style={{ float: 'left', width: '100%' }}>
                    <CustomSelect
                      options={adsType}
                      selectedOption={selectedAdsType}
                      handleChangeValue={handleChangeValue} />
                  </div>
                </Grid>
                <Grid item md={3} xs={3} sm={3} lg={3} xl={3} style={{ display: 'flex', alignItems: 'center' }}>
                  <p className={classes.text}>Option</p>
                </Grid>
                <Grid item md={9} xs={9} sm={9} lg={9} xl={9}>
                  <FormControlLabel
                    control={<GreenCheckbox checked={forMobile === 1 ? true : false} onChange={event => handleCheckMobile(event)} name="forMobile" />}
                    label={<p className={classes.text}>For Mobile</p>}
                  />
                   <FormControlLabel
                    control={<GreenCheckbox checked={forWeb === 1 ? true : false} onChange={event => handleCheckWeb(event)} name="forWeb" />}
                    label={<p className={classes.text}>For Web</p>}
                  />
                </Grid>
                <Grid item md={3} xs={3} sm={3} lg={3} xl={3}>
                  <p className={classes.text}>Image</p>
                </Grid>
                <Grid item md={9} xs={9} sm={9} lg={9} xl={9}>
                  <Button
                    color="primary"
                    variant="outlined"
                    style={{
                      fontFamily: constant.fontFamily.Khmer_Battambong_Regular,
                      textTransform: 'capitalize'
                    }}
                    onClick={() => fileRef.current.click()}
                  >
                    Choose FIle
                </Button>
                  <input
                    ref={fileRef}
                    type="file"
                    style={{ display: 'none' }}
                    onChange={uploadMultipleFiles}
                  />
                  <div style={{ marginLeft: 16, fontSize: 12, color: 'red' }}>
                    {adsObj.image === '' && check ? imgErrTxt : ''}
                  </div>

                  {imageLink.preview !== '' && (
                    <div>
                      <div style={{ marginTop: 10 }}>
                        <Box
                          border={1}
                          borderColor="#cfd8dc"
                          style={{ padding: 5 }}
                        >
                          <img
                            src={imageLink.preview}
                            style={{ width: 200 }}
                            alt={imageLink.preview}
                          />
                        </Box>
                      </div>
                    </div>
                  )}
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogAction}>
              <Button
                onClick={onCloseAddUserModal}
                color="default"
                variant="outlined"
                className={classes.btn}
              >
                Cancel
              </Button>
              <Button
                onClick={handleOnSave}
                color="primary"
                variant="contained"
                className={classes.btn}
              >
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </div >
  );
};

export default Slider
