
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, Typography, makeStyles } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import c from '../../../../constant/constant';

const useStyles = makeStyles((theme) => ({
	text: {
		color: 'white',
		lineHeight: 2,
		padding: 4,
		fontFamily: `Calibri, ${c.fontFamily.Khmer_Battambong_Regular}`,
	},
	notificationPoint: {
		justifyContent: 'center',
		alignItems: 'center',
		display: 'flex',
		backgroundColor: c.color.main,
		marginLeft: 95,
		marginTop: -10
	},
	notificationConent: {
		backgroundColor: c.color.darkMain,
		width: '320px !important',
		padding: 20,
		boxShadow: '0px 0px 5px #00000029',
	},
	menu: {
		borderLeft: '8px solid transparent',
		borderRight: '8px solid transparent',
		borderTop: '12px solid #ffa500',
		alignSelf: 'center',
		display: 'flex',
	},
}));

const StyledMenu = withStyles({
	paper: {
		background: 'transparent',
	},
})((props) => (
	<Menu
		elevation={0}
		getContentAnchorEl={null}
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'center',
		}}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'center',
		}}
		{...props}
	/>
));

const NoteDialog = (props) => {
	const { openNoteDialog, handleClose } = props;
	const classes = useStyles()

	return (
		<StyledMenu
			id="customized-menu"
			anchorEl={openNoteDialog}
			keepMounted
			open={Boolean(openNoteDialog)}
			onClose={handleClose}
			autoFocus={false}
		>
			<div className={classes.notificationPoint}>
				<div className={classes.menu}>
				</div>
			</div>
			<div className={classes.notificationConent}>
				<Typography className={classes.text}>
					KhmerPedia ត្រូវបានចងក្រងឡើងដោយប្រជុំវចនានុក្រមបច្ចេកទេស និងពិសេសៗជាច្រើន ដោយយកវចនានុក្រមសម្តេចព្រះសង្ឃរាជ ជួន ណាត ជាគោល ក្នុងគោលដៅប្រមូលផ្តុំ អភិរក្ស និងអភិវឌ្ឍអក្សរសាស្ត្រខ្មែរសម្រាប់សាធារណជនប្រើប្រាស់ប្រកបដោយសុក្រឹតភាព។
				</Typography>
			</div>
		</StyledMenu>
	);
};

NoteDialog.propTypes = {
	className: PropTypes.string,
	openNoteDialog: PropTypes.object,
	handleClose: PropTypes.func,
};

export default NoteDialog;
