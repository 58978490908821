import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/styles';
import { Button, Grid, useMediaQuery } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { SearchInput } from 'components';
import RefreshIcon from '@material-ui/icons/Refresh';
import constants, { getUser } from 'constant/constant';
import PublishIcon from '@material-ui/icons/Publish';
import CustomSelect from '../../../../components/select/custom-select';
import Collapse from '@material-ui/core/Collapse';
import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
} from 'date-fns';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import moment from 'moment';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexFlow: 'column',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    marginRight: theme.spacing(1),
  },
  btnDateFilter: {
    backgroundColor: 'white',
    width: '200px',
    height: '56px',
    textTransform: 'capitalize',
    display: 'flex',
    justifyContent: 'left',
    '&:hover': {
      backgroundColor: 'white'
    },
  },
  clearBtn: {
    textTransform: 'capitalize',
    backgroundColor: constants.color.yellow
  },
  button: {
    marginRight: '8px',
    backgroundColor: constants.color.green,
    color: 'white',
    textTransform: 'capitalize',
    fontFamily: 'Calibri',
    fontSize: constants.fontSize.placholder,
    '&:hover': {
      background: constants.color.darkgreen,
    },
  },
}));

const status = [
  { value: 'ALL', label: 'ALL' },
  { value: 'PUBLISHED', label: 'PUBLISHED' },
  { value: 'DRAFTED', label: 'DRAFTED' },
];

const ProductsToolbar = props => {
  const {
    className,
    handleBatchPublish,
    wordIds,
    handleFilterByStatus,
    handleFilterByDate,
    handleFilterByAuthor,
    users
  } = props;

  

  const user = JSON.parse(getUser());
  const classes = useStyles();
  const [selectedStatus, setSelectedStatus] = useState({
    value: 'ALL', label: 'ALL',
  });
  const [selectedAuthor, setSelectedAuthor] = useState({
    value: 'Author', label: 'Author',
  });
  const [openDateRange, setOpenDateRange] = useState(false);
  const [dateFilter, setDateFilter] = useState('Date range filter')
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));

  useEffect(() => {
    const queryParam = props.location?.search;
    const params = new URLSearchParams(queryParam);
    const authorParam = params.get("author");
    const statusParam = params.get("status");
    const startDateParam = params.get("startDate");
    const endDateParam = params.get("endDate");

    if (authorParam !== '' && authorParam !== undefined && authorParam !== null) {
      const author=  users?.find(item => item.value === authorParam);
      setSelectedAuthor({
        value: authorParam,
        label: author?.label
      });
    } else {
      setSelectedAuthor({
        value: 'Author', label: 'Author',
      })
    }

    if (statusParam) {
      setSelectedStatus({
        value: statusParam?.toUpperCase(),
        label: statusParam?.toUpperCase()
      });
    }
  
    if ((startDateParam !== '' && endDateParam !== '') && (startDateParam !== null && endDateParam !== null)) {
      setDateFilter(moment(startDateParam).format('YYYY-MM-DD') + " - " + moment(endDateParam).format('YYYY-MM-DD'));
    } else {
      setDateFilter('Date Rang Filter');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location?.search, users]);

  const handleChangeValue = (option) => {
    setSelectedStatus({
      value: option.value,
      label: option.label,
    });

    handleFilterByStatus(option.value.toLowerCase());
  }

  const handleChangeAuthor = (option) => {
    setSelectedAuthor({
      value: option.value,
      label: option.label,
    });
  
    handleFilterByAuthor(option);
  }

  const handleClickAway = () => {
    setOpenDateRange(false);
  };

  const handleOpenDateRange = () => {
    setOpenDateRange(!openDateRange);
  }

  const clearFilter = () => {
    console.log("Start Date", startDate + "End Date" + endDate);
  
    setSelectedAuthor({
      value: 'Author', 
      label: 'Author',
    });
    handleFilterByAuthor('');
    setSelectedStatus({
      value: 'ALL', 
      label: 'ALL',
    })
    handleFilterByStatus('');
    setState([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection'
      }

    ]);
    setDateFilter('Date Rang Filter');
    handleFilterByDate('', '');
    props.history.push({
      pathname: "/kh-kh-words",
      search: '?page=1&count=10&status=all'
    });
  }

  const handleSetDateRange = item => {
    
    console.log("Start-Date", moment(item.selection.startDate).startOf("day").toLocaleString());
    console.log("End-Date", moment(item.selection.endDate).endOf("day").toLocaleString());

    if (moment(new Date(item.selection.startDate)).isSame(moment(new Date(startOfDay(new Date()))))
      && moment(new Date(item.selection.endDate)).isSame(moment(new Date(endOfDay(new Date()))))
    ) {
      setDateFilter("Today");
    } else if (moment(new Date(item.selection.startDate)).isSame(moment(new Date(startOfDay(addDays(new Date(), -1)))))
      && moment(new Date(item.selection.endDate)).isSame(moment(new Date(endOfDay(addDays(new Date(), -1)))))
    ) {
      setDateFilter("Yesterday");
    } else if (moment(new Date(item.selection.startDate)).isSame(moment(new Date(startOfWeek(new Date()))))
      && moment(new Date(item.selection.endDate)).isSame(moment(new Date(endOfWeek(new Date()))))
    ) {
      setDateFilter("This Week");
    } else if (moment(new Date(item.selection.startDate)).isSame(moment(new Date(startOfWeek(addDays(new Date(), -7)))))
      && moment(new Date(item.selection.endDate)).isSame(moment(new Date(endOfWeek(addDays(new Date(), -7)))))
    ) {
      setDateFilter("Last Week");
    } else if (moment(new Date(item.selection.startDate)).isSame(moment(new Date(startOfMonth(new Date()))))
      && moment(new Date(item.selection.endDate)).isSame(moment(new Date(endOfMonth(new Date()))))
    ) {
      setDateFilter("This Month");
    } else if (moment(new Date(item.selection.startDate)).isSame(moment(new Date(startOfMonth(addMonths(new Date(), -1)))))
      && moment(new Date(item.selection.endDate)).isSame(moment(new Date(endOfMonth(addMonths(new Date(), -1)))))
    ) {
      setDateFilter("Last Month");
    } else {
      setDateFilter(moment(item.selection.startDate).format('YYYY-MM-DD') + " - " + moment(item.selection.endDate).format('YYYY-MM-DD'));
    }

    setState([item.selection]);
    setStartDate(item.selection.startDate.toISOString());
    setEndDate(item.selection.endDate.toISOString());
    
    handleFilterByDate(moment(item.selection.startDate).startOf("day").toISOString(), moment(item.selection.endDate).endOf("day").toISOString());
    if (!moment(new Date(item.selection.startDate)).isSame(moment(new Date(item.selection.endDate)))) {
      setOpenDateRange(!openDateRange);
    }
  }

  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.row}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={5} lg={5}>
            {
              (user.role === constants.userRole.admin || user.role === constants.userRole.editor) &&
              <div>
                <Button
                  variant="contained"
                  className={classes.button}
                  startIcon={<AddCircleOutlineIcon />}
                  onClick={props.handleAddUser}
                >
                  New
              </Button>
                <Button
                  color="default"
                  variant="outlined"
                  className={classes.button}
                  onClick={props.handleRefresh}
                  startIcon={<RefreshIcon />}
                >
                  Refresh
              </Button>
                <Button
                  color="default"
                  variant="outlined"
                  className={classes.button}
                  onClick={handleBatchPublish}
                  style={{ height: isXs && '43px' }}
                  disabled={wordIds.length > 0 ? false : true}
                  startIcon={<PublishIcon />}
                >
                  {!isXs && 'Publish'}  {wordIds.length > 0 && "(" + wordIds.length + ")"}
                </Button>
              </div>
            }
          </Grid>
          <Grid item xs={12} sm={12} md={7} lg={7} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <div style={{ width: '300px' }}>
              <SearchInput
                className={classes.searchInput}
                placeholder="ស្វែងរកពាក្យ"
                onChange={props.handleSearch}
              />
            </div>
          </Grid>
        </Grid>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Collapse in={true} style={{ width: '100%' }}>
          <Grid container style={{ border: '1px solid #27577B', padding: '16px', marginTop: '16px' }} spacing={1}>
            <Grid item >
              <ClickAwayListener onClickAway={handleClickAway}>
                <div>
                  <Button
                    variant="outlined"
                    disableRipple={true}
                    className={classes.btnDateFilter}
                    onClick={handleOpenDateRange}>
                    <span>{dateFilter}</span>
                  </Button>

                  {
                    openDateRange &&
                    <div style={{ position: 'absolute', zIndex: 999, boxShadow: '0px 0px 5px #00000029' }}>
                      <DateRangePicker
                        onChange={handleSetDateRange}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        months={2}
                        ranges={state}
                        direction="horizontal"
                        // inputRanges={[]}
                        // staticRanges={defaultStaticRanges}
                      />
                    </div>
                  }
                </div>
              </ClickAwayListener>
            </Grid>
            <Grid item >
              <div style={{ width: '200px', height: '46px' }}>
                <CustomSelect
                  options={users}
                  height='45px'
                  selectedOption={selectedAuthor}
                  borderRadius="5px"
                  borderColor="white"
                  handleChangeValue={handleChangeAuthor} />
              </div>
            </Grid>
            <Grid item>
              <div style={{ width: '200px', height: '46px' }}>
                <CustomSelect
                  options={status}
                  height='45px'
                  selectedOption={selectedStatus}
                  borderRadius="5px"
                  borderColor="white"
                  handleChangeValue={handleChangeValue} />
              </div>
            </Grid>
            <Grid item>
                <Button className={classes.button} onClick={clearFilter}>
                  clear 
                </Button>
            </Grid>
            {/* {
              isXs &&
              <Button
                variant="contained"
                className={classes.button}
                startIcon={<AddCircleOutlineIcon />}
                onClick={handleFilterByDate}
              >
                filter
                </Button>
            } */}
          </Grid>
        </Collapse>
      </div>
    </div >
  );
};

ProductsToolbar.propTypes = {
  className: PropTypes.string,
  handleAddUser: PropTypes.func,
  handleSearch: PropTypes.func,
  handleRefresh: PropTypes.func,
  handleBatchPublish: PropTypes.func,
  handleFilterByStatus: PropTypes.func,
  handleFilterByDate: PropTypes.func,
  handleChangeEndDate: PropTypes.func,
  handleChangeStartDate: PropTypes.func,
  handleFilterByAuthor: PropTypes.func,
  endDateErrMsg: PropTypes.string,
  startDateErrMsg: PropTypes.string,
  wordIds: PropTypes.array,
  users: PropTypes.array
};

export default ProductsToolbar
