import React, {useEffect} from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import { 
  setAuthorGlobal, 
  setStatusGlobal, 
  setPageGlobal, 
  setRowInPerPageGlobal ,
  setStartDateGlobal,
  setEndDateGlobal
} from 'views/User/actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

const RouteWithLayout = props => {
  const { layout: Layout, component: Component, ...rest } = props;

  useEffect(() => {
    setPageGlobal(0);
    setRowInPerPageGlobal(10);
    setStatusGlobal('');
    setAuthorGlobal('');
    setEndDateGlobal('');
    setStartDateGlobal('');
  }, [])

  return (
    <Route
      {...rest}
      render={matchProps => (
        <Layout>
          <Component {...matchProps} />
        </Layout>
      )}
    />
  );
};

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string
};

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    setAuthorGlobal,
    setStatusGlobal,
    setPageGlobal,
    setRowInPerPageGlobal,
    setStartDateGlobal,
    setEndDateGlobal,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(RouteWithLayout)
// export default RouteWithLayout;
