import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/styles';
import c from 'constant/constant';
import {
  Divider,
  List,
  ListItemText,
  ListItem,
  Box,
  Grid,
  IconButton,
  DialogActions,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Tooltip,
  Snackbar,
  useMediaQuery
} from '@material-ui/core';
import { getData, postData } from 'provider/data-loader';
import SyncLoader from "react-spinners/SyncLoader";
import ShareIcon from '@material-ui/icons/Share';
import EditIcon from '@material-ui/icons/Edit';
import CommentIcon from '@material-ui/icons/Comment';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import {
  FacebookShareButton,
  FacebookIcon,
  EmailIcon,
  EmailShareButton,
  TelegramIcon,
  TelegramShareButton
} from "react-share";
import Swal from 'sweetalert2';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getWordToSearch, clearWordToSearch, setKhKhWords } from '../../actions';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ImageRequestDialog from '../ImageRequestDialog/ImageRequestDialog';
import { wordService } from 'services/user';
import ImageDialog from '../../../../components/Dialog/image-dialog';
import InfiniteScroll from 'react-infinite-scroller';

const useStyles = makeStyles(theme => ({
  word: {
    fontSize: 30,
    fontWeight: 'bold',
    float: "left",
  },
  subTitle: {
    fontSize: c.fontSize.text,
    color: c.color.text,
    marginTop: 5,
    marginBottom: 5
  },
  partOfSpeech: {
    fontSize: c.fontSize.title,
    color: c.color.red,
    fontWeight: 'bold',
  },
  meaning: {
    paddingLeft: 20,
    paddingTop: 10,
    paddingBottom: 10,
    fontFamily: c.fontFamily.Khmer_Battambong_Regular,
  },
  number: {
    float: "left",
    minWidth: 30,
    color: c.color.text,
    fontSize: c.fontSize.meaningText,
  },
  example: {
    paddingLeft: 20,
    paddingTop: 10,
    color: c.color.text
  },
  row: {
    display: 'flex',
    flexFlow: 'row',
  },
  iconBlock: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-end',
  },
  iconWidth: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  meaningText: {
    fontSize: c.fontSize.meaningText
  },
  exampleText: {
    color: c.color.blue,
    fontSize: c.fontSize.text
  },
  shareButton: {
    display: 'flex',
    flexFlow: 'row',
    position: 'absolute',
    width: 340,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    paddingTop: 10
  },
  shareButtonIcon: {
    borderRadius: 50
  },
  input: {
    fontSize: c.fontSize.texfield,
    lineHeight: 1.5,
  },
  requestBtn: {
    fontFamily: c.fontFamily.Khmer_Battambong_Regular,
    backgroundColor: c.color.main,
  },
  itemCenter: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '16px',
    paddingBottom: '16px'
  },
  img: {
    '&:hover': {
      cursor: 'pointer'
    }
  }
}));

const Tab1 = ({ props, wordToSearch }) => {
  const [word, setWord] = useState(null);
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(true);
  const [openCmtModal, setOpenCmtModal] = useState(false);
  const [comment, setComment] = useState('');
  const [fullName, setFullName] = useState('');
  const [openShareBtn, setOpenShareBtn] = useState(false);
  const [state, setState] = React.useState({
    open: false,
    vertical: 'top',
    horizontal: 'center',
    message: 'copied'
  });
  const [allWords, setAllWords] = useState([]);
  const [bookMarkColor, setBookMarkColor] = useState(false);
  const [openImageRequest, setOpenImageRequest] = useState(false);

  const { newSearchWord, getWordToSearch, newSearchWordID, clearWordToSearch, setKhKhWords } = props;
  const [bookMarkWords, setBookMarkWords] = useState([]);
  const [checkSearchWordById, setCheckSearchWordById] = useState(false);
  const [openImgDialog, setOpenImgDialog] = useState(false);
  const [imageLink, setImageLink] = useState('');
  const [wordSuggestion, setWordSuggestion] = useState(null);
  const { vertical, horizontal, open, message } = state;
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [color, setColor] = useState('#546e7a');
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const [mainWord, setMainWord] = useState({
    word: '',
    phonetic: '',
    sounds: [],
    images: [],
    definitions: [
      {
        meanings: [
          {
            examples: [''],
            _id: '',
            meaning: '',
          },
        ],
        _id: '',
        part_of_speech: '',
      },
    ],
  });

  useEffect(() => {
    setBookMarkColor(false);
    setColor('#546e7a');
    setBookMarkWords(getAllBookMarkKhWords());
    if ((wordToSearch !== null && !checkSearchWordById) || newSearchWordID !== '') {
      getAllBookMarkKhWords().forEach(item => {
        setIsLoading(true);
        if (item.word === wordToSearch.word) {
          setBookMarkColor(true);
          setColor(c.color.yellow);
        }
      });
      console.log("wordToSearch", wordToSearch);
      getWord({ wordId: newSearchWordID, keyWord: wordToSearch.word });
    } else {
      setWord(null);
      getAllKhWords();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wordToSearch, newSearchWord, newSearchWordID]);

  const getWord = ({ wordId, keyWord }) => {
    if (wordId) {
      wordService.getWordById("KK", wordId).then(data => {
        setWord(data);
        setIsLoading(false);
      }).catch(err => {
        setIsLoading(false);
      })
    } else {
      wordService.getWordByKeyword('KK', keyWord).then(data => {
        setWord(data.found_word);
        setWordSuggestion(data.suggestions);
        setIsLoading(false)
      }).catch(err => {
        setIsLoading(false);
      })
    }
  }

  const handleListItem = (event, suggestValue) => {
    wordService.getWordById("KK", suggestValue._id).then(data => {
      setWord(data);
      setMainWord(data);
      setIsLoading(false);
      getWordToSearch(suggestValue);
    }).catch(err => {
      console.log(err);
      setIsLoading(false);
    });
  }

  function getAllBookMarkKhWords() {
    var values = [],
      keys = Object.keys(localStorage),
      i = keys.length;

    while (i--) {
      values.push(localStorage.getItem(keys[i]));
    }

    var oldItems = JSON.parse(localStorage.getItem(c.localStorageKey.WORDS)) || [];

    return oldItems;
  }

  const handleListWords = (targe, word) => {
    getWordToSearch(word);
    setCheckSearchWordById(true);
    wordService.getWordById("KK", word._id).then(data => {
      setIsLoading(false);
      setWord(data);
      setMainWord(data);
      bookMarkWords.forEach(item => {
        if (item === word.word) {
          setBookMarkColor(true);
          setColor(c.color.yellow);
        }
      });
    }).catch(err => {
      console.log(err);
      setIsLoading(false);
    });
  }

  function getAllKhWords() {
    getData(c.endPoint.khKhWord + `/web?page=1&count=10`).then(value => {
      setIsLoading(false);
      if (value.code === 1) {
        setKhKhWords(value.data);
      }
    }).catch(err => {
      console.log(err);
      setIsLoading(false);
    })
  }

  const handleBack = () => {
    setWordSuggestion(null);
    clearWordToSearch();
  }

  const onCommentChange = e => {
    setComment(e.target.value);
  };

  const onFullnameChange = e => {
    setFullName(e.target.value);
  };

  const onOpenCommentModal = (event, word) => {
    setMainWord(word);
    setOpenCmtModal(true)
  }

  const onBookMark = (event, item) => {
    if (bookMarkColor) {
      setBookMarkColor(false);
      setColor('#546e7a');
      var items = JSON.parse(localStorage.getItem(c.localStorageKey.WORDS));
      for (var i = 0; i < items.length; i++) {
        if (items[i].word === item.word) {
          items.splice(i, 1);
        }
      }
      window.localStorage.setItem(c.localStorageKey.WORDS, JSON.stringify(items));
    } else {
      var oldItems = JSON.parse(localStorage.getItem(c.localStorageKey.WORDS)) || [];
      setBookMarkColor(true)
      setColor(c.color.yellow);
      let obj = {
        type: "kh_kh",
        word: item.word,
      }
      oldItems.push(obj);
      window.localStorage.setItem(c.localStorageKey.WORDS, JSON.stringify(oldItems));
    }
  }

  const onCloseCmtModal = () => {
    setOpenCmtModal(false);
  };

  const onRequestChange = (event, word) => {
    props.history.push("/khkh_request_change", {
      state: { updateWord: word }
    })
  };

  const hanleOpenShareBtn = () => {
    if (openShareBtn) {
      setOpenShareBtn(false);
    } else {
      setOpenShareBtn(true);
    }
  }

  const onCommentHandle = () => {
    const item = {
      kh_kh_word: mainWord._id,
      comment: comment,
      fullname: fullName
    }
    postData(c.endPoint.wordComment, item).then(response => {
      if (response.code === 1) {
        setOpenCmtModal(false);
        Swal.fire({
          icon: 'success',
          title: 'ផ្តល់យោបល់ជោគជ័យ',
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Opps, something went wrong.',
          html:
            '<div style="font-family: Battambang-Regular; font-size: 14 "> <p>' +
            response.message +
            '</p></div>',
        });
      }
    }).catch(err => {
      Swal.fire({
        icon: 'error',
        title: 'Opps, something went wrong.',
      })
    })
  }

  const handleCopied = (newState, word) => () => {
    setState({ open: true, message: 'copied', ...newState })
  };

  function toShareString() {
    var result = `\n${word.word}\n `;
    var definition = "";
    word.definitions.forEach(item => {
      definition += `  ${item.part_of_speech}\n`;
      var meaning = "";
      item.meanings.map((m, index) => {
        return meaning += `  ${m.meaning}\n`;
      })
      definition += meaning;
    });
    result += definition;
    return result;
  }

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const handleOpenImageRequest = () => {
    setOpenImageRequest(true);
  }

  const handleCloseImageRequest = () => {
    setOpenImageRequest(false);
  }

  const handleOpenImageDialog = (imageLink) => {
    setOpenImgDialog(true)
    setImageLink(imageLink)
  }

  const handleCloseImageDialog = () => {
    setOpenImgDialog(false)
  }

  const infiniteScrollWord = () => {
    getData(c.endPoint.khKhWord + `/web?page=${page}&count=20`).then(value => {
      setIsLoading(false);
      if (value.code === 1) {
        if (allWords.length >= value.pagination.total_items) {
          setHasMore(false);
          return;
        }
        setTimeout(() => {
          setAllWords(allWords.concat(value.data));
          setPage(page + 1);
        }, 500);
      }
    }).catch(err => {
      console.log(err);
      setIsLoading(false);
    })
  }

  const loader = <div
    className="loader"
    key={page}
    style={{ marginLeft: 20, color: c.color.main, fontSize: 12, paddingBottom: 8 }}>
    កំពុងទាញយក ...
    </div>;

  return (
    <div>
      {isLoading ? (
        <div style={{ paddingTop: 200, textAlign: 'center' }}>
          <SyncLoader
            size={15}
            color={c.color.main}
            loading={isLoading}
          />
        </div>
      ) :
        (
          word === null && wordSuggestion === null ?
            <div style={{ overflow: 'auto'}}>
              <div style={{ fontSize: c.fontSize.text, paddingTop: 20 }}>ពាក្យខ្មែរ-ខ្មែរ</div>
              <InfiniteScroll
                pageStart={0}
                loadMore={infiniteScrollWord}
                hasMore={hasMore}
                threshold={20}
                loader={loader}>
                <List>
                  {allWords.map((word, index) => (
                    <div key={index}>
                      <ListItem button onClick={(e) => {
                        handleListWords(e, word);
                      }}>
                        <ListItemText primary={<p style={{ fontSize: c.fontSize.texfield }}>{word?.word}</p>} />
                      </ListItem>
                      <Divider />
                    </div>

                  ))}
                </List>
              </InfiniteScroll>
            </div>
            :
            (word !== null ? (
              <div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <IconButton onClick={handleBack} style={{ marginLeft: -16 }}>
                    <ArrowBackIcon />
                  </IconButton>
                  {
                    isXs &&
                    <div className={classes.iconBlock}>
                      {
                        openShareBtn &&
                        <div className={classes.shareButton}>
                          <FacebookShareButton
                            url={window.location.href}
                            quote={toShareString()}
                            className="Demo__some-network__share-button"
                          >
                            <FacebookIcon
                              size="2rem"
                              borderRadius="50"
                            />
                          </FacebookShareButton>
                          <div style={{ display: 'flex', flexFlow: 'column', marginTop: -20 }}>
                            <EmailShareButton

                              body={toShareString()}
                              subject={word?.word}
                            >
                              <EmailIcon
                                size="2rem"
                                borderRadius="50"
                              />
                            </EmailShareButton>
                            <TelegramShareButton
                              url={window.location.href}
                              title={toShareString()}
                            >
                              <TelegramIcon
                                size="2rem"
                                borderRadius="50"
                                style={{ transition: 'all 0.3s ease', backgroundColor: 'red' }}
                              />
                            </TelegramShareButton>
                          </div>
                        </div>
                      }
                      <div className={classes.iconWidth}>
                        <Tooltip title="ចែករំលែក">
                          <IconButton onClick={hanleOpenShareBtn}>
                            <ShareIcon style={{ fontSize: 30 }} />
                          </IconButton>
                        </Tooltip>
                      </div>
                      <div className={classes.iconWidth}>
                        <Tooltip title="ថតចំលង">
                          <CopyToClipboard text={toShareString()} options={{ asHtml: true }}
                            onCopy={handleCopied({ vertical: 'bottom', horizontal: 'center' }, word)}>
                            <IconButton>
                              <FileCopyIcon style={{ fontSize: 30 }} />
                            </IconButton>
                          </CopyToClipboard>
                        </Tooltip>
                      </div>
                      <div className={classes.iconWidth}>
                        <Tooltip title="កែប្រែនិយមន័យ">
                          <IconButton onClick={event => onRequestChange(event, word)}>
                            <EditIcon style={{ fontSize: 30 }} />
                          </IconButton>
                        </Tooltip>
                      </div>
                      <div className={classes.iconWidth}>
                        <Tooltip title="ផ្ដល់យោបល់">
                          <IconButton onClick={event => onOpenCommentModal(event, word)}>
                            <CommentIcon style={{ fontSize: 30 }} />
                          </IconButton>
                        </Tooltip>
                      </div>
                      <div className={classes.iconWidth}>
                        <Tooltip title="រក្សាទុក">
                          <IconButton onClick={event => onBookMark(event, word)}>
                            <BookmarkIcon style={{ fontSize: 30, color: color }} />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </div>
                  }
                </div>
                <div className={classes.row} style={{ alignItems: 'flex-end' }}>
                  <p className={classes.word}>{word.word}</p>
                  {
                    !isXs &&
                    <div className={classes.iconBlock}>
                      {
                        openShareBtn &&
                        <div className={classes.shareButton}>
                          <FacebookShareButton
                            url={window.location.href}
                            quote={toShareString()}
                            className="Demo__some-network__share-button"
                          >
                            <FacebookIcon
                              size="2rem"
                              borderRadius="50"
                            />
                          </FacebookShareButton>
                          <div style={{ display: 'flex', flexFlow: 'column', marginTop: -20 }}>
                            <EmailShareButton

                              body={toShareString()}
                              subject={word?.word}
                            >
                              <EmailIcon
                                size="2rem"
                                borderRadius="50"
                              />
                            </EmailShareButton>
                            <TelegramShareButton
                              url={window.location.href}
                              title={toShareString()}
                            >
                              <TelegramIcon
                                size="2rem"
                                borderRadius="50"
                              />
                            </TelegramShareButton>
                          </div>
                        </div>
                      }
                      <div className={classes.iconWidth}>
                        <Tooltip title="ចែករំលែក">
                          <IconButton onClick={hanleOpenShareBtn}>
                            <ShareIcon style={{ fontSize: 30 }} />
                          </IconButton>
                        </Tooltip>
                      </div>
                      <div className={classes.iconWidth}>
                        <Tooltip title="ថតចំលង">
                          <CopyToClipboard text={toShareString()} options={{ asHtml: true }}
                            onCopy={handleCopied({ vertical: 'bottom', horizontal: 'center' }, word)}>
                            <IconButton>
                              <FileCopyIcon style={{ fontSize: 30 }} />
                            </IconButton>
                          </CopyToClipboard>
                        </Tooltip>
                      </div>
                      <div className={classes.iconWidth}>
                        <Tooltip title="កែប្រែនិយមន័យ">
                          <IconButton onClick={event => onRequestChange(event, word)}>
                            <EditIcon style={{ fontSize: 30 }} />
                          </IconButton>
                        </Tooltip>
                      </div>
                      <div className={classes.iconWidth}>
                        <Tooltip title="ផ្ដល់យោបល់">
                          <IconButton onClick={event => onOpenCommentModal(event, word)}>
                            <CommentIcon style={{ fontSize: 30 }} />
                          </IconButton>
                        </Tooltip>
                      </div>
                      <div className={classes.iconWidth}>
                        <Tooltip title="រក្សាទុក">
                          <IconButton onClick={event => onBookMark(event, word)}>
                            <BookmarkIcon style={{ fontSize: 30, color: color }} />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </div>
                  }
                </div>
                {
                  word?.phonetic !== '' &&
                  <p className={classes.subTitle}>[{word?.phonetic}]</p>
                }
                <Divider />
                <div className={classes.meaning} style={{ width: '100%' }}>
                  {word?.definitions.map((item, index) => {
                    return (
                      <div key={index}>
                        <p className={classes.partOfSpeech}>{item.part_of_speech}</p>
                        {
                          item.meanings.map((mean, meanIdex) => {
                            return (
                              <div key={meanIdex}>
                                <div className={classes.meaning}>
                                  <div>
                                    {/* <p className={classes.number}>{meanIdex + 1}</p> */}
                                    <p className={classes.meaningText}>{mean.meaning}</p>
                                  </div>
                                  {
                                    mean.examples.map((example, Exindex) => {
                                      return (
                                        <div className={classes.meaning} key={Exindex}>
                                          <p className={classes.exampleText}>{example}</p>
                                        </div>
                                      )
                                    })
                                  }
                                </div>
                              </div>
                            )
                          })
                        }
                      </div>
                    )

                  })}
                </div>
                <div style={{ width: '100%' }}>
                  {
                    word?.images.length !== 0 &&
                    <Grid container>
                      {
                        word?.images.map((item, index) => {
                          return (
                            <Box border={1} key={index} borderColor='#cfd8dc' style={{ padding: 5, marginLeft: 5, marginTop: 5 }}>
                              <Grid item xs={12} sm={6} md={6}>
                                <img
                                  src={c.endPoint.images + "/" + item}
                                  width="120"
                                  height="120"
                                  alt={"Image" + index}
                                  className={classes.img}
                                  onClick={() => handleOpenImageDialog(c.endPoint.images + "/" + item)} />
                              </Grid>
                            </Box>
                          )
                        })
                      }
                    </Grid>
                  }
                </div>

                <div className={classes.itemCenter}>
                  <Button
                    color="primary"
                    variant="contained"
                    className={classes.requestBtn}
                    onClick={handleOpenImageRequest}
                  >
                    ស្នើបញ្ចូលរូបភាព
                    </Button>
                </div>
              </div>
            ) : (wordSuggestion?.length > 0 ?
              <div>
                <div style={{ fontSize: c.fontSize.text, paddingTop: 20 }}>ពាក្យស្រដៀងនឹងពាក្យ {wordToSearch?.word}</div>
                <List>
                  {
                    wordSuggestion?.map((suggest, suggestIndex) => {
                      return (
                        <div key={suggestIndex}>
                          <ListItem button onClick={(e) => {
                            handleListItem(e, suggest)
                          }}>
                            <ListItemText primary={suggest.word} />
                          </ListItem>
                          <Divider />
                        </div>
                      )
                    })
                  }
                </List>
              </div>
              :
              <div style={{ fontSize: c.fontSize.title, textAlign: 'center', paddingTop: 200 }}>
                <p>មិនមានទិន្នន័យ</p>
              </div>
              )
            )
        )
      }
      {
        openCmtModal === true && mainWord.word !== '' &&
        <div>
          <Dialog
            open={openCmtModal}
            onClose={onCloseCmtModal}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">ផ្តល់យោបល់លើពាក្យ</DialogTitle>
            <Divider />
            <DialogContent>
              {
                mainWord.word !== '' && (
                  <Grid container spacing={1}>
                    <Grid item md={12} xs={12}>
                      <TextField
                        fullWidth
                        label="យោបល់"
                        margin="dense"
                        name="comment"
                        required
                        value={comment}
                        variant="outlined"
                        onChange={e => {
                          onCommentChange(e);
                        }}
                      />
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <TextField
                        fullWidth
                        label="ឈ្មោះ"
                        margin="dense"
                        name="fullname"
                        required
                        value={fullName}
                        onChange={e => {
                          onFullnameChange(e);
                        }}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                )
              }
            </DialogContent>
            <DialogActions className={classes.dialogAction}>
              <Button
                onClick={onCloseCmtModal}
                color="default"
                variant="outlined"
              >
                បោះបង់
            </Button>
              <Button
                onClick={onCommentHandle}
                color="primary"
                variant="contained"
              >
                ផ្តល់យោបល់
            </Button>
            </DialogActions>
          </Dialog>
        </div>
      }

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        key={`${vertical},${horizontal}`}
        open={open}
        onClose={handleClose}
        message={message}
      />

      <ImageRequestDialog
        type={c.types.khkh}
        word={word}
        openImageRequest={openImageRequest}
        handleClose={handleCloseImageRequest}
      />
      <ImageDialog
        openImgDialog={openImgDialog}
        imageLink={imageLink}
        handleCloseImageDialog={handleCloseImageDialog}
      />
    </div>
  );
};

Tab1.propTypes = {
  className: PropTypes.string
};

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    getWordToSearch,
    clearWordToSearch,
    setKhKhWords
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Tab1);
