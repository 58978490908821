import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Drawer } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import HelpIcon from '@material-ui/icons/Help';
import { SidebarNav } from './components';
import DonutLargeIcon from '@material-ui/icons/DonutLarge';
import WallpaperIcon from '@material-ui/icons/Wallpaper';
import CommentIcon from '@material-ui/icons/Comment';
import SpellcheckIcon from '@material-ui/icons/Spellcheck';
import TranslateIcon from '@material-ui/icons/Translate';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import SlideshowIcon from '@material-ui/icons/Slideshow';
import constant, { getUser } from 'constant/constant';
import { getData } from 'provider/data-loader';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 100,
      height: 'calc(100% - 100px)',
    },
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  nav: {
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(1)
  },
}));

const Sidebar = props => {
  const { open, variant, onClose, className, ...rest } = props;
  const [data, setData] = useState();
  const classes = useStyles();
  const user = JSON.parse(getUser());

  useEffect(() => {
    const token = window.sessionStorage.getItem(constant.sessionKey);
    if (token !== null) {
      getData(constant.endPoint.dashboard + '?token=' + token)
      .then(result => {
        if (result.code === 1) {
          setData(result.data);
        } else {
          alert("Something went wrong");
        }
      })
      .catch(err => console.error(err));
    }
  }, []);

  const pages = [
    {
      title: 'Dashboard',
      href: '/dashboard',
      icon: <DashboardIcon />,
      isShow: true,
      subMenu: [],
      selected: true
    },
    {
      title: 'All Words',
      href: '/all_words',
      icon: <TranslateIcon />,
      isShow: true,
      subMenu: [],
      selected: false
    },
    {
      title: 'New Word',
      href: '/test',
      icon: <TranslateIcon />,
      isShow: true,
      selected: false,
      subMenu: [
        {
          title: 'ខ្មែរ-ខ្មែរ',
          href: '/kh-kh-words?page=1&count=10&status=all',
          icon: <TranslateIcon />,
          isShow: true,
          selected: false
        },
        {
          title: 'ខ្មែរ-ENG',
          href: '/kh-en-words?page=1&count=10&status=all',
          icon: <TranslateIcon />,
          isShow: true,
          selected: false
        },
        {
          title: 'ENG-ខ្មែរ',
          href: '/en-kh-words?page=1&count=10&status=all',
          icon: <TranslateIcon />,
          isShow: true,
          selected: false
        },
      ]
    },
    {
      title: 'Part Of Speech',
      href: '/part_of_speeches',
      icon: <TranslateIcon />,
      isShow: true,
      subMenu: [],
      selected: false
    },
    {
      title: 'Requested Word',
      href: '/wordrequets',
      icon: <SpellcheckIcon />,
      isShow: true,
      subMenu: [],
      selected: false,
      count: data?.numberOfWordRequest
    },
    {
      title: 'Questions',
      href: '/enquiries',
      icon: <HelpIcon />,
      isShow: true,
      subMenu: [],
      selected: false,
      count: data?.numberOfEnquiry
    },
    {
      title: 'Editing Word Requests',
      href: '/change-request',
      icon: <AutorenewIcon />,
      isShow: true,
      subMenu: [],
      selected: false,
      count: data?.numberOfChangeRequest
    },
    {
      title: 'Picture Requests',
      href: '/image-request',
      icon: <WallpaperIcon />,
      isShow: true,
      subMenu: [],
      selected: false,
      count: data?.numberOfImageRequest
    },
    {
      title: 'Comments',
      href: '/word-comment',
      icon: <CommentIcon />,
      isShow: true,
      subMenu: [],
      selected: false,
      count: data?.numberOfComment
    },
    {
      title: 'Users',
      href: '/users',
      icon: <PeopleIcon />,
      isShow: user?.role === constant.userRole.admin ? true : false,
      subMenu: [],
      selected: false
    },
    {
      title: 'Slide Advertisement',
      href: '/slide',
      icon: <SlideshowIcon />,
      isShow: true,
      subMenu: [],
      selected: false
    },
    {
      title: 'Version',
      href: '/versions',
      icon: <DonutLargeIcon />,
      isShow: user?.role === constant.userRole.admin ? true : false,
      subMenu: [],
      selected: false
    },
  ];

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div {...rest} className={clsx(classes.root, className)}>
        <SidebarNav {...props} className={classes.nav} pages={pages} />
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
};

export default Sidebar;
