import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import c from 'constant/constant';
import KhKhWordTab from '../WordTab/KhKhWordTab';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexFlow: 'column',
        height: '100%',
    },
    contentTab: {
        backgroundColor: 'white',
    },
    tabItem: {
        color: 'white',
        fontFamily: c.fontFamily.Khmer_Battambong_Regular
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingBottom: 20,
        paddingRight: 5
    },
    rootInput: {
        borderRadius: '4px',
        alignItems: 'center',
        padding: theme.spacing(1),
        display: 'flex',
        flexBasis: 420
    },
    icon: {
        marginRight: theme.spacing(1),
        color: theme.palette.text.secondary
    },
    input: {
        flexGrow: 1,
        fontSize: '14px',
        lineHeight: '16px',
        letterSpacing: '-0.05px'
    },
    searchBtn: {
        height: 46,
        marginLeft: 10,
        backgroundColor: c.color.yellow,
        color: 'white',
        fontFamily: c.fontFamily.Khmer_Battambong_Regular
    }
}));

const Word = props => {
    const classes = useStyles();
    return (
        <div className={clsx({
            [classes.root]: true,
        })}>
            <div className={classes.content}>
                <main>
                    <div style={{ backgroundColor: c.color.main }}>
                        <div className={classes.contentTab}>
                            <KhKhWordTab props={props} />
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
};

Word.propTypes = {
    className: PropTypes.string,
    onSidebarOpen: PropTypes.func
};

export default Word