import React from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types'
import { Button, DialogActions, Typography, DialogContentText, Divider } from "@material-ui/core";
import c from "constant/constant";

const useStyles = makeStyles(theme => ({
    content: {
        width: '100%', 
        margin: '0px 16px 0px 16px'
    },
    btn: {
        color: c.color.main,
        textTransform: 'capitalize',
        fontSize: c.fontSize.text,
        width: '100%',
        fontFamily: 'Calibri'
    },
    buttonPath: {
        width: '45%',
        display: 'flex',
        justifyContent: 'center'
    },
    text: {
        color: c.color.text,
        fontSize: c.fontSize.text,
        fontWeight: 500,
        fontFamily: 'Calibri'
    },
    mainColor: {
        color: c.color.main
    },
    dialogStyle: {
        borderRadius: 10,
    },
    borderRight: {
        width: 1,
        height: 30,
        backgroundColor: '#C4C4C4'
    },
}))

const LogoutDialog = props => {
    const { open, handleClose, handleSignOutButton } = props;
    const classes = useStyles();

    return (
        <Dialog
            open={open}
            onClose={handleClose} 
            aria-labelledby="form-dialog-title"
            classes={{
                paper: classes.dialogStyle
            }}
        >
            <DialogContent className={classes.content}>
                <DialogContentText id="alert-dialog-description">
                    <Typography className={classes.text}>Are you sure you want to sign out?</Typography>
                </DialogContentText>
            </DialogContent>
            <Divider />
            <DialogActions style={{ display: 'flex', justifyContent: 'center' }}>
                <div className={classes.buttonPath}>
                    <Button onClick={handleClose} className={classes.btn}>
                        cancel
                    </Button>
                </div>
                <div className={classes.borderRight}></div>
                <div className={classes.buttonPath}>
                    <Button onClick={handleSignOutButton} className={classes.btn}>
                        sign out
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    )
}

LogoutDialog.propTypes = {
    className: PropTypes.string,
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    handleSignOutButton: PropTypes.func,
}

export default LogoutDialog
