import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import c from 'constant/constant';
import Tab1 from '../Tabs/Tab1';
import Tab3 from '../Tabs/Tab3';
import Tab2 from '../Tabs/Tab2';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getWordToSearch, getWordIdToSearch,  clearWordToSearch, setTabSelected, setKhKhWords, setKhEnWords, setEnKhWords } from '../actions';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexFlow: 'column',
    flexGrow: 1,
    height: '100%'
  },
  contentTab: {
    width: '100%',
  },

  tabItem: {
    color: 'white',
    fontFamily: c.fontFamily.Khmer_Battambong_Regular,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 10,
    paddingBottom: 10,
    backgroundColor: c.color.main
  },
  rootInput: {
    borderRadius: '4px',
    alignItems: 'center',
    padding: theme.spacing(1),
    display: 'flex',
    flexBasis: 420,
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  input: {
    flexGrow: 1,
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '-0.05px',
  },
  searchBtn: {
    height: 46,
    marginLeft: 10,
    backgroundColor: c.color.yellow,
    color: 'white',
    fontFamily: c.fontFamily.Khmer_Battambong_Regular,
  },
  content: {
    display: 'flex',
    flexFlow: 'column',
  },
}));

const Home = props => {
  const { newTab, newSearchWord, setTabSelected } = props;
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(0);
  const [tab, setTab] = useState(false);

  // useEffect(() => {
  //   console.log("Active", newTab)
  //   setActiveTab(newTab);
  //   setTab(true);
  // }, [newSearchWord, newTab]);

  useEffect(()=> {
    let type = props.location.state?.type;
    if (type === undefined) {
      setActiveTab(newTab);
      setTab(true);
    } else {
      if (type === 'kh_kh') {
        setActiveTab(0);
        setTabSelected(0);
      } else if (type === 'kh_en') {
        setActiveTab(1);
        setTabSelected(1);
      } else {
        setActiveTab(2);
        setTabSelected(2);
      }
    }
    setTab(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location.state, newSearchWord, newTab])

  return (
    <div
      className={clsx({
        [classes.root]: true,
      })}
    >
      <div className={classes.content}>
        <div>
          {tab && (
            <div className={classes.contentTab}>
              {activeTab === 0 && (
                <Tab1
                  props={props}
                  wordToSearch={newSearchWord}
                />
              )}
              {activeTab === 1 && (
                <Tab2
                  props={props}
                  wordToSearch={newSearchWord}
                />
              )}
              {activeTab === 2 && (
                <Tab3
                  props={props}
                  wordToSearch={newSearchWord}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

Home.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
};

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    getWordToSearch,
    getWordIdToSearch,
    clearWordToSearch,
    setTabSelected,
    setKhKhWords,
    setKhEnWords,
    setEnKhWords
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)
